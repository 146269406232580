import { Types } from '../actions/reportActions';
import _ from 'lodash';

const defaultState = {
  my: {},
};

const reportReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.ADD_MODAL: {
      let newState = _.cloneDeep(state);

      newState.report.modal = action.status;

      // When user needs to update subscription to generate a new report
      if (action.updateSubscription) newState.report.updateSubscription = true;

      return newState;
    }
    case Types.ADD_REPORT: {
      let newState = _.cloneDeep(state);

      newState.report.addingReport = false;

      // if added properly
      newState.report.modal = false; // hide modal as report added
      // report list is sent in full by server to cover everything
      newState.report.list = action.payload.reports;

      return newState;
    }
    case Types.ADDING_REPORT: {
      let newState = _.cloneDeep(state);

      newState.report.addingReport = true;

      return newState;
    }
    case Types.LIST: {
      let newState = _.cloneDeep(state);

      newState.report = state.report ? { ...state.report } : {};
      newState.report.list = action.payload;
      newState.report.details = null;

      return newState;
    }
    case Types.DOWNLOAD_STATUS: {
      let newState = _.cloneDeep(state);

      newState.report = state.report ? { ...state.report } : {};

      if (!newState.report.download) newState.report.download = {};

      newState.report.download[action.filetype] = action.status;

      return newState;
    }
    case Types.DETAILS: {
      let newState = _.cloneDeep(state);
      const { cyfAdmin, whiteLabelAdmin } = newState.profile.details;

      newState.report = state.report ? { ...state.report } : {};
      newState.organisation.details = state?.organisation?.details ? { ...state?.organisation?.details } : {};
      newState.report.details = action.payload;

      newState.currentOrganisation = action.payload.organisationId;
      // Setting org details state for the orgId to wich belongs the report
      if (cyfAdmin || whiteLabelAdmin) {
        newState.organisation.details = _.find(newState?.admin?.organisations, { id: action.payload.organisationId });
      } else {
        newState.organisation.details = _.find(newState?.organisation?.list, { id: action.payload.organisationId });
      }

      return newState;
    }

    default:
      return state;
  }
};

export default reportReducers;
