import { format } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';

const UploadHistory = ({ usageUploadHistory, confirmUploadEstimate, showUploadHistory, uploads, importStatusRunning }) => {
  if (!usageUploadHistory) return null;

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '90vh', width: '80%', overflow: 'auto', fontSize: '12px' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button onClick={() => showUploadHistory(false)} className='close'>
              x
            </button>
            <h4 className='modal-title'>Upload History</h4>
          </div>
          <div className='modal-body'>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>File</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Unique Routes</th>
                  <th>Estimate</th>
                  <th>Final Cost</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {uploads &&
                  uploads.map((row, index) => (
                    <tr key={index} style={{ marginBottom: '5px', borderBottom: '15px solid transparent' }}>
                      <td>{row.key.substring(row.key.lastIndexOf('/') + 1)}</td>
                      <td>{row.type}</td>
                      <td>{format(new Date(row.createdAt), 'dd/MM/yyyy')}</td>
                      <td style={{ textAlign: 'center' }}>{row.uniqueRoutes}</td>
                      <td>{(row.uniqueRoutes * 0.004).toFixed(2)}</td>
                      <td>{(row.geoLocationAPICalls * 0.005 + row.distanceMatrixAPICalls * 0.004).toFixed(2)}</td>
                      <td>
                        <button
                          disabled={importStatusRunning}
                          className='btn btn-success'
                          onClick={() => {
                            confirmUploadEstimate(row.id);
                          }}>
                          Proceed
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    usageUploadType: state.usageUploadType,
    usageUploadHistory: state.usageUploadHistory,
    uploads: state.myUploads,
    importStatusRunning: state.processingImport?.status?.status === 'RUNNING',
  };
};

const mapDispatchToProps = (dispatch) => ({
  confirmUploadEstimate: (id) => dispatch(USAGEACTIONS.confirmUploadEstimate(id)),
  showUploadHistory: (status) => dispatch(USAGEACTIONS.showUploadHistory(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadHistory);
