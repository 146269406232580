import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { AddButton } from '../../../styledComponents/GlobalStyle';
import { AutoSizer, Table, Column } from 'react-virtualized';
import _ from 'lodash';
import { LinearProgress, Tooltip } from '@mui/material';
import { Edit, Delete, ContentCopy } from '@mui/icons-material';
import PricingBandModal from './PricingBandModal';

const PriceBands = ({ branding, getAllItemsFromDB, priceBands, tableName, toggleModal, showLoader, contractApprover }) => {
  const { headers } = branding?.fonts[0]?.font;

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newPricingBand, setNewPricingBand] = useState(false);

  useEffect(() => {
    getAllItemsFromDB(tableName);
  }, []);

  useEffect(() => {
    setData(priceBands);
  }, [priceBands]);

  const userActions = ({ rowData }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div>
          {!rowData.inUse && (
            <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>Edit Pricing Band</p>}>
              <Edit sx={{ cursor: 'pointer' }} onClick={() => setShowModal(rowData)} />
            </Tooltip>
          )}
          <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>Copy Pricing Band</p>}>
            <ContentCopy
              sx={{ marginLeft: '1vh', cursor: 'pointer' }}
              onClick={() => {
                const newData = { ...rowData };
                const keysToDelete = ['created', 'modified', 'id', 'modifiedBy'];
                Object.keys(newData).forEach((key) => {
                  // Delete keys s that are not needed when copying band
                  if (keysToDelete.includes(key)) {
                    delete newData[key];
                  } else if (['name'].includes(key)) newData[key] = ''; // Reseting Band Name
                });
                setShowModal(newData);
              }}
            />
          </Tooltip>
          {contractApprover && !rowData.inUse && (
            <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>Delete Pricing Band</p>}>
              <Delete
                sx={{ marginLeft: '1vh', cursor: 'pointer' }}
                onClick={() => toggleModal(true, 'pricingBand-table-delete', rowData)}
              />
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  /* if (showLoader) {
    return <LinearProgress color='inherit' />;
  } */

  return (
    <section className='panel' style={{ fontFamily: headers }}>
      <header
        className='panel-heading'
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        Price Bands
        <div style={{ display: 'flex' }}>
          <AddButton className='btn btn-success' onClick={() => setNewPricingBand(true)}>
            Add New Pricing Band
          </AddButton>
        </div>
      </header>
      <div id='contract-not-found' className='panel-body' style={{ height: '100vh' }}>
        {showLoader && (
          <div>
            <LinearProgress color='inherit' />;
          </div>
        )}

        <div style={{ minWidth: '100%', height: '100%', display: data?.length ? 'block' : 'none' }}>
          {data?.length && (
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width}
                  height={height}
                  rowHeight={50}
                  headerHeight={60}
                  headerStyle={{ verticalAlign: 'bottom', width: '100%', display: 'flex', justifyContent: 'center' }}
                  rowStyle={{ textAlign: 'center' }}
                  headerClassName='admin_portal_column_headers'
                  rowCount={data && data.length}
                  rowGetter={({ index }) => data[index]}>
                  <Column label='Actions' dataKey='actions' width={400} cellRenderer={userActions} />
                  <Column label='Document Id' dataKey='id' width={400} />
                  <Column label='Name' dataKey='name' width={400} />
                  <Column label='Created' dataKey='created' width={400} />
                  <Column label='Modified' dataKey='modified' width={400} />
                  <Column label='Modified By' dataKey='modifiedBy' width={400} />
                </Table>
              )}
            </AutoSizer>
          )}
        </div>
      </div>
      {(showModal || newPricingBand) && (
        <PricingBandModal
          rowData={showModal}
          setShowModal={setShowModal}
          isNew={newPricingBand}
          setIsNew={setNewPricingBand}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  const { contractApprover } = state?.profile?.details || {};
  const tableName = 'pricingBands';

  return {
    priceBands: state?.admin?.adminPortal?.[tableName],
    branding: state.branding,
    tableName,
    showLoader: state.showLoader,
    contractApprover,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceBands);
