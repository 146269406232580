import React from 'react';

import { connect } from 'react-redux';
import ConsumptionReport from '../components/ConsumptionReport/ConsumptionReport';

import Breadcrumb from '../components/Common/Breadcrumb';

const Report = ({ profile, saveProfile, saving }) => {
  return (
    <React.Fragment>
      <Breadcrumb
        items={[
          {
            label: 'Reports',
            link: '/reports',
          },
        ]}
      />

      <ConsumptionReport />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
