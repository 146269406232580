const softwareLicenseAgreement = () =>
  `
The Agreement is between **COMPARE YOUR FOOTPRINT LTD,** a company incorporated in Scotland (Registered No. SC689618) with its registered office at 7 Ferrymuir Lane, South Queensferry, Scotland, EH30 9PA( **&quot;CYF&quot;** ) and **you,** ( **&quot;the Customer&quot;** ).

This is a Click to Agree Contract from within the Compare Your Footprint Software if the Customer wishes to be bound by these Terms and Conditions, the Customer must click to accept these Terms and Conditions. If the Customer does not agree to be bound by these Terms and Conditions, the Customer cannot order or use any Services. The Customer must be at least 18 years old to order Services. The person entering into the Agreement on behalf of the Customer warrants that he/she has the authority of the Customer to enter into the Agreement on the Customer&#39;s behalf.

CYF&#39;s issue of an Order Form to the Customer constitutes a contractual offer and the Customer&#39;s signing/acceptance of the Agreement and acceptance/signing of an Order Form will conclude the contract between CYF and the Customer relating to the Subscription Services and Professional Services listed in the Order Form. All Order Forms will be deemed to be subject to and include these Terms and Conditions.
 The contract ( **&quot;Agreement&quot;** ) between the Customer and CYF will comprise:

1. the Order Form(s);
2. these Terms and Conditions;
3. the Privacy Policy; and
4. the SLA.

If there is any conflict between the terms of an Order Form and these Terms and Conditions, then the terms of the Order Form will prevail.
The elements of the Contract will have precedence as follows:

1. Order Form (s) (in reverse chronological order);
2. Terms and Conditions;
3. Privacy Policy;
4. SLA.

**PART ONE – SUBSCRIPTION SERVICES.**

1	**Grant of Licence**.

CYF grants to the Customer a non-exclusive, royalty based (i.e. the Fees specified in the Order Form), sublicensable (as provided in Clause 8, of these Terms and Conditions), term licence to use the Subscription Services for the Customer’s internal business purposes only for the Subscription Term, subject to the following conditions:

1. The Subscription Services are located on the Platform. CYF has full administrative access rights to the Platform. Users may access the Subscription Services but have no right to administer the Platform or receive a copy of the object code or source code to the Software.
2. Users must have a reasonable speed Internet connection, and Local Equipment that is compatible with the Subscription Services, as set out in the Documentation. None of these things are CYF’s responsibility.
3. CYF may periodically Upgrade and Update the Services, in order to provide Users with a greater, evolving user experience. Some of these changes shall occur automatically, while others may require the Customer to schedule and implement the changes. The changes may also mean that Users need to upgrade their Local Equipment in order to make efficient use of the Subscription Services. CYF shall provide the Customer with reasonable notification in advance in this case.
4. CYF has all required distribution rights to the Intellectual Property in the Software and the Documentation. For a full list of third party licenses and attribution, please click here http://compareyourfootprint.com/wp-content/uploads/2021/07/CYF-Licenses-July-2021.pdf
5. Neither party shall be under any liability to the other in respect of anything which, apart from this provision, may constitute breach of the Agreement arising by reason of Force Majeure.

2	**Conditions of Use**.

The Subscription Services provided to the Customer are non-exclusive, non-transferable and are for the Customer’s internal business use only. The Customer’s right to use the Subscription Services is subject to the following conditions:-

the Customer shall not:

1.	subject to Clauses 8 sub-licensing and  21.4 alienation of the Terms and Conditions, transfer to any other person any of its rights to use the Subscription Services;
2.	sell, license, rent or lease the Subscription Services except as provided for in the Agreement;
3.	make the Subscription Services available to anyone who is not a User;
4.	create any derivative works based upon the Subscription Services or Documentation;
5.	copy any feature, design or graphic in, or reverse engineer the Software (including without prejudice to the foregoing generality the graphical user interface and/or menu command hierarchy);
6.	access the Subscription Services (i) in order to build a competitive solution or to assist someone else to build a competitive solution; or (ii) if the User is an employee or contractor of a CYF competitor;
7.	use the Subscription Services in a way that violates any criminal or civil law;
8.	load test the Subscription Services in order to test scalability; or,
9.	exceed any usage limits listed on the applicable Order Form.

3	**User Content/Security**
1.	Users provide all data for use in the Subscription Services, and CYF is not obliged to modify or add to User Content except as specified in Clause 4. The Customer is solely responsible for User Content and the accuracy of User Content.
2.	User Content belongs to Users or their licensors, and CYF makes no claim to any right of ownership in User Content except as specifically provided herein.
3.	CYF shall keep User Content confidential in accordance with Clause 17 of the Terms and Conditions.
4.	Subject to the terms of Clauses 4 and 24, CYF shall only be entitled to use User Content strictly as necessary to carry out its obligations under the Agreement, and for no other purpose. However, CYF:
    1.	may observe and report back to the Customer on the Customer’s and its clients’ usage of the Subscription Services, and make recommendations for improved usage of the Subscription Services;
    2.	shall use reasonable endeavours to ensure that the data centre containing the User Content complies with ISO 27001;and
    3.	may identify trends and publish reports on its findings from data aggregated from more the User Site(s) provided such reports do not identify the User and otherwise anonymise the data and comply with the terms of Clause 17 (Confidentiality);
5.	CYF shall, in providing the Subscription Services, comply with the Privacy Legislation and in accordance with its Privacy Policy and Clauses 22 and 23 of the Agreement. For the purposes of the Privacy Legislation (except in the case of Data Sharing in terms of Clause 23), CYF will be a Data Processor and the Customer will be the Data Controller of User Content. This clause is in addition to, and does not relieve, remove or replace, CYF’s obligations or rights under the Privacy Legislation.
6. 	Sharing of login/account details is not permitted unless expressly authorised in writing by CYF. Users must keep login/account details confidential and Users should not reveal their username or password to any unauthorised third parties. CYF accepts no liability for any losses or damages incurred as a result of account details being shared in breach of the terms of the Agreement. It is recommended that Users do not save login/account details in their internet browser.
7.  Passwords must be robust and difficult to break.
8.	Industry Best Practice security recommendations should be implemented at all times, such as (a) maintaining a recognised Cyber Essentials Certification IT systems such as https://www.cyberessentials.ncsc.gov.uk; (b) Always implementing strong, robust, difficult to break passwords, that are changed on a regular basis; (c) that Users do not save login/account details in their internet browser; and (d) implementing two factor authentication at each endpoint.
9.	Whereas as part of the Subscription Services, CYF may host email accounts or other online communications infrastructure or subscription accounts (including the CYF subscription itself) for Users, CYF accepts no responsibility and shall not be liable for third parties accessing such email, online communications accounts or subscription accounts by way of breaking or hacking passwords. It is the responsibility of Users to ensure that all email, online communications accounts and subscription accounts are properly protected with robust passwords. The terms of Clause 4 apply to the use of any such email, online communications accounts and subscription accounts.

4 **Acceptable Usage Policy**.
1.	Without prejudice to the generality of Clause 2.7, when using the Subscription Services; Users should do so in accordance with the following rules:
    1.	Users must not use obscene or vulgar language;
    2.	User Sites may not contain any material that is unlawful or otherwise objectionable (including that which may be in breach of rules, regulations or legislation in force in the United Kingdom or any other jurisdiction in which the Customer’s User Site can be lawfully accessed. This does not extend to material which may be automatically blocked in certain jurisdictions but that is lawful in the Customer’s home country);
    3.	User Sites may not contain any material that is intended to promote or incite violence or any other unlawful conduct against any group, individual or animal. This includes, but is not limited to, the provision of instructions on how to assemble weapons of any kind, bombs, grenades or other explosive devices;
    4.	User Sites may not infringe the Intellectual Property rights of any third party including, but not limited to, copyright, trademarks, patents and designs;
    5.	User Sites may not contain any material that may contain viruses or other software or instructions that may damage or disrupt other software, computer hardware or communications networks; and
    6.	User Sites may not be used for unauthorised mass-communications such as “spam” or “junk mail”.
2.	CYF does not screen or pre-approve any User Site or User Content (although Users acknowledge that CYF may do so if it wishes).
3.	CYF may edit a User Site to comply with the provisions of sub-Clause 4.1 without prior consultation. In cases of severe breaches of the provisions of sub-Clause 4.1, a User Site may be taken down and the relevant account may be suspended or terminated. The Customer will not be informed in writing of the reasons for such alterations or take downs.
4.	CYF accepts no responsibility or liability for any infringement of third-party rights by User Sites.
5.	CYF will not be liable in any way or under any circumstances for any loss or damage that any User may incur as a result of such User Sites, or CYF exercising its rights under the Agreement, nor for any errors or omissions in User Sites. Use of and reliance upon User Sites is entirely at the Customer’s own risk.
6.	The Customer acknowledges that CYF may retain copies of any and all communications, information, User Content and User Sites sent to CYF.
7.	Users must comply with the terms of the Privacy Legislation at all times.
8.	Users who are the Customer’s employees and consultants, clients, clients’ employees and other third parties must enter into the EULA.
9.    All parties who create an Assessment are required to enter into the Assessment Terms.

5	**Intellectual Property**
1.	Subject to the exceptions in Clause 6 of the Terms and Conditions, all Content, that is not User Content, and the Database and the Software and the Documentation are the property of CYF, or CYF’s Affiliates or licensors. By continuing to use the Subscription Services the Customer acknowledges that such material is protected by applicable United Kingdom and international Intellectual Property and other laws.
2.	The Customer may print, reproduce, copy, distribute, store or in any other fashion re-use Content from the Subscription Services for personal or educational purposes only unless otherwise given CYF’s express written permission to do so. Specifically, the Customer agrees that it will not systematically copy Content from the Subscription Services with a view to creating or compiling any form of comprehensive collection, compilation, directory or database unless given CYF’s express written permission to do so.
3.	In the event that new inventions, designs or processes evolve in performance of or as a result of the Agreement, the Customer acknowledges that the same shall be the property of CYF unless otherwise agreed in writing by CYF.
4.	Any trade mark, trade name or logo such as “Powered by Compare Your Footprint” appearing on or in the Software is the property of CYF and must not be copied, obscured or removed from the Software.

6	**User Site Intellectual Property**
1.	The Intellectual Property rights subsisting in the User Content of User Sites belong to the User to which that/those User Site(s) belong(s) unless it is expressly stated otherwise in the Agreement.
2.	Where expressly indicated, certain Content available through User Sites and the Intellectual Property rights subsisting therein belongs to third parties.
3.	The third party Content described in this Clause 6, unless expressly stated to be so, is not covered by any permission granted by Clause 5 of these Terms and Conditions to use Content.
4.	For the avoidance of doubt, the Database (excluding the User Content therein) shall not be considered User Content.

7	**Third Party Intellectual Property**
1.	Unless otherwise expressly indicated, all Intellectual Property rights including, but not limited to, copyright and trademarks, in Content belong to the manufacturers or distributors of such Content as may be applicable.
2.	Subject to Clause 5 the Customer may not reproduce, copy, distribute, store or in any other fashion re-use Content unless otherwise indicated on the Subscription Services or the Documentation or unless given express written permission to do so by the relevant manufacturer or supplier.

8	**Sub-licences and Client Sharing**

1.	The Customer agrees:  
    1.	not to represent itself as agent of CYF for any purpose, nor pledge CYF’s credit or give any condition or warranty or make any representation on CYF’s behalf or commit CYF to any contracts or otherwise incur any liability on behalf of CYF howsoever arising; 
    2.	not without CYF’s prior written consent, make any representations, warranties, guarantees or other commitments with respect to the specifications, features or capabilities of the Subscription Services which are inconsistent with those contained in any promotional material supplied by CYF; 
    3.	to observe all reasonable directions and instructions given to it by CYF in relation to the promotion and advertisement of the Subscription Services to the extent that such promotions or advertisements refer to the Subscription Services; and
    4.	not to make any written statement as to the quality or manufacture of the Subscription Services without prior written approval of CYF. 
    5.	that the Software shall contain the words “© Compare Your Footprint” or such other similar wording as is prescribed by CYF from time to time. The Customer shall ensure that this wording is not removed or obscured from/in the Software.
2.	CYF undertakes and agrees with the Customer: 
    1.	to meet all orders for the quantity of Sub-licenses as the Customer requires and is entitled to grant from time to time in accordance with the Agreement and the relevant Order Form; and 
    2.	to provide the Customer with reasonable technical support to enable the Customer to promote, resell and distribute the Subscription Services in accordance with the relevant Order Form. 
3.	The sums to be paid by the Customer to CYF in respect of the purchase/supply of Sub-licenses shall be calculated in accordance with the relevant Order Form. 
4.	The Customer is entitled to grant the number of Sub-licenses as are detailed in the relevant Order Form;
5.	Unless specified otherwise in the relevant Order Form, the Customer must contain the EULA in any Sub-licence.
6.	The Customer is not entitled to amend, vary or change the terms of the EULA without CYF’s consent.
7.	The terms of each Sub-licence will be as agreed in the relevant Order Form.
8.	The Customer shall supervise and control use of the Subscription Services by Users in accordance with the terms of the Agreement and the EULA.
9.	The Customer will where required by CYF, enforce at the Customer’s cost the terms of the Sub-license for CYF’s benefit.
10.	Notwithstanding the terms of Clause 8.5, the terms of the Sub-licence will be on the same terms mutatis mutandis as the Agreement.
11.	Notwithstanding that the Customer has granted a Sub-license, the Customer will remain liable to CYF under the Agreement.

9	**Subscription Services Warranties**
1.	CYF warrants that: (i) the Subscription Services will function substantially as described in the Documentation; and (ii) CYF owns or otherwise has the right to provide the Subscription Services to the Customer under the Agreement. The remedies set out in this Clause 9 are the Customer’s exclusive remedies for breach of either warranty.
2.	If the Subscription Services do not function substantially in accordance with the Documentation, CYF shall, at its option, either (i) modify the Subscription Services to conform to the Documentation; or (ii) provide a workaround solution that will reasonably meet the Customer’s requirements. If neither of these options are commercially feasible, either party may terminate the relevant Order Form under the Agreement, in which case CYF shall refund to the Customer all fees pre-paid to CYF under the relevant Order Form for unused Subscription Services.
3.	If the normal operation, possession or use of the Subscription Services by the Customer is found to infringe any third party Intellectual Property right or CYF believes that this is likely, CYF shall, at its option, either (i) obtain a license from such third party for the benefit of the Customer; (ii) modify the Subscription Services so that they no longer infringe; or (iii) if neither of these options is commercially feasible, terminate the relevant Order Form under the Agreement, in which case CYF shall refund to the Customer all fees pre-paid to CYF under the relevant Order Form for unused Subscription Services.
4.	However, CYF has no warranty obligations for:
    1.	the extent that Software has been modified by the Customer or any third party, unless the modification has been approved in writing by CYF; or,
    2.	problems in the Subscription Services caused by any Third-Party Software or hardware, by accidental damage or by other matters beyond CYF’s reasonable control.


**PART TWO – PROFESSIONAL SERVICES.**

10 **Professional Services Warranties.**
1. CYF warrants that (i) the Professional Services shall substantially
    conform to the applicable Order Form; and (ii) the Professional
    Services shall be performed with reasonable skill, care and
    diligence. The remedies set out in this Clause 10 are the Customer’s
    exclusive remedies for breach of either warranty. If the
    Professional Services do not conform to the Order Form or are not
    performed with reasonable skill, care and diligence, CYF shall
    re-perform the Professional Services to the extent necessary to
    correct the defective performance.
2. CYF shall comply with the terms of the SLA (or as amended in an
    Order Form) at all times. The terms of Clause 10.1 shall apply to
    the SLA.

11 **The Customer’s Responsibilities.**

1. The Customer shall provide CYF with all information, access, and
    full good faith cooperation reasonably necessary to enable CYF to
    deliver the Professional Services and shall do anything that is
    identified in the Order Form as the Customer’s responsibility. If
    the Customer fails to do this, CYF shall be relieved of its
    obligations to the extent that the obligations are dependent upon
    the Customer’s performance.

**PART THREE – GENERAL.**

12 **Term of Agreement.**

The Agreement starts on the date that both parties sign an Order Form for the relevant services and ends when CYF no longer is obliged to provide the Customer with Services under any Order Form.

13 **Payments.**
  1. The Customer shall pay the Fees listed in, and in accordance with, the relevant Order Form.
  2. If the Customer initially purchases Subscription Services for a term, and subsequently orders an additional product, the purchase price for the additional product shall be pro-rated so that the added subscriptions terminate on the same day as the initial Subscription Term (unless specified otherwise in the relevant Order Form). The initial Subscription Term is the Subscription Term stated in the first Order Form.
  3. The Fees for the initial Subscription Term will be as specified in the first Order Form. CYF reserves the right to change Fees from time to time and any such changes may affect the Customer&#39;s recurring fees after the initial Subscription Term. Increases in price will be reflected in the Customer&#39;s recurring Fees for the Services.

14 **Termination and Suspension.**
  1. Either party may terminate rights granted to the other under a particular Order Form at any time after expiry of the relevant Subscription Term and provided all outstanding fees have been paid to CYF for the Services, by providing not less than 30 days' prior written notice to the other party.
  2. Either party may terminate the Agreement, or any rights granted under a particular Order Form with immediate effect if:
        1. the other party commits a material breach of any term of the Agreement or any Order Form which is irremediable or (if such breach is remediable) fails to remedy that breach within a period of 30 days after being notified in writing to do so; or
        2. the other party is unable to pay its debts and/or ceases to trade and/or suffers an Insolvency Event.
  3. Clauses 2.4, 2.5, 3.3, 4, 5, 6, 7, 9, 12, 14, 15, 16, 17, 18, 19, 20, 22, 23, 24, 25 and 26 shall continue after the Agreement ends.
  4. If CYF terminates an Order Form under the Agreement because of non-payment by the Customer, all unpaid fees for the remainder of the Subscription Term immediately fall due for payment.
  5. Upon termination of the Agreement or any Order Form for any reason:
        1. (except in the case of termination due to the Customer&#39;s material breach of the Agreement), the Customer will be given restricted access to the Subscription Services for a period of ten days in which to recover their User Content. CYF can provide a User Content recovery service to the Customer, should they wish to use it. CYF reserves the right to charge for this service; and
        2. the Customer shall immediately pay to CYF all unpaid invoices and interest at the rate specified in the relevant Order Form, for any Services for which no invoice has been raised and any work in progress. CYF shall invoice the Customer and the invoice shall be payable immediately on receipt.
  6. Termination of the Agreement or any Order Form shall not affect any of the parties&#39; rights and remedies that have accrued as at termination, including the right to claim damages (subject to the limitations contained herein) in respect of any breach of the Agreement or any Order Form that existed at or before the date of termination.
  7. CYF may retain User Content in backup media for an additional period of up to one year after the date of termination of the Agreement, or longer if required by law, provided it makes no further use of such User Content (except as provided for herein or as is required by law), keeps the User Content confidential in accordance with Clause 17, and supplies the Customer with a copy of the most recent back-up of the User Content within 30 days of the Customer&#39;s request (at the Customer's cost).

15 **Warranty Disclaimer.**
  1. Except as expressly provided in the Agreement, the Subscription Services, Software and Professional Services are provided with no other warranties of any kind, and CYF disclaims all other warranties, express or implied, including without limitation any warranty of merchantability or fitness for a particular purpose. CYF does not warrant that the use of the Subscription Services shall be uninterrupted or error-free.
  2. Without prejudice to the foregoing Clause, CYF does not give any guarantee of any specific results from the results of the use of the Services. Without prejudice to the foregoing, CYF does not guarantee that the Customer or its business will make any monetary savings, will be more profitable or successful or be able to avoid any government taxes or penalties in any particular jurisdiction.

16 **Limitation of Liability.**
  1. Neither party shall be liable under the Agreement for any indirect, special, incidental, punitive or consequential damages (including without limitation damages for loss of goodwill, work stoppage, computer failure or malfunction, lost or corrupted data, lost profits, lost business or lost opportunity), or any other similar damages under any theory of liability (whether in contract, tort/delict, strict liability or any other theory), even if the other party has been informed of this possibility. The Customer assumes all responsibility for the selection of the Subscription Services, Software and Documentation necessary to achieve the Customer&#39;s intended results, and for the use and results of the Subscription Services or work product. Each party&#39;s total liability for any direct loss, cost, claim or damages of any kind related to the Agreement or the relevant Order Form shall not exceed the amount of the fees paid or payable by the relevant party under such relevant Order Form during the period of 12 months before the event giving rise to such loss, cost, claim or damages. However, there is no limitation on direct loss, claim or damages arising as a result of an infringement of either party&#39;s Intellectual Property rights by the other party, or a breach of Clause 17 or Clauses 22 and 23 of the Terms and Conditions by the other party.
  2. CYF&#39;s liability under the Agreement (except where provided otherwise in the Agreement to a lesser extent) shall be limited to the amount of professional indemnity insurance underwritten in the name of CYF which shall be £1,000,000. This limitation shall not apply to a breach of Clauses 22 or 23.

17 **Confidentiality**
  1. Each party may be given access to Confidential Information from the other party in order to perform its obligations under the Agreement and any Order Form. A party&#39;s Confidential Information shall not be deemed to include information that (i) is now, or subsequently becomes, through no act or failure to act on the part of receiving party (the &quot;Receiver&quot;), generally known or available; (ii) is known by the Receiver at the time of receiving such information, as evidenced by the Receiver&#39;s records; (iii) is subsequently provided to the Receiver by a third party, as a matter of right and without restriction on disclosure; or (iv) is required to be disclosed by law, provided that the party to whom the information belongs is given prior written notice of any such proposed disclosure.
  2. Subject to clauses 17.4, each party shall hold the other&#39;s Confidential Information in confidence and not make the other&#39;s Confidential Information available to any third party (other than to a consultant or a Sub-contractor for the purposes of the Agreement and which consultant or Sub-contractor shall have entered into undertakings of confidentiality in relation to the Confidential Information on terms no less onerous than those contained in this Clause 17), or use the other&#39;s Confidential Information for any purpose other than to carry out its obligations under the Agreement.
  3. Each party shall take all reasonable steps to ensure that the other&#39;s Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of the terms of the Agreement.
  4. A party may disclose Confidential Information to the extent such Confidential Information is required to be disclosed by law, by any governmental or other regulatory authority or by a court or other authority of a competent jurisdiction, provided that, to the extent it is legally permitted to do so, it gives the other party as much notice of such disclosure as possible and, where notice of disclosure is not prohibited and is given in accordance with this clause 17.4, it takes into account the reasonable requests of the other party in relation to the content of such disclosure.
  5. No party shall make, or permit any person to make, any public announcement concerning the Agreement without the prior written consent of the other parties (such consent not to be unreasonably withheld or delayed), except as required by law, any governmental or regulatory authority (including without limitation, any relevant securities exchange), any court or other authority of competent jurisdiction.
  6. This clause 17 shall survive termination of the Agreement or any Order Form, howsoever arising.

18 **Indemnification by CYF.**
  1. CYF shall indemnify and hold harmless the Customer its clients, its Affiliates, directors and employees from any damages finally awarded against the Customer (including, without limitation, reasonable costs and legal fees incurred by the Customer) arising out of any third party suit, claim or other legal action alleging that the use of the Subscription Services or Documentation by the Customer (other than User Content – see Clauses 4 and 19) infringes the Intellectual Property of any third party, (&quot;Legal Action&quot;). CYF shall provide reasonable assistance in the defence of such Legal Action.
  2. However, CYF shall have no indemnification obligations for any Legal Action arising out of: (i) a combination of the Subscription Services and/or Software with software or products not supplied, or approved in writing by CYF; (ii) any repair, adjustment, modification or alteration to the Subscription Services by the Customer or any third party, unless approved in writing by CYF; or (iii) any refusal by the Customer to install and use a non-infringing version of the Subscription Services offered by CYF under Clause 9.2(ii). Clause 9.2(ii) and this Clause 18 state the entire liability of CYF with respect to any Intellectual Property infringement by the Subscription Services or Software or Documentation.
  3. The Customer shall give written notice to CYF of any Legal Action no later than 30 days after first receiving notice of a Legal Action, and shall give copies to CYF of all communications, notices and/or other actions relating to the Legal Action. The Customer shall give CYF the sole control of the defence of any Legal Action, shall act in accordance with the reasonable instructions of CYF and shall give CYF such assistance as CYF reasonably requests to defend or settle such claim. CYF shall conduct its defence at all times in a manner that is not adverse to the Customer&#39;s interests. the Customer may employ its own counsel to assist it with respect to any such claim. The Customer shall bear all costs of engaging its own counsel, unless engagement of counsel is necessary because of a conflict of interest with CYF or its counsel, or because CYF fails to assume control of the defence. The Customer shall not settle or compromise any Legal Action without CYF&#39;s express written consent. CYF shall be relieved of its indemnification obligation under this Clause 18 if the Customer materially fails to comply with Clause 18.3.

19 **Indemnification by the Customer**
  1. The Customer shall indemnify and hold harmless CYF, its Affiliates, directors, and employees from any damages finally awarded against CYF (including, without limitation, reasonable costs and legal fees incurred by CYF) arising out of any third party suit, claim or other legal action (including but not limited to any governmental investigations, complaints and actions) in connection with the User Content, including, without limitation, any action for infringement of any trademark, copyright, trade secret, right of publicity or privacy (including defamation), patent or other proprietary right with respect to the User Content (&quot;Legal Claim&quot;).
  2. CYF shall give written notice to the Customer of any Legal Claim no later than 30 days after first receiving notice of a Legal Claim and shall give copies to the Customer of all communications, notices and/or other actions relating to the Legal Claim. CYF shall give the Customer the sole control of the defence of any Legal Claim, shall act in accordance with the reasonable instructions of the Customer and shall give the Customer such assistance as the Customer reasonably requests to defend or settle such claim. The Customer shall conduct its defence at all times in a manner which is not adverse to CYF&#39;s interests. CYF may employ its own counsel to assist it with respect to any such claim. CYF shall bear all costs of engaging its own counsel, unless engagement of counsel is necessary because of a conflict of interest with the Customer or its counsel, or because the Customer fails to assume control of the defence. CYF shall not settle or compromise any Legal Claim without the Customer&#39;s express written consent. The Customer shall be relieved of its indemnification obligation under Clause 19 if CYF materially fails to comply with Clause 19.2.

20 **Publicity.**

CYF may list the Customer as a customer and use the Customer&#39;s logo on CYF&#39;s website, on publicly available Customer lists, and in media releases with the Customer&#39;s consent, such consent not to be unreasonably withheld.

21 **Miscellaneous.**
  1. The Agreement represents the entire express agreement of the parties, and supersedes any prior or current agreements or understandings, whether written or oral. If there is a conflict between the Agreement and an Order Form, the Order Form shall prevail.
  2. The Agreement may not be changed or any part waived except by written agreement between the parties.
  3. The Agreement shall be governed by the laws of Scotland. The parties consent to the exercise of exclusive jurisdiction of the Scottish courts.
  4. Neither party shall assign or otherwise transfer any of its rights or obligations under the Agreement without the prior written consent of the other party.
  5. The language of the Agreement shall be English.
  6. The time zone of the Agreement shall be Greenwich Mean Time.

22 **Data Processing.**
  1. Both parties will comply with all applicable requirements of the Privacy Legislation. This Clause 22 is in addition to, and does not relieve, remove or replace a party&#39;s obligations or rights under the Privacy Legislation.
  2. The provisions of the Agreement shall apply to the processing of the Personal Data carried out for the Customer by CYF, and to all Personal Data held by CYF in relation to all such processing whether such Personal Data is held at the date of the Agreement or received afterwards.
  3. The Agreement shall continue in full force and effect for so long as CYF is processing Personal Data on behalf of the Customer.
  4. CYF is only to process the Personal Data received from the Customer:
        1. for the purposes of the Agreement and not for any other purpose;
        2. to the extent and in such a manner as is necessary for those purposes; and
        3. strictly in accordance with the Agreement or otherwise with the express written authorisation and instructions of the Customer (which may be specific instructions or instructions of a general nature or as otherwise notified by the Customer to CYF).
  5. Schedule 1 sets out the scope, nature and purpose of processing by CYF, the duration of processing and the types of Personal Data and categories of data subject.
  6. All instructions given by the Customer to CYF shall be made in writing and shall at all times be in compliance with the Privacy Legislation and other applicable laws. CYF shall act only on such written instructions from the Customer unless CYF is required by law to do otherwise.
  7. CYF shall promptly assist the Customer (where the Customer cannot do this itself via the Subscription Services) in complying with a legitimate data subject request to amend, transfer, delete, or otherwise dispose of Personal Data. Where permitted to do so by law, CYF may charge a reasonable fee for providing such assistance.
  8. Both Parties shall comply at all times with the Privacy Legislation and other applicable laws and shall not perform their obligations under the Agreement or any other agreement or arrangement between themselves in such way as to cause either party to breach any of its applicable obligations under the Privacy Legislation.
  9. The Customer hereby warrants, represents, and undertakes that the Personal Data shall comply with the Privacy Legislation in all respects including, but not limited to, its collection, holding, and processing.
  10. CYF agrees to comply with any reasonable measures required by the Customer to ensure that its obligations under the Agreement are satisfactorily performed in accordance with any and all applicable legislation from time to time in force (including, but not limited to, the UK GDPR) and any best practice guidance issued by the ICO.
  11. CYF shall provide all reasonable assistance (at the Customer&#39;s cost) to the Customer in complying with its obligations under the Privacy Legislation with respect to the security of processing, the notification of Personal Data breaches, the conduct of data protection impact assessments, and in dealings with the ICO.
  12. When processing the Personal Data on behalf of the Customer, CYF shall:
        1. not process the Personal Data outside the European Economic Area (all EU member states, plus Iceland, Liechtenstein, and Norway) (&quot;EEA&quot;) other than the United Kingdom (which shall be permitted) without the prior written consent of the Customer and, where the Customer consents to such a transfer to a country that is outside of the EEA, to comply with the obligations of Data Processors under the provisions applicable to transfers of Personal Data to third countries set out in Chapter 5 of the UK GDPR by providing an adequate level of protection to any Personal Data that is transferred and providing appropriate safeguards in relation to the transfer;
        2. not transfer any of the Personal Data to any third party (other than those Sub-processors listed in Schedule 1) without the written consent of the Customer and, in the event of such consent, the Personal Data shall be transferred strictly subject to the terms of a suitable agreement, as set out in Clause 22.21;
        3. process the Personal Data only to the extent, and in such manner, as is necessary in order to comply with its obligations to the Customer or as may be required by law (in which case, CYF shall inform the Customer of the legal requirement in question before processing the Personal Data for that purpose unless prohibited from doing so by law);
        4. implement appropriate technical and organisational measures, and take all steps necessary to protect the Personal Data against unauthorised or unlawful processing, accidental loss, destruction, damage, alteration, or disclosure;
        5. make available to the Customer any and all such information as is reasonably required and necessary to demonstrate CYF&#39;s compliance with the Privacy Legislation; and
        6. inform the Customer immediately if it is asked to do anything that infringes on the Privacy Legislation.
  13. CYF shall, at the Customer&#39;s cost, assist the Customer in complying with its obligations under the Privacy Legislation. In particular, the following shall apply to data subject access requests, complaints, and data breaches.
  14. CYF shall notify the Customer without undue delay if it receives:
        1. a subject access request from a data subject; or
        2. any other complaint or request relating to the processing of the Personal Data.
  15. CYF shall, at the Customer&#39;s cost, cooperate fully with the Customer and assist as required in relation to any subject access request, complaint, or other request, including by:
        1. providing the Customer with full details of the complaint or request;
        2. providing the necessary information and assistance in order to comply with a subject access request;
        3. providing the Customer with any Personal Data it holds in relation to a data subject (within the timescales required by the Customer); and
        4. providing the Customer with any other information requested by the Customer.
  16. CYF shall notify the Customer immediately if it becomes aware of any form of Personal Data breach, including any unauthorised or unlawful processing, loss of, damage to, or destruction of any of the Personal Data.
  17. The Customer shall be liable for, and shall indemnify (and keep indemnified) CYF in respect of any and all action, proceeding, liability, cost, claim, loss, expense (including reasonable legal fees and payments on a solicitor and client basis), or demand suffered or incurred by, awarded against, or agreed to be paid by, CYF and any Sub-Processor arising directly or in connection with:
        1. any non-compliance by the Customer with the Privacy Legislation or other applicable legislation;
        2. any Personal Data processing carried out by CYF or Sub-Processor in accordance with instructions given by the Customer that infringe the Privacy Legislation or other applicable legislation; or
        3. any breach by the Customer of its obligations under the Agreement, except to the extent that CYF or a Sub-Processor is liable under sub-Clause 22.18.
  18. CYF shall be liable for, and shall indemnify (and keep indemnified) the Customer in respect of any and all action, proceeding, liability, cost, claim, loss, expense (including reasonable legal fees and payments on a solicitor and client basis), or demand suffered or incurred by, awarded against, or agreed to be paid by, the Customer arising directly or in connection with CYF&#39;s Personal Data processing activities that are subject to the Agreement:
        1. only to the extent that the same results from CYF&#39;s or a Sub-Processor&#39;s breach of the Agreement; and
        2. not to the extent that the same is or are contributed to by any breach of the Agreement by the Customer.
  19. The Customer shall not be entitled to claim back from CYF or a Sub-Processor any sums paid in compensation by the Customer in respect of any damage to the extent that the Customer is liable to indemnify CYF or Sub-Processor under sub-Clause 22.17.
  20. Nothing in the Agreement (and in particular, this Clause 22) shall relieve either party of, or otherwise affect, the liability of either party to any data subject, or for any other breach of that party&#39;s direct obligations under the Privacy Legislation. Furthermore, CYF hereby acknowledges that it shall remain subject to the authority of the ICO and shall co-operate fully therewith, as required, and that failure to comply with its obligations as a Data Processor under the Privacy Legislation may render it subject to the fines, penalties, and compensation requirements set out in the Privacy Legislation.
  21. In the event that CYF appoints a Sub-Processor, CYF shall:
        1. enter into a Sub-Processing Agreement with the Sub-Processor which shall impose upon the Sub-Processor the same or similar obligations as are imposed upon CYF by the Agreement;
        2. ensure that the Sub-Processor complies fully with its obligations under the Sub-Processing Agreement and the Privacy Legislation; and
        3. remain fully liable to the Customer for performance of the Sub-Processor&#39;s obligations to the extent the Sub-Processor fails to fulfil their data protection obligations.
  22. Except as provided otherwise herein, CYF shall, at the written direction of the Customer, delete or return Personal Data and copies thereof to the Customer on termination of the Agreement unless required by applicable law to store the Personal Data (and for these purposes the term &quot;delete&quot; shall mean to put such data beyond use).
  23. CYF shall maintain complete and accurate records and information to demonstrate its compliance with this Clause 22 and immediately inform the Customer if, in the opinion of CYF, its instruction infringes the DPA or Privacy Legislation or applicable laws.

23 **Data Sharing**
  1. In certain circumstances, CYF and a Customer may require to share data which includes Personal Data for example to improve and enhance User experience and (2) to perform and/or improve the Services and (3) to obtain relevant marketing, demographic, clinical and other appropriate information from CYF.
  2. The parties shall not process the Shared Personal Data for any purpose or in any way that is incompatible with the Stated Purposes.
  3. The Shared Personal Data shall be disclosed by one party to another only to the extent reasonably necessary for the Stated Purposes.
  4. Each party shall appoint a data protection officer and/or at least one other of its representatives as a point of contact for all issues relating to the sharing of the Shared Personal Data and the Privacy Legislation (including, but not limited to, compliance, training, and the handling of Personal Data breaches).
  5. Both parties shall at all times during the Subscription Term comply with their obligations as Data Controllers, the rights of data subjects, and all other applicable requirements under the Privacy Legislation. These Terms and Conditions are in addition to, and do not relieve, remove, or replace either party&#39;s obligations under the Privacy Legislation. Any material breach of the Privacy Legislation by either party shall, if not remedied within 14 days of written notice from the other party, give the other party grounds to terminate the Agreement with immediate effect.
  6. The extent of the Shared Personal Data, including any applicable restrictions relating to will be agreed between the parties and set out in writing.
  7. Each party shall ensure that the Shared Personal Data is accurate and up-to-date prior to its disclosure to the other party.
  8. The parties shall use compatible technology for the processing of the Shared Personal Data in order to preserve accuracy.
  9. Both parties shall at all times during the Term process the Shared Personal Data fairly and lawfully.
  10. Both parties shall ensure that they have legitimate grounds for processing the Shared Personal Data under the Privacy Legislation.
  11. Both parties shall ensure that they have in place all required notices and consents in order to enable the sharing of the Shared Personal Data under the Agreement. In particular, the parties shall ensure that data subjects are provided with clear and sufficient information about the following:
        1. the purposes for which their Personal Data is to be processed;
        2. the legal basis upon which it is relying for such purposes;
        3. the fact that their Personal Data is to be transferred to a third party and sufficient detail about the transfer to enable the data subject to understand the purpose of the transfer and any risks associated therewith; and
        4. in the event that their Personal Data is to be transferred outside of the United Kingdom or EEA, the fact that such a transfer is to take place and sufficient detail about the transfer to enable the data subject to understand the purpose of the transfer and any risks associated therewith; and
        5. all other information required under the Privacy Legislation.
  12. The parties shall assist one another in complying with their respective obligations and the rights of data subjects under the Privacy Legislation. Such assistance shall include, but not be limited to:
        1. consulting with the other party with respect to information and notices provided to data subjects relating to the Shared Personal Data;
        2. informing the other party about the receipt of data subject access requests and providing reasonable assistance in complying with the same;
        3. not disclosing or otherwise releasing any Shared Personal Data in response to a data subject access request without prior consultation with the other party, whenever reasonably possible;
        4. assisting the other party at the cost of the other party in responding to any other data subject request.
  13. Each party shall maintain records of all data subject requests received, the decisions made in response, and any information provided to the data subject(s) concerned. Such records shall include copies of the request, details of any data accessed and shared, and, if applicable, details of any further correspondence, telephone conversations, or meetings relating to the request.
  14. Each party shall hold and process the Shared Personal Data only for so long as is necessary for the fulfilment of the Stated Purposes.
  15. In the event that any statutory or similar retention periods apply to any of the Shared Personal Data, the relevant Personal Data shall be retained by the relevant party in accordance therewith.
  16. The parties shall delete (or otherwise dispose of) or at a party&#39;s option anonymise the Shared Personal Data (or the relevant part thereof) and any and all copies thereof or, on the written request of the other party, other than in the case of anonymised data, return it to the other disclosing party, subject to any legal requirement to retain any applicable Personal Data, in the following circumstances:
        1. upon the termination or expiry of the Agreement; or
        2. once the Stated Purposes have been fulfilled and it is no longer necessary to retain the Shared Personal Data (or the relevant part thereof) in light of the Stated Purposes;

whichever is earlier.

  17. All Shared Personal Data to be deleted or disposed of or anonymised under the Agreement shall be deleted or disposed of using methods compliant with the Privacy Legislation.
  18. Following the deletion and/or disposal or anonymisation of the Shared Personal Data (as applicable), the party deleting or disposing of the data shall notify the other party of the same in writing, confirming that the Shared Personal Data has been deleted or disposed of or anonymised using methods compliant with the Privacy Legislation.
  19. For the purposes of this Clause the transfer of Shared Personal Data shall refer to any sharing of the Shared Personal Data by a party with a third party. Such sharing shall include, but not be limited to, the appointment of a third-party Data Processor and sharing the Shared Personal Data with a third-party Data Controller.
  20. In the event that a party wishes to appoint a third-party Data Processor, it shall remain liable to the other party for any acts and/or omissions of the third-party processor and it shall comply with Articles 28 and 30 of the Privacy Legislation.
  21. Neither party shall transfer any of the Shared Personal Data outside of the United Kingdom or EEA unless:
        1. that party complies with the provisions of Article 26 of the Privacy Legislation (where the third party is a joint controller); and
        2. that party ensures that the transfer is to a country that the European Commission has determined (by means of an adequacy decision) offers an adequate level of data protection, pursuant to Article 45 of the Privacy Legislation; there are appropriate safeguards in place pursuant to Article 46 of the UK GDPR; or one of the derogations for specific situations set out in Article 49 of the UK GDPR applies.
  22. A party shall transfer the Shared Personal Data to the other party using methods compliant with the Privacy Legislation.
  7. Both parties shall ensure that they have in place appropriate technical and organisational measures as reviewed and approved by the other party, to protect against the unauthorised or unlawful processing of, and against the accidental loss or destruction of, or damage to, the Shared Personal Data, having regard to the state of technological development and the cost of implementing any such measures.
  8. When putting appropriate technical and organisational measures in place, both parties shall ensure a level of security appropriate to the nature of the Shared Personal Data which is to be protected, and to the potential harm resulting from the unauthorised or unlawful processing of, the accidental loss or destruction of, or damage to, the Shared Personal Data.
  9. All technical and organisational measures put in place by both parties shall be reviewed regularly by the respective party, updating such measures upon the agreement of the other party as appropriate throughout the Term of the Agreement.
  10. Both parties shall ensure that any and all of their representatives by whom the Shared Personal Data is to be handled and processed are appropriately trained to do so in accordance with the Privacy Legislation and with the requisite technical and organisational measures.
  11. The parties shall further ensure that any of their respective representatives to whom the Shared Personal Data is to be disclosed are subject to contractual obligations in relation to confidentiality and data protection that bind those Representatives and that are same as the obligations imposed upon the parties by the Agreement.
  12. In the event of a dispute or claim brought by a data subject or the ICO concerning the processing of Shared Personal Data against either or both parties, the parties will inform each other about any such disputes or claims and will cooperate with a view to settling them amicably in a timely fashion.
  13. The parties agree to respond to any generally available non-binding mediation procedure initiated by a data subject or by the supervisory authority. If they do participate in the proceedings, the parties may elect to do so remotely (such as by telephone or other electronic means). The parties also agree to consider participating in any other arbitration, mediation, or other dispute resolution proceedings developed for data protection disputes.

24 **Feedback and Modifications**
  1. The Customer hereby acknowledges that upon submitting Feedback to CYF, the Customer automatically grants to CYF a worldwide, perpetual, irrevocable, royalty free licence to use that Feedback in any way CYF deems appropriate including, but not limited to:
        1. The use, publication, distribution, transmission, broadcasting, licensing, sub-licensing, leasing, lending and sale of the Feedback; and
        2. The creation, use, publication, distribution, transmission, broadcasting, licensing, sub-licensing, leasing, lending and sale of any derivative works based upon the Feedback.
  2. CYF&#39;s use of the Feedback shall not bestow any rights or interests upon the Customer whatsoever.
  3. The Customer hereby acknowledges that any modifications made to the Software at the request or suggestion of the Customer will belong to and be the Intellectual Property of CYF.

25 **Change Orders.**
  1. If the Customer wishes to amend the scope of an Order Form, the parties will use all reasonable endeavours to agree to a Change Order. Each Change Order shall detail the requested changes to the applicable task, responsibility, duty, budget, work programme or other matter. The Change Order will become effective upon the execution of the Change Order by both parties and will include a specified period of time (as agreed upon by the parties) within which CYF will implement the changes and any increase in price.
  2. Both parties agree to act in good faith and promptly when considering a Change Order requested by the other party. CYF reserves the right to postpone effecting material changes in the scope of Professional Services until such time as the parties agree to and execute the corresponding Change Order.

26 **Entire Agreement**

The Agreement and each Order Form comprises the entire express Agreement between the parties and supersedes all previous agreements between the parties. The Agreement can only be altered or amended by either an Order Form or a Change Order.

**PART FOUR – DEFINITIONS.**

27 **Glossary.**

1. “**Affiliate**” means an entity which controls, is controlled by,or is under common control with, a party, and control means the ability to vote 50% or more of the voting securities of any entity or otherwise having the ability to influence and direct the polices and direction of an entity;

2. “**Assessment**” means the creation of a report on the carbon footprint (or carbon emissions results) of an organisation via the Software. This is achieved by inputting key usage data associated with quantified consumption of e.g. electricity, gas, waste, water, travel, materials use, freight and other activities related to that organisations, and converting this usage data into tonnes of carbon dioxide equivalent (tCO2e); 

3. “**Assessment Terms**” means the terms and conditions to be entered into by parties using the Software to create an Assessment, which Assessment Terms are available here [ https://www.compareyourfootprint.com/terms-and-conditions-for-use-of-compare-your-footprint-software/ ];

4. **“Change Order”** means a written statement signed by the parties
       recording any (a) change in the details of an Order Form, or (b)
       change in the assumptions upon which the Order Form is based
       (including, but not limited to, changes in an agreed starting
       date for a Subscription or Professional Services or suspension of
       the services by the Customer or (c) any changes in the fees,
       costs and/or time lines;

5. “**Confidential Information**” means any information that is
       proprietary or confidential which either party directly or
       indirectly discloses, or makes available, to the other, including
       but not limited to, the existence and terms of the Agreement, all
       confidential or proprietary information relating to the business,
       affairs, operations, processes, product information, know-how,
       technical information, designs, trade secrets or software and/or
       Intellectual Property of the party disclosing such information;
6. “**Content**” means any text, graphics, images, audio, video,
       software, data compilations including, but not limited to, text,
       graphics, logos, icons, sound clips, video clips, data
       compilations, page layout, underlying code and software and any
       other form of information capable of being stored in a computer
       that appears on, is uploaded to or forms part of the Subscription
       Services or the Platform; BUT excluding User Content;

7. “**Database**” means the database stored on the Platform which
       contains inter alia User Content;

8. “**Data Controller**”, “**Data Processor**”, “**Personal Data**”
       “**processing**” and “**data subject**” shall have the meanings
       ascribed to them in the Privacy Legislation;

9. “**DPA**” means the Data Protection Act 2018 and any modification,
       amendment or re-enactment thereof;

10. “**Documentation**” means user documentation provided
       electronically by CYF for use with the Subscription Services, including content held within the client area of the CYF web site which Users can subscribe to, in order to gain access
       to online training and learning materials as periodically updated;

11. **“EULA”** means the End User Licence Agreement in the form set out
       in PART SEVEN of the Agreement;

12. “**Feedback**” means all comments, suggestions, requests,
       requirements, improvements, feedback, or other input the Customer
       and Users provide regarding any products or services owned or
       supplied by CYF or its Affiliates;

13. “**Fee(s)**” means the sum or sums payable by the Customer to CYF specified in the relevant Order Form for the provision of Services;

14. “**Force Majeure**”, means circumstances beyond the control of CYF
       which shall include (but shall not be limited to) acts of God,
       perils of the sea or air, fire, flood, drought, explosion,
       sabotage, accident, embargo, riot, civil commotion, including
       acts of local government and parliamentary authority, disease,
       epidemic or pandemic (whether naturally occurring or man-made);
       inability to supply the Subscription Services and or the
       Professional Services, materials, breakdown of Local Equipment
       and labour disputes of whatever nature and for whatever cause
       arising including (but without prejudice to the generality of the
       foregoing) work to rule, overtime bars, strikes and lockouts and
       whether between either of the parties hereto and any or all of
       its employees and/or any other employer and any or all of its
       employees and/or between any two or more groups of employees (and
       whether of either of the parties hereto or any other employer);

15. “**GDPR**” means the General Data Protection Regulation (Regulation
       (EU) 2016/679) as amended, replaced, supplemented or adopted into
       United Kingdom Legislation from time to time;

16. “**ICO**” means the UK’s supervisory authority, the Information
       Commissioner’s Office;

17. **“Industry Best Practice”** means the standard of care, attention,
       diligence, expertise, knowledge, methods and practice expected of
       a competent and experienced professional in the IT and Cyber
       Security profession;

18. “**Insolvency Event**” means the other party (a) enters
       liquidation, or a winding up petition is presented against the
       company; (b) has a receiver, liquidator, administrator, trustee
       or an individual with a similar role appointed over any of its
       assets; (c) proposes to make any arrangements with its creditors
       or passes a resolution to place the company into liquidation;
       or (d) suffers an event which, under the law of a different
       country, is equivalent to any of the previously specified acts or
       events;

19. “**Intellectual Property**” means patents, trademarks, trade name,
       service mark, copyright, trade secrets, know-how, process,
       technology, development tool, ideas, concepts, design right,
       domain names, moral right, database right, methodology, algorithm
       and invention, and any other proprietary information (whether
       registered, unregistered, pending or applied for);

20. **“Local Equipment”** means the Customer or User’s own on-premise
       equipment including hardware and software environment which is
       used in connection with the Software Services, which comprise of,
       but is not limited to - server computers (whether virtual or
       not), Desktop PC’s, Laptops or any other portable device, storage
       systems and relative hardware, firmware, operating software,
       operating system software, networking software, database
       software, anti-virus and security software, switches, power
       supplies and telecommunications infrastructure, internet
       connection, broadband availability and infrastructure, routers,
       Printers, associated peripheral devices or accessories whether
       fixed or portable;

21. “**Order Form**” means a document provided by CYF and signed by the
       Customer that describes the Subscription Services and
       Professional Services being purchased and/or licensed by the
       Customer in terms of the Agreement and any additional conditions
       pertaining thereto substantially in the form comprising PART SIX
       of the Agreement;

22. “**Platform**” means the hardware and software environment in which
       the software element of the Subscription Services operates, which
       comprises one or more server computers (whether virtual or not),
       mirroring/duplicating/back-up and storage systems and relative
       hardware operating software, virtual machine software (where
       relevant), operating system software, database software,
       anti-virus and security software, switches, power supplies and
       telecommunications infrastructure;

23. **“Privacy Legislation”** means the GDPR, (where applicable in
       relation to the storage retention and processing of EU personal
       data), the DPA, the retained EU law version of the General Data
       Protection Regulation ((EU) 2016/679) (the “UK GDPR”), as it
       forms part of the law of England and Wales, Scotland, and
       Northern Ireland by virtue of section 3 of the European Union
       (Withdrawal) Act 2018, the DPA, the UK equivalent of the Data Protection Directive
       (95/46/EC), the Regulation of Investigatory Powers Act 2000, the
       Telecommunications (Lawful Business Practice) (Interception of
       Communications) Regulations 2000 (SI 2000/2699), the UK equivalent of the Electronic
       Communications Data Protection Directive (2002/58/EC), the
       Privacy and Electronic Communications (EC Directive) Regulations
       2003 (SI 2426/2003) and all applicable laws and regulations
       relating to the processing of personal data and privacy,
       including where applicable the guidance and codes of practice
       issued by the ICO (as amended or replaced from time to time);

24. “**Privacy Policy**” means CYF’s policy relating to User Content
       and compliance with (amongst others) the Privacy Legislation from
       time to time, the current version of which is located here
       https://app.compareyourfootprint/privacy-policy

25. “**Professional Services**” means the training, consulting,
       development and other professional services identified on an
       Order Form but does not include the Subscription Services;

26. “**Shared Personal Data**” means the personal data and special
       category personal data to be shared between CYF and the Customer
       under these Terms and Conditions;

27. “**SLA**” means a Service Level Agreement in the form set out in
       PART FIVE of the Agreement;

28. **“Services”** means the Subscription Services and the Professional
       Services;

29. “**Software**” means the Compare Your Foortprint™ proprietary
       carbon footprint calculation and benchmarking software and the
       Third-Party Software written in object and source code residing
       on and used for operating the Platform and the Subscription
       Services as Updated and Upgraded from time to time;

30. “**Sub-licence”** means a licence of Subscription Services or any
       part thereof granted by the Customer to a Client in terms of the
       Agreement and in the form of the EULA;

31. “**Sub-Processor**” means a sub-processor appointed by CYF to
       process the Personal Data;

32. “**Sub-Processing Agreement**” means an agreement between CYF and a
       Sub-Processor governing the Personal Data processing carried out
       by the Sub-Processor, as described in Clause 22;

33. “**Subscription Services**” means the hosted Customer experience
       solutions identified in an Order Form, and any modifications
       periodically made by CYF, but does not include the Professional
       Services;

34. “**Subscription Term**” means the period of time during which CYF
       is required to provide the Customer with the Subscription
       Services as specified in the relevant Order Form;

35. “**Terms and Conditions**” means these terms and conditions as varied, amended or replaced from time to time and
     intimated to the Customer;

36. “**Third-Party Software**” means software other than the Software
       which belongs to third parties and in relation to which CYF has
       the right to grant sub-licenses;

37. “**Update**” means any update, update rollup, service pack, feature
       pack, critical update, security update, or hotfix that is used to
       improve or to fix a software product;

38. “**Upgrade**” means a software package that replaces an installed
       version of a product with a newer version of the same product,
       typically leaving existing customer data and preferences intact
       while replacing the existing software with the newer version;

39. “**User**” means the Customer and any of its employees, or a person
       to whom the Customer has outsourced services, that has permission
       to accesses the Subscription Services as a named user and is not
       employed by CYF and acting in the course of their employment;

40. “**User Content**” means any text, graphics, images, audio, video,
       software, data compilations and any other form of information
       capable of being stored in a computer that appears on, is
       uploaded to or forms part of a User Site or the Subscription
       Services and has been uploaded by a User;

41. “**User Site**” means a partition/tenancy/instance on the Platform
       created by CYF for the Customer and/or User or Users accessing
       the Subscription Services which shall contain User Content and
       shall be hosted on the Platform;

42. **“Working Day”** means any day (1) which is neither a Saturday or
       a Sunday or a public holiday in any part of the United Kingdom
       and (2) upon which the Bank of England is open for business.

**PART FIVE – SLA**

1  **Server availability Service Level Agreement (SLA)**


This SLA is designed to ensure that CYF meets the needs of its Customers
and it outlines what levels of service can be expected.

| Service Line    | SLA               | KPI (%) |
| --------------- | ----------------- | ------- |
| System Availability | To be made available to Users 24/7 | 99 |
| Support Hours: | CYF will respond within defined service levels (see below) | 99 |
| Technical Support - Second Line | Available 9.00am – 5.00pm GMT | 99 |


2 **Incident Resolution Targets**



|     | Severity Description    | Response              | Resolution |
| --------------- | --------------- | ----------------- | ------- |
| 2.1 | Unplanned outage impacting multiple Users |1hr |<24 hrs|
| 2.2 | Outage / severe disruption to service for several Users | 2hrs | within 2 Working Days|
| 2.3 | Reduced functionally causing disruption to business | 4hrs | within 3 Working Days|
| 2.4 | Non-urgent / reduced functionality with low impact | 8hrs | within 5 Working Days|
| 2.5 | On Demand Professional services requests for installations | 8hrs | Agree with Customer|

CYF shall use reasonable endeavours to provide the CYF support services (“CYF Support”)  in accordance with these Service Levels and Response Times. 
These Response times refer only to the time within which CYF shall respond to a CYF Support request. 
CYF gives no guarantee as to the time any given issue may take to resolve save that it hereby undertakes to use 
reasonable endeavours to resolve issues as quickly as is reasonably possible within the target resolution times specified in the table above.

3 **CYF Support shall include:**

1.	Customer response facilities.
       
       1. For software issues, the Customer can email support@compareyourfootprint.com to contact CYF Support which is available during normal business hours (Contracted Hours)
       2. The Customer Contact must submit sufficient material and information to enable CYF Support staff to duplicate the problem. 
       3. CYF Support is strictly not a training facility. Its objective is to troubleshoot and resolve Software Operational Issues.
       4. CYF Support is not a hardware or Local Equipment IT support facility. CYF does not support or warrant your Local Equipment, or any other system or service that you subscribe to. Its sole purpose is to support the CYF Software only. Anything else is strictly the Customer’s responsibility.
       5. Additional Service Levels: additional service levels shall be as follows:
              
          1. “Contracted hours” are Monday to Friday- 9am to 5pm Monday to Friday GMT.



2. CYF Support Proceedures.

    1. Answer the support case, received via email:
       
       1. Agree case priority

    2. Take action on the case:

        1. Confirm that this is a CYF problem

        2. Use reasonable endeavours to resolve the problem reported by Customer/User

        3. Report to Customer if problem cannot be resolved

        4. Pass to a third party if not an CYF problem

        5. Inform Customer of progress

    3. Close the case:

        1. Inform Customer of resolution

        2. Review Service Level

    4. Correction of critical errors or assistance to overcome problems.

    5. CYF may, at its sole discretion, correct errors by "patch" or by version update.

    6. Provision of Information on availability of new versions of Licensed Software.

    7. Provision Consultancy advice (chargeable at agreed rates may apply where appropriate) on Software development,

    8. enhancements and modifications, together with estimates for the same.

    9. Provide Installations and Re-installations of CYF as are required and reasonable to support use of CYF software.

**PART SIX – ORDER FORM**

**The following Order Form is a template to be used and completed each time the Customer wishes to order new Subscription or Professional Services and the terms have been agreed between the parties.**
    
This Order Form ("Order Form") is between **COMPARE YOUR FOOTPRINT LTD,** a company incorporated in Scotland (Registered No. SC689618) with its registered office at 7 Ferrymuir Lane, South Queensferry, Scotland, EH30 9PA (**“CYF”**) and **** company incorporated in [   ] (Registered No. [   ]) with its registered office at [   ] (**“the Customer”**) and relates to the Services Agreement entered into between CYF and the Customer dated ***** (the **"Agreement"**).
    
The parties hereby agree as follows:
    1.  This document constitutes an "Order Form" under the Agreement, and
        this Order Form is subject to the terms and provisions of the
        Agreement.
    2.  In this Order Form, unless specified otherwise, words and phrases
        shall have the same meanings as those in the Agreement.
    
**Subscription Services**

1.  Number of Users:

2.  Price per User:

3.  Subscription Term:

4.  Number of Sub-licenses

**Professional Services:**

1.  Customisation/White-labelling:

    1.  New Functionality

    2.  Evaluation

    3.  Evaluation Criteria

    4.  Data/materials to be provided by Customer

    5.  Resources to be provided by Customer

2.  Acceptance Testing:

    1.  Acceptance Testing Criteria

3.  Programme for delivery of Professional Services

4.  Training:

5.  Provision of support and information to detect and clarify possible
    impositions and abuses of the Customer’s business

6.  \[Others\]

7.  Service Levels

8.  Fees:

    1.  Milestone payments

    2.  Invoicing Terms

    3.  Currency \[GBP\]

    4.  Exchange Rate

**Sub-contractors**

Any Affiliates and Sub-contractors used in delivering either the Subscription Services or the Professional Services must be approved by the Customer (such approval not to be unreasonably withheld) and shall be bound by all the terms and conditions of the Agreement and this Order Form. The use of any approved Sub-contractor or Affiliate by CYF will not absolve CYF of its contractual obligations to the Customer under the Agreement and CYF shall be liable to the Customer for the work carried out by any such Sub-contractor or Affiliate as if CYF had carried out such work itself. Any Sub-contractors or consultants (other than CYF’s Affiliates) that will be used by CYF in performing the Services are listed below:
[Specify all Sub-contractors and Affiliates to be used in delivering either the Subscription Services or the Professional Services]

**Special Conditions:**

If there is any conflict or inconsistency between the terms of this
Order Form and the Agreement, the terms of the Order Form shall have
precedence.

This Order Form forms part of the Agreement.

ACKNOWLEDGED, ACCEPTED AND AGREED TO:

Compare Your Footprint Ltd By:     			

          				    		
Name: ________ Title:______		                			                              		
Date: ________		                             	

[   ] By:  

Name: ________ Title:______		                			                              		
Date: ________	

**PART SEVEN – EULA**

The Agreement is between **COMPARE YOUR FOOTPRINT LTD,** a company
incorporated in Scotland (Registered No. SC689618) with its registered
office at 7 Ferrymuir Lane, South Queensferry, Scotland, EH30 9PA and
you (**“the User”**). This is a Click to Agree Contract from within the
Software if the User wishes to be bound by these Terms and Conditions.
If the User does not agree to be bound by these Terms and Conditions,
the User cannot USE the Software.

The contract (“Agreement”) between the User and CYF will comprise:

1.  these Terms and Conditions; and

2.  the Privacy Policy;

**PART ONE – SUBSCRIPTION SERVICES.**

1  **Access to the Software.**

CYF grants to the User a non-exclusive, royalty based, non-sublicensable licence to Use the Software for the User’s personal business use for the duration of the Agreement, subject to the following conditions: -
       
1. The Software is located on the Platform. CYF has full administrative access rights to the Platform. Users may access the Software but have no right to administer the Platform or receive a copy of the object code or source code to the Software.

2  **Conditions of Use.**

The rights to use the Software provided to the User are non-exclusive,
non-transferable and are for the User’s personal business use only. The
User’s right to use the Software is subject to the following
conditions:-

the User shall not:

1.  Transfer to any other person any of its rights to use the Software;

2.  Sell, license, rent or lease the Software;

3.  Make the Software available to anyone who is not a User;

4.  Create any derivative works based upon the Software or Documentation;

5.  Copy any feature, design or graphic in, or reverse engineer the Software (including without prejudice to the foregoing generality the graphical user interface and menu command hierarchy);

6.  Access the Software (i) in order to build a competitive solution or to assist someone else to build a competitive solution; or (ii) if the User is an employee or contractor of a CYF competitor;

7.  Use the Software in a way that violates any criminal or civil law;

8.  Load test the Software in order to test scalability; or,

9.  Exceed any specified usage limits listed the Documentation.

3 **User Content/Security**

1.  Users provide all data for use in the Software, and CYF is not obliged to modify or add to User Content except as specified in 
       Clause 4. The User is solely responsible for User Content and
       the accuracy of User Content.

2.  User Content belongs to the User or its licensors, and CYF makes
       no claim to any right of ownership in it.

3.  CYF shall keep User Content confidential in accordance with
       Clause 10 of the Agreement.

4.  Subject to the terms of Clause 4, CYF shall only be entitled to
       use User Content strictly as necessary to carry out its
       obligations under the Agreement, and for no other purpose.
       However, CYF:

       1.  may observe and report back to the User on the User’s usage
              of the Software, and make recommendations for improved usage
            of the Software;

       2.  may identify trends and publish reports on its findings from
            data aggregated from more the User Site(s) provided such
            reports do not identify the User and otherwise anonymise the
            data and comply with the terms of Clause 10
            (Confidentiality);

5.  The parties shall comply with the principles of the Privacy
        Legislation and in accordance with the Privacy Policy.

6.  Sharing of accounts is not permitted unless expressly authorised
        in writing by CYF. Users must keep account details confidential
        and Users should not reveal their username or password to any
        unauthorised third parties. CYF accepts no liability for any
        losses or damages incurred as a result of account details being
        shared in breach of the terms of the Agreement. It is
        recommended that Users do not save account details in their
        internet browser.

7.  Passwords must be strong, robust, robust and difficult to break
        and changed on a regular basis.

8.  Whereas as part of the Subscription Services, CYF may host email
        accounts or other online communications infrastructure or
        subscription accounts (including the CYF subscription itself)
        for Users, CYF accepts no responsibility and shall not be liable
        for third parties accessing such email, online communications
        accounts or subscription accounts by way of breaking or hacking
        passwords. It is the responsibility of Users to ensure that all
        email, online communications accounts and subscription accounts
        are properly protected with robust passwords. The terms of
        Clause 4 apply to the use of any such email, online
        communications accounts and subscription accounts.

4  **Acceptable Usage Policy**

1.  Without prejudice to the generality of Clause 2.7, when using the Software; Users should do so in accordance with the following rules:
    
       1.  Users must not use obscene or vulgar language;
    
       2.  User Sites may not contain any material that is unlawful or otherwise objectionable (including that which may be in breach of rules, regulations or legislation in force in the United Kingdom or any other jurisdiction in which the User’s User Site can be lawfully accessed. This does not extend to material which may be automatically blocked in certain jurisdictions but that is lawful in the User’s home country);
    
       3.  User Sites may not contain any material that is intended to
                promote or incite violence or any other unlawful conduct
                against any group, individual or animal. This includes, but
                is not limited to, the provision of instructions on how to
                assemble weapons of any kind, bombs, grenades or other
                explosive devices;
    
       4.  User Sites may not infringe the Intellectual Property rights
                of any third party including, but not limited to, copyright,
                trademarks, patents and designs;
    
       5.  User Sites may not contain any material that may contain
                viruses or other software or instructions that may damage or
                disrupt other software, computer hardware or communications
                networks;
    
       6.  User Sites may not be used for unauthorised
                mass-communications such as “spam” or “junk mail”; and
    
2.  CYF does not screen or pre-approve any User Site or User Content
       (although Users acknowledge that CYF may do so if it wishes).
    
3.  CYF may edit a User Site to comply with the provisions of sub-Clause 4.1 without prior consultation. In cases of severe breaches of the provisions of sub-Clause 4.1, a User Site may
              be taken down and the relevant account may be suspended or
              terminated. The User will not be informed in writing of the
              reasons for such alterations or take downs.
    
4.  CYF accepts no responsibility or liability for any infringement of third-party rights by User Sites.
    
5.  CYF will not be liable in any way or under any circumstances for any loss or damage that any User may incur as a result of such User Sites, or CYF exercising its rights under the Agreement,
              nor for any errors or omissions in User Sites. Use of and
              reliance upon User Sites is entirely at the User’s own risk.
    
6.  The User acknowledges that CYF may retain copies of any and all
            communications, information, User Content and User Sites sent
            to CYF.
    
5  **Intellectual Property**
    
1.  Subject to the exceptions in Clause 6 of the Agreement, all
            Content, that is not User Content, and the Database and the
            Software and the Documentation are the property of CYF, or CYF’s
            Affiliates or licensors. By continuing to use the Software the
            User acknowledges that such material is protected by applicable
            United Kingdom and international Intellectual Property and other
            laws.
    
2.  You may print, reproduce, copy, distribute, store or in any
            other fashion re-use Content from the Software for personal or
            educational purposes only unless otherwise given CYF’s express
            written permission to do so. Specifically, the User agrees that
            it will not systematically copy Content from the Software with a
            view to creating or compiling any form of comprehensive
            collection, compilation, directory or database unless given
            CYF’s express written permission to do so.
    
3.  In the event that new inventions, designs or processes evolve in
            performance of or as a result of the Agreement, the User
            acknowledges that the same shall be the property of CYF unless
            otherwise agreed in writing by CYF.
    
4.  Any trade mark, trade name or logo such as “Powered by CYF”
            appearing on or in the Software is the property of CYF and must
            not be copied, obscured or removed from the Software.

6  **User Site Intellectual Property**

1.  The Intellectual Property rights subsisting in the User Content
                of User Sites belong to the User to which that/those User
                Site(s) belong(s) unless it is expressly stated otherwise.
        
2.  Where expressly indicated, certain Content available through
                User Sites and the Intellectual Property rights subsisting
                therein belongs to other parties.
        
3.  The third party Content described in this Clause 6, unless
                expressly stated to be so, is not covered by any permission
                granted by Clause 5 of these Terms and Conditions to use
                Content.
        
4.  For the avoidance of doubt, the Database (excluding the User
                Content therein) shall not be considered User Content.
        
7  **Third Party Intellectual Property**
        
1.  Unless otherwise expressly indicated, all Intellectual Property
                rights including, but not limited to, Copyright and Trademarks,
                in Content belong to the manufacturers or distributors of such
                products as may be applicable.
        
2.  Subject to Clause 5 the User may not reproduce, copy,
                distribute, store or in any other fashion re-use Content unless
                otherwise indicated on the Software or the Documentation or
                unless given express written permission to do so by the relevant
                manufacturer or supplier.
        
8  **Warranty Disclaimer.**
        
1.  Except as expressly provided in the Agreement, the Software and
                Professional Services are provided with no other warranties of
                any kind, and CYF disclaims all other warranties, express or
                implied, including without limitation any warranty of
                merchantability or fitness for a particular purpose. CYF does
                not warrant that the use of the Subscription Services shall be
                uninterrupted or error-free.

2.   Without prejudice to the foregoing Clause, CYF does not give any guarantee of any specific results from the results of the use of the Services. Without prejudice to the foregoing, CYF does not guarantee that the User will make any monetary savings, will be more profitable or successful or be able to avoid any government taxes or penalties in any particular jurisdiction. 
        
9  **Limitation of Liability**
        
1.  Neither party shall be liable under the Agreement for any
                indirect, special, incidental, punitive or consequential damages
                (including without limitation damages for loss of goodwill, work
                stoppage, computer failure or malfunction, lost or corrupted
                data, lost profits, lost business or lost opportunity), or any
                other similar damages under any theory of liability (whether in
                contract, tort/delict, strict liability or any other theory),
                even if the other party has been informed of this possibility.
                Each party’s total liability for any direct loss, cost, claim or
                damages of any kind related to the Agreement or the relevant
                Order Form shall not exceed the sum of £10,000. However, there
                is no limitation on direct loss, claim or damages arising as a
                result of an infringement of either party’s Intellectual
                Property rights by the other party, or a breach of the Privacy
                Legislation by the other party.
        
2.  CYF’s liability under the Agreement (except where provided
                otherwise in the Agreement to a lesser extent) shall be limited
                to the amount of professional indemnity insurance underwritten
                in the name of CYF which shall be £1,000,000. This limitation
                shall not apply to a breach of the Privacy Legislation.

10 **Confidentiality**

1.  Each party may be given access to Confidential Information from
       the other party in order to perform its obligations under the
       Agreement. A party’s Confidential Information shall not be
       deemed to include information that (i) is now, or subsequently
       becomes, through no act or failure to act on the part of
       receiving party (the “Receiver”), generally known or
       available; (ii) is known by the Receiver at the time of
       receiving such information, as evidenced by the Receiver’s
       records; (iii) is subsequently provided to the Receiver by a
       third party, as a matter of right and without restriction on
       disclosure; or (iv) is required to be disclosed by law,
       provided that the party to whom the information belongs is
       given prior written notice of any such proposed disclosure.
            
2.  Subject to clauses 10.4, each party shall hold the other’s
       Confidential Information in confidence and not make the
       other’s Confidential Information available to any third party
       (other than to a consultant or a Sub-contractor for the
       purposes of the Agreement and which consultant or
       Sub-contractor shall have entered into undertakings of
       confidentiality in relation to the Confidential Information on
       terms no less onerous than those contained in this Clause 10),
       or use the other’s Confidential Information for any purpose
        other than to carry out its obligations under the Agreement.
            
3.  Each party shall take all reasonable steps to ensure that the
       other’s Confidential Information to which it has access is not
       disclosed or distributed by its employees or agents in
       violation of the terms of the Agreement.
            
4.  A party may disclose Confidential Information to the extent such
       Confidential Information is required to be disclosed by law,
       by any governmental or other regulatory authority or by a
       court or other authority of a competent jurisdiction, provided
       that, to the extent it is legally permitted to do so, it gives
       the other party as much notice of such disclosure as possible
       and, where notice of disclosure is not prohibited and is given
       in accordance with this clause 10.4, it takes into account the
       reasonable requests of the other party in relation to the
       content of such disclosure.
            
5.  No party shall make, or permit any person to make, any public
       announcement concerning the Agreement without the prior
       written consent of the other parties (such consent not to be
       unreasonably withheld or delayed), except as required by law,
       any governmental or regulatory authority (including without
       limitation, any relevant securities exchange), any court or
       other authority of competent jurisdiction.
            
6.  This clause 10 shall survive termination of the Agreement or any
       Order Form, howsoever arising.
            
11 **Indemnification by the User**
            
1.  The User shall indemnify and hold harmless CYF, its Affiliates,
       directors, and employees from any damages finally awarded
       against CYF (including, without limitation, reasonable costs
       and legal fees incurred by CYF) arising out of any third party
       suit, claim or other legal action (including but not limited
       to any governmental investigations, complaints and actions) in
       connection with the User Content, including, without
       limitation, any action for infringement of any trademark,
       copyright, trade secret, right of publicity or privacy
       (including defamation), patent or other proprietary right with
       respect to the User Content (“Legal Claim”).
            
2.  CYF shall give written notice to the User of any Legal Claim no
       later than 30 days after first receiving notice of a Legal
       Claim and shall give copies to the User of all communications,
       notices and/or other actions relating to the Legal Claim. CYF
       shall give the User the sole control of the defence of any
       Legal Claim, shall act in accordance with the reasonable
       instructions of the User and shall give the User such
       assistance as the User reasonably requests to defend or settle
       such claim. The User shall conduct its defence at all times in
       a manner which is not adverse to CYF’s interests. CYF may
       employ its own counsel to assist it with respect to any such
       claim. CYF shall bear all costs of engaging its own counsel,
       unless engagement of counsel is necessary because of a
       conflict of interest with the User or its counsel, or because
       the User fails to assume control of the defence. CYF shall not
       settle or compromise any Legal Claim without the User’s
       express written consent.
            
12 **Law**
            
1.  The Agreement shall be governed by the laws of Scotland. The
       parties consent to the exercise of exclusive jurisdiction of
       the Courts of Scotland.

13 **Feedback Feedback and Modifications**

1.  The User hereby acknowledges that upon submitting Feedback to
       CYF, the User automatically grants to CYF a worldwide,
       perpetual, irrevocable, royalty free licence to use that
       Feedback in any way CYF deems appropriate including, but not
       limited to:
   
       1.  The use, publication, distribution, transmission,
              broadcasting, licensing, sub-licensing, leasing, lending
              and sale of the Feedback; and
   
       2.  The creation, use, publication, distribution, transmission,
              broadcasting, licensing, sub-licensing, leasing, lending
              and sale of any derivative works based upon the Feedback.
   
2.  CYF’s use of the Feedback shall not bestow any rights or interests upon the User whatsoever.
   
3.  The User hereby acknowledges that any modifications made to the
       Software at the request or suggestion of the User will belong
       to and be the Intellectual Property of CYF.

**PART TWO – DEFINITIONS.**

14 **Glossary.**
       
1.  **“Affiliate”** means an entity which controls, is controlled
       by, or is under common control with, a party, and **control**
       means the ability to vote 50% or more of the voting securities
       of any entity or otherwise having the ability to influence and
       direct the polices and direction of an entity;
       
2.  **“Content”** means any text, graphics, images, audio, video,
       software, data compilations including, but not limited to,
       text, graphics, logos, icons, sound clips, video clips, data
       compilations, page layout, underlying code and software and
       any other form of information capable of being stored in a
       computer that appears on, is uploaded to or forms part of the
       Software or the Platform; BUT excluding User Content;
       
3.  **“Database”** means the database stored on the Platform which
       contains *inter alia* User Content;
       
4.  **“DPA”** means the Data Protection Act 2018 and any
       modification, amendment or re-enactment thereof;
       
5.  **“Documentation”** means user documentation provided
       electronically by CYF for use with the Software, as
       periodically updated;
       
6.  **“Feedback”** means all comments, suggestions, requests,
       requirements, improvements, feedback, or other input the User
       provides regarding any products or Services owned or supplied
       by CYF, its Affiliates and licensees;
       
7.  **“GDPR”** means the General Data Protection Regulation
       (Regulation (EU) 2016/679) as amended, replaced, supplemented
       or adopted into United Kingdom Legislation;
       
8.  **“Intellectual Property”** means patents, trademarks, trade
       name, service mark, copyright, trade secrets, know-how,
       process, technology, development tool, ideas, concepts, design
       right, domain names, moral right, database right, methodology,
       algorithm and invention, and any other proprietary information
       (whether registered, unregistered, pending or applied for);
       
9.  **“Platform”** means the hardware and software environment in
       which the software element of the Software operates, which
       comprises one or more server computers (whether virtual or
       not), mirroring/duplicating/back-up and storage systems and
       relative hardware operating software, virtual machine software
       (where relevant), operating system software, database
       software, anti-virus and security software, switches, power
       supplies and telecommunications infrastructure;
       
10. **“Privacy Legislation”** means the GDPR (where applicable in
       relation to the storage retention and processing of EU
       personal data), the DPA, the retained EU law version of the
       General Data Protection Regulation ((EU) 2016/679) (the “UK
       GDPR”), as it forms part of the law of England and Wales,
       Scotland, and Northern Ireland by virtue of section 3 of the
       European Union (Withdrawal) Act 2018 the DPA, the UK equivalent of the
       Data Protection Directive (95/46/EC), the Regulation of
       Investigatory Powers Act 2000, the Telecommunications (Lawful
       Business Practice) (Interception of Communications)
       Regulations 2000 (SI 2000/2699), the UK equivalent of the Electronic Communications
       Data Protection Directive (2002/58/EC), the UK equivalent of the Privacy and
       Electronic Communications (EC Directive) Regulations 2003 (SI
       2426/2003) and all applicable laws and regulations relating to
       the processing of personal data and privacy, including where
       applicable the guidance and codes of practice issued by the
       ICO. (as amended or replaced from time to
       time);
       
11. “**Privacy Policy**” means CYF’s policy relating to User Content
       and compliance with (amongst others) the Privacy Legislation
       from time to time, the current version of which is located
       [https://www.compareyourfootprint.com/privacy-policy/].
       
12. **“Software”** means the Compare Your Footprint™ proprietary
       carbon footprint calculation and benchmarking software and the
       Third Party Software written in object and source code
       residing on and used for operating the Platform and the
       Software as Updated and Upgraded from time to time;
       
13. **“Third Party Software”** means software other than the
       Software which belongs to third parties and in relation to
       which CYF has the right to grant sub-licenses;
       
14. **“Update”** means any update, update rollup, service pack,
       feature pack, critical update, security update, or hotfix that
       is used to improve or to fix a software product;
       
15. **“Upgrade”** means a software package that replaces an
       installed version of a product with a newer version of the
       same product, typically leaving existing customer data and
       preferences intact while replacing the existing software with
       the newer version;
       
16. **“User”** means the person that has permission to accesses the
       Software as a named user and is not employed by CYF and acting
       in the course of their employment;
       
17. **“User Content”** means any text, graphics, images, audio,
       video, software, data compilations and any other form of
       information capable of being stored in a computer that appears
       on, is uploaded to or forms part of a User Site or the
       Software and has been uploaded by a User;
       
18. **“User Site”** means a partition/tenancy on the Platform
       created by CYF for a User or Users accessing the Software
       which shall contain User Content and shall be hosted on the
       Platform;

**SCHEDULE 1**

**Processing, Personal Data and Data Subjects**

1  **Processing by CYF**

1.  Scope –CYF shall process Personal Data in order to provide the
       Subscription Services and Professional Services, as set out in
       the Agreement.

2.  Nature - Those processing operations that are necessary to
       enable CYF to perform its obligations under the Agreement

3.  Purpose of Processing - Personal Data may be processed for the
       purpose of providing the Professional Services and the
       Subscription Services.

4.  Duration of the Processing - The duration of the Agreement

5.  Types of Personal Data – Personal Data may include without limitation:
       1. User First Name

       2. User Last Name

       3. User Email Address

6. Categories of Data Subject – The Customer’s employees

2  **Rights and Obligations of Data Controller**

1.  The rights and obligations of the Data Controller set out in the Agreement and the Privacy Legislation.

**List of Sub-processors**

1.  Compare Your Footprint Ltd uses the following Sub-processors for the purpose of providing the Professional Services and the Subscription Services.

|  SUB-PROCESSOR   | ACTIVITY CARRIED OUT    | SECTOR              | LOCATION |
| --------------- | --------------- | ----------------- | ------- |
| CircleCo, Inc. | Online community and forum |Software|USA|
| Continually Ltd | Website chatbot and chat function | Software | UK|
| PandaDoc Inc. | E-Contracts | Software | USA|
| Zapier Inc. | Providing automation support between services | Software | USA|
| Calltech Limited| Providing answerphone support | Receptionist | UK|
| Insightly Inc.| Customer relationship management| Software | USA|
| Calendly LLC| Meeting bookings | Software | USA|
| Active Campaign LLC| Webforms, marketing and mail-server| Software | USA|
| Ringcentral Inc.| Providing VOIP telephone services | Software | USA|
| Aidaform| Webforms| Software | Germany|
| GoCardless| Direct debit payments | Finance | UK|
| FreeAgent| Accountancy software | Finance | UK|
| Stripe| Digital payments| Finance| UK|


`;

export default softwareLicenseAgreement;
