import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../store/actions/facilityActions';
import { AddButton } from '../../styledComponents/GlobalStyle';

const DeleteFacilityModal = ({
  facMetricsDeleteModal,
  showFacilityMetricDeleteModal,
  deleteFacilityData,
  facilitiesDeleteModal,
  showFacilitiesDeleteModal,
  deleteFacilities,
  showLoader,
  branding,
}) => {
  useEffect(() => {}, [facilitiesDeleteModal?.errorMsg]);

  if (!(facMetricsDeleteModal?.status || facilitiesDeleteModal?.status)) return null;
  const { dashboardSecondIcon, cancelBtn } = branding.colors;

  // Facility Metric Modal
  if (facMetricsDeleteModal?.status) {
    return (
      <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button onClick={() => showFacilityMetricDeleteModal(false, null)} className='close'>
                x
              </button>
              <h4 className='modal-title'>Are you sure you wish to delete this facility metric?</h4>
            </div>
            <div className='modal-body'>
              <p>This action cannot be undone.</p>
              <AddButton
                onClick={() => deleteFacilityData(facMetricsDeleteModal.item.id)}
                className='btn btn-danger'
                style={{ marginRight: '10px' }}>
                Yes, please delete this data
              </AddButton>

              <AddButton
                id='cancel-facilityMetric-button'
                onClick={() => showFacilityMetricDeleteModal(false, null)}
                className='btn btn-success'
                style={{ backgroundColor: cancelBtn || dashboardSecondIcon }}>
                Cancel
              </AddButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Facilities Modal
  else if (facilitiesDeleteModal?.status) {
    return (
      <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button onClick={() => showFacilitiesDeleteModal(false, null)} className='close'>
                x
              </button>
              <h4 className='modal-title'>Are you sure you wish to delete this facility?</h4>
            </div>
            <div className='modal-body'>
              <p>This action cannot be undone.</p>
              <div style={{ display: facilitiesDeleteModal?.errorMsg ? 'block' : 'none' }} className='alert-message'>
                <i className='fa-solid fa-triangle-exclamation' />
                <span style={{ fontSize: 15 }}>{facilitiesDeleteModal?.errorMsg}</span>
              </div>
              <AddButton
                id='confirmDeleteFacilityBtn'
                onClick={() => deleteFacilities(facilitiesDeleteModal.item.id)}
                className='btn btn-danger'
                disabled={facilitiesDeleteModal?.errorMsg ? true : false}
                style={{ marginRight: '10px' }}>
                {showLoader && <i className='fa fa-spin fa-spinner icon-spinner' />}
                Yes, please delete this data
              </AddButton>

              <AddButton
                id='cancel-facility-button'
                style={{ backgroundColor: cancelBtn || dashboardSecondIcon }}
                onClick={() => showFacilitiesDeleteModal(false, null)}
                className='btn btn-success'>
                Cancel
              </AddButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    facMetricsDeleteModal: state.facility?.facilityDataDeleteModal,
    facilitiesDeleteModal: state.facility?.facilitiesDeleteModal,
    showLoader: state?.showLoader,
    branding: state.branding,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showFacilityMetricDeleteModal: (status, item) => dispatch(Actions.showFacilityMetricDeleteModal(status, item)),
  deleteFacilityData: (id) => dispatch(Actions.deleteFacilityData(id)),
  showFacilitiesDeleteModal: (status, item, errorMsg) => dispatch(Actions.showFacilitiesDeleteModal(status, item, errorMsg)),
  deleteFacilities: (id) => dispatch(Actions.deleteFacilities(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFacilityModal);
