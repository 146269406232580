import React, { useState, useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { CreditScore } from '@mui/icons-material';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';

const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#666',
      color: '#000',
      fontWeight: 500,
      fontFamily: 'Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      display: 'flex',
      ':-webkit-autofill': { color: '#666' },
      '::placeholder': { color: '#666' },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
    valid: {
      iconColor: 'green',
      color: 'green',
    },
  },
};

const AddNewCard = ({
  stripeIntent,
  getStripePaymentMethods,
  toggleAlertMessage,
  getStripeIntent,
  setAddCard,
  addCard,
  stripeCustomerId,
  createStripeCustomer,
  updateStripeCustomer,
}) => {
  const [saveCard, setSaveCard] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (stripeCustomerId.startsWith('cus')) {
      (async () => await getStripeIntent())();
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveCard(true);

    if (saveCard) return;

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    let clientSecret = stripeIntent;

    if (!stripeCustomerId.startsWith('cus')) {
      // Create a stripe customer, the true is to create an intent as well
      const response = await createStripeCustomer(true);
      console.log('response', response);
      const { client_secret } = response.data;
      clientSecret = client_secret;
    }

    const savedCard = await stripe.confirmCardSetup(clientSecret, { payment_method: { card: cardElement } });
    console.log('savedCard', savedCard);
    if (savedCard.error) {
      toggleAlertMessage(true, savedCard.error.message);
      setSaveCard(false);
      if (addCard) setAddCard(false);
    } else {
      if (addCard) setAddCard(false);
      setSaveCard(false);
      await updateStripeCustomer('invoice_settings', `{default_payment_method:${savedCard.setupIntent.payment_method}}`);
      await getStripePaymentMethods();
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ padding: '20px 0px', width: '55%' }}>
        <div className='add-card-component_wrapper' style={{ height: 70, padding: '25px 0px 0px 15px' }}>
          <CardElement options={CARD_OPTIONS} />
        </div>
      </div>
      <div>
        <span>
          <AddButton className='btn btn-success' onClick={handleSubmit} style={{ float: 'left', display: 'flex' }}>
            {!saveCard ? <CreditScore sx={{ marginRight: 1 }} /> : <i className='fa fa-spin fa-spinner icon-spinner' />}
            {!saveCard ? 'Add Card' : 'Saving Card...'}
          </AddButton>
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { firstName, lastName, email } = state.profile?.details || {};
  const { vatNumber } = state.organisation?.details || {};
  const customerDetails = { name: firstName + ' ' + lastName, email, vatNumber };
  return {
    currentOrg: state.currentOrg,
    stripeIntent: state.payment?.stripe?.intent?.client_secret,
    stripeCustomerId: state.payment?.stripe?.stripeCustomerId,
    customerDetails,
    currentOrg: state.currentOrganisation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStripePaymentMethods: () => dispatch(PAYMENTACTIONS.getStripePaymentMethods()),
  updateStripeCustomer: (field, value) => dispatch(PAYMENTACTIONS.updateStripeCustomer(field, value)),
  getStripeIntent: () => dispatch(PAYMENTACTIONS.getStripeIntent()),
  toggleAlertMessage: (status, message) => dispatch(ACTIONS.toggleAlertMessage(status, message)),
  setStripeCustomerId: (stripeCustomerId) => dispatch(PAYMENTACTIONS.setStripeCustomerId(stripeCustomerId)),
  createStripeCustomer: (createIntent) => dispatch(PAYMENTACTIONS.createStripeCustomer(createIntent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCard);
