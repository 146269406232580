import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import Select from 'react-select';
import Explainer from './Explainer';
import LinearProgress from '@mui/material/LinearProgress';
import _ from 'lodash';
import { accountCodeSelectStyle } from '../utils/ReactSelectStyles';

const SageAccountCodes = ({
  accounts,
  selectedAccounts,
  setSageAccountCode,
  entities,
  getSageSpendEntities,
  getSageAccountCodes,
  tenant,
}) => {
  const handleAccountCodeChange = (name, selectedOption, e) => {
    setSageAccountCode(name, selectedOption, e?.removedValue);
  };

  useEffect(() => {
    getSageSpendEntities();
    getSageAccountCodes();
  }, [tenant]);

  return [
    accounts && entities ? (
      entities?.map((item) => {
        return (
          <li key={item.id} id={`spendEntity-${item.id}`} style={{ padding: '10px 15px' }}>
            Select your{' '}
            <Explainer description={item?.description} id={`spendEntity-${item.id}`}>
              {item.name}
            </Explainer>
            Nominal Code
            <span className='pull-right' style={{ marginRight: '47px', lineHeight: '0' }}>
              <Select
                className='spendEntitySelect'
                id={`spendEntity-${item.id}-select`}
                isMulti={true}
                options={accounts}
                value={selectedAccounts && selectedAccounts?.[item.id]}
                onChange={(value, e) => {
                  handleAccountCodeChange(item.id, value, e);
                }}
                styles={accountCodeSelectStyle}
                isClearable={true}
                isSearchable={true}
              />
            </span>
          </li>
        );
      })
    ) : (
      <LinearProgress color='inherit' />
    ),
  ];
};

const mapStateToProps = (state) => {
  return {
    accounts: _.sortBy(state?.accounts, ['label']),
    selectedAccounts: state?.selectedAccounts,
    entities: _.sortBy(state.spendEntities, ['name']),
    tenant: state?.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSageAccountCode: (account, option, removedValue) => dispatch(ACTIONS.setSageAccountCode(account, option, removedValue)),
  getSageSpendEntities: () => dispatch(ACTIONS.getSageSpendEntities()),
  getSageAccountCodes: () => dispatch(ACTIONS.getSageAccountCodes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SageAccountCodes);
