import { Box, FormControlLabel, LinearProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import Select from 'react-select';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { CalendarBtn } from '../../styledComponents/GlobalStyle';

const BulkEditModal = ({
  toggleModal,
  bulkEditModal,
  modalProps,
  modalData,
  branding,
  updateOrAddToDB,
  saveUsageData,
  progressState,
}) => {
  const [checked, setChecked] = useState({});
  const [selected, setSelected] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmBulkEdit, setConfirmBulkEdit] = useState(false);
  const [progressBarActive, setProgressBarActive] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [progress, setProgress] = useState(0);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (progressState !== undefined) {
      setProgress(progressState);
      setProgressBarActive(true);
    }
  }, [progressState]);

  if (!bulkEditModal) return null;
  const { btn, modalTitle, modalTableTitle, hideBtns, elements, tableName } = modalProps;
  const tabelLabel = tableName == 'usageData' ? 'Usage Data' : 'Conversion Factors';
  const { primaryColor } = branding.colors;

  const updateDateOrSelect = (selectedInput, e) => {
    if (e) {
      const newSelected = { ...selected };
      if (e.name) {
        newSelected[e.name] = selectedInput;
      } else {
        newSelected['source'] = selectedInput;
      }
      setSelected(newSelected);
    }
  };

  //to update checkbox
  const updateChecked = (e) => {
    const { name } = e.target;
    const newChecked = { ...checked };
    newChecked[name] = !newChecked[name];
    if (!newChecked[name]) {
      const newSelected = { ...selected };
      newSelected[name] = false;
      setSelected(newSelected);
    }
    setChecked(newChecked);
  };

  const trueChecked = Object?.keys(checked).filter((item) => checked[item] == true);
  const lengthOfSelected = Object?.keys(selected).filter(
    (item) => (typeof selected[item] === 'string' && selected[item].length >= 1) || typeof selected[item] === 'object'
  ).length;

  const bulkSelectionOK = trueChecked?.length >= 1 && lengthOfSelected >= 1 && trueChecked?.length == lengthOfSelected;

  const makeNewData = () => {
    const newItem = {};
    Object.keys(selected).map((optionType) => (newItem[optionType] = selected[optionType]['value']?.toString()));
    return modalData?.map((item) => {
      const newData = { ...item, ...newItem };
      return newData;
    });
  };

  const formatUsageTypesValues = (data) => {
    const { dataKey, cellData } = data;
    const options = _.find(elements, { name: dataKey })?.options;
    if (dataKey === 'source') {
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{cellData}</p>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {cellData}
            </div>
          </Tooltip>
        </div>
      );
    } else if (dataKey === 'discontinuedDate') {
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{cellData}</p>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {cellData}
            </div>
          </Tooltip>
        </div>
      );
    } else if (dataKey) {
      return (
        <div>
          <Tooltip
            sx={{ fontSize: 20 }}
            title={
              <p style={{ fontSize: 12, marginBottom: 0 }}>{cellData && _.find(options, { value: cellData })?.label}</p>
            }>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {cellData && _.find(options, { value: cellData })?.label}
            </div>
          </Tooltip>
        </div>
      );
    }
  };

  return (
    <>
      {confirmModal ? (
        <div className='modal' style={{ display: 'flex', alignItems: 'center', background: 'rgba(0,0,0,.5)' }}>
          <div className='modal-dialog modal-autoheight' style={{ fontSize: '12px', width: 'auto' }}>
            <div className='modal-content' style={{ margin: '70px auto', width: 'auto', maxHeight: '100%', height: 'auto' }}>
              <div className='modal-header'>
                <button type='button' onClick={() => toggleModal(false, null)} className='close'>
                  x
                </button>
                <h4 className='modal-title' style={{ textAlign: 'center' }}>
                  {modalTitle.replace('select', 'confirm')}
                </h4>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h3 style={{ textAlign: 'center', borderBottom: '2px solid black', color: 'black' }}>Selected Changes</h3>
                <div style={{ height: '15vh', overflowX: 'hidden', overflowY: 'auto', width: '100%', paddingTop: '2vh' }}>
                  {trueChecked?.map((item, index) => (
                    <div
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: 'fit-content',
                      }}>
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                        }}>
                        <h4>
                          <b>{item.charAt(0).toUpperCase() + item.slice(1)} -</b>
                        </h4>
                        <h4 style={{ color: primaryColor, paddingLeft: '5px' }}>
                          <b>{selected[item].label}</b>
                        </h4>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <h2 style={{ textAlign: 'center' }}>{modalTableTitle}</h2>
              <div
                className='modal-body'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '25vh',
                  width: '150vh',
                }}>
                <div style={{ width: '100%', height: '100%' }}>
                  <AutoSizer>
                    {({ width, height }) => (
                      <Table
                        width={width}
                        height={height}
                        rowHeight={40}
                        headerHeight={50}
                        rowCount={modalData.length}
                        rowGetter={({ index }) => modalData[index]}>
                        {elements.map((el, index) => (
                          <Column
                            key={index}
                            width={60}
                            label={el.label}
                            dataKey={el.name}
                            flexGrow={3}
                            cellRenderer={formatUsageTypesValues}
                          />
                        ))}
                      </Table>
                    )}
                  </AutoSizer>
                </div>
              </div>
              <div
                className='modal-footer'
                style={{ display: hideBtns ? 'none' : 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div>
                  {' '}
                  <FormControlLabel
                    control={<Checkbox checked={confirmBulkEdit} />}
                    onChange={(e) => setConfirmBulkEdit(e.target.checked)}
                    label={'By clicking you confirm the changes above are correct.'}
                  />
                </div>
                <div style={{ display: hideBtns ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {' '}
                  <button
                    className='btn btn-danger'
                    bgcolor='true'
                    bordercolor='true'
                    type={btn?.type}
                    onClick={() => {
                      setConfirmModal(false);
                    }}>
                    Back
                  </button>
                  <button
                    disabled={!confirmBulkEdit}
                    className='btn btn-success'
                    bgcolor='true'
                    bordercolor='true'
                    type={btn?.type}
                    onClick={() => {
                      if (tableName === 'usageData') {
                        setTimeout(() => {
                          setConfirmModal(false);
                        }, 500);
                        saveUsageData(makeNewData(), true);
                      } else updateOrAddToDB(tableName, makeNewData());
                    }}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : progressBarActive ? (
        <div className='modal' style={{ display: 'flex', alignItems: 'center', background: 'rgba(0,0,0,.5)' }}>
          <div className='modal-dialog modal-autoheight' style={{ fontSize: '12px', width: 'auto' }}>
            <div className='modal-content' style={{ margin: '70px auto', width: '65vh', maxHeight: '100%', height: 'auto' }}>
              <div className='modal-header'>
                <button type='button' onClick={() => toggleModal(false, null)} className='close'>
                  x
                </button>
                <h4 className='modal-title' style={{ textAlign: 'center' }}>
                  {tabelLabel} Bulk Edit Progress
                </h4>
              </div>
              <Box sx={{ width: '100%', padding: '50px' }}>
                <LinearProgressWithLabel sx={{ height: 7, borderRadius: 5 }} value={progress} primarycolor={primaryColor} />
                {progress === 100 && (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <p>{tabelLabel} has been bulk edited Successfully!</p>
                    </div>
                  </>
                )}
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          className='modal'
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(0,0,0,.5)' }}>
          <div className='modal-dialog' style={{ width: 'auto', height: 'auto', fontSize: '12px' }}>
            <div className='modal-content' style={{ margin: 'auto' }}>
              <div className='modal-header'>
                <button type='button' onClick={() => toggleModal(false, null)} className='close'>
                  x
                </button>
                <h4 className='modal-title' style={elements && { textAlign: 'center', paddingRight: '3vh' }}>
                  {modalTitle}
                </h4>
              </div>
              <div className='modal-body' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {elements.map((el, index) => {
                  const elName = el.name;
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingBottom: '2vh',
                      }}>
                      <div style={{ display: 'contents' }}>
                        <div style={{ width: '20vh' }}>
                          <label style={{ display: 'contents', fontSize: '14px' }}>{el.label}</label>
                        </div>
                        <div style={{ width: '10vh' }}>
                          <Checkbox
                            onChange={(e) => updateChecked(e)}
                            checked={checked[elName] || false}
                            name={elName}
                            style={{ marginRight: '3vh' }}
                          />
                        </div>
                        <div style={{ width: '63%', height: 'auto' }}>
                          {checked[elName] &&
                            (el.component === 'select' ? (
                              <div className='row' style={{ width: '213%' }}>
                                <div className='col-xs-6'>
                                  <Select
                                    name={elName}
                                    options={el.options}
                                    onChange={(selectedOption, e) => {
                                      updateDateOrSelect(selectedOption, e);
                                    }}
                                    value={selected[elName]}
                                    isClearable={false}
                                    isSearchable={true}
                                  />
                                </div>
                              </div>
                          ) : el.component === 'datePicker' ? (
                            checked[elName] &&
                            el.component === 'datePicker' && (
                              <div className='row' style={{ width: '213%' }}>
                                <div className='col-xs-6'>
                                  <label className='input-group date datepicker'>
                                    <DatePicker
                                      name={elName}
                                      showMonthDropdown
                                      showYearDropdown
                                      scrollableYearDropdown
                                      dateFormat='dd/MM/yyyy'
                                      className='form-control startDate'
                                      popperPlacement='bottom-end'
                                      yearDropdownItemNumber={5}
                                      isClearable={true}
                                      maxDate={new Date()}
                                      placeholderText='Discontinued Date'
                                      onChange={(date, e) => {
                                        let obj = {
                                          value: moment(date).format('YYYY-MM-DD'),
                                          label: moment(date).format('YYYY-MM-DD'),
                                          name: elName,
                                        };
                                        updateDateOrSelect(obj, { label: moment(date).format('YYYY-MM-DD'), name: elName });
                                        setDate(date);
                                      }}
                                      selected={date}
                                      popperModifiers={{ offset: { enabled: true, offset: '77px, 0px' } }}
                                    />
                                    <span className='input-group-btn'>
                                      <CalendarBtn className='btn btn-primary date-set'>
                                        <i className='fa fa-calendar' />
                                      </CalendarBtn>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            )
                          ) : (
                              <div className='row' style={{ width: '213%' }}>
                                <div className='col-xs-6'>
                                  <input
                                    type={el?.type}
                                    className='form-control'
                                    name={elName}
                                    onChange={(e) => {
                                      let obj = { value: e.target.value, label: e.target.value };
                                      setInputValue(e.target.value);
                                      updateDateOrSelect(obj, e);
                                    }}
                                    value={inputValue}
                                    placeholder='Source'
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {bulkSelectionOK && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      className='btn btn-success'
                      bgcolor='true'
                      bordercolor='true'
                      style={btn?.style}
                      type={btn?.type}
                      onClick={() => {
                        setConfirmModal(true);
                      }}>
                      {btn?.title}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', height: '10vh' }}>
        <Box sx={{ width: '90%', mr: 4, marginLeft: '5vh', color: props.primarycolor }}>
          <LinearProgress color='inherit' variant='determinate' {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant='body2' fontSize='2vh' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    /*
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
    primarycolor: PropTypes.string.isRequired,
  };
};

const mapStateToProps = (state) => {
  return {
    modalData: state.admin?.modal?.item,
    modalProps: state.admin?.modal?.modalProps,
    branding: state.branding,
    bulkEditModal: state?.admin?.modal?.bulkEditModal,
    progressState: state?.progress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action) => dispatch(ADMINACTIONS.toggleModal(status, action)),
  updateOrAddToDB: (table, item) => dispatch(ADMINACTIONS.updateOrAddToDB(table, item)),
  saveUsageData: (usageData, adminPortal) => dispatch(USAGEACTIONS.saveUsageData(usageData, adminPortal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkEditModal);
