import React from 'react';
import { Actions } from '../../store/actions/organisationActions';
import { connect } from 'react-redux';

const UsersListRow = ({ userDetails, toggleModal }) => {
  return (
    <tr id='users-list-row' style={{ display: userDetails.enabled ? 'table-row' : 'none' }}>
      <td width='20%' style={{ textAlign: 'left' }}>
        {userDetails.name}
      </td>
      <td width='20%'>{userDetails.surname}</td>
      <td width='20%'>{userDetails.email}</td>
      <td>
        <i
          style={{ cursor: 'pointer' }}
          onClick={() => toggleModal(true, userDetails, 'disableUser')}
          className='fa fa-trash-o fa-lg'
        />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, item, action) => dispatch(Actions.toggleModal(status, item, action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersListRow);
