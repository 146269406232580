import React from 'react';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { AuthBtn } from '../../styledComponents/GlobalStyle';

const IntegrationModal = ({ branding, setShowIntegrationModal, showIntegrationModal, wlSettings, profileDetails }) => {
  const { primaryHeaderBackground } = branding.colors || {};

  const history = useHistory();

  const { isXeroUser } = profileDetails || {};

  return (
    <>
      {showIntegrationModal && (
        <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,0.9)' }}>
          <div className='login-body' style={{ background: 'transparent' }}>
            <div className='form-signin' style={{ display: 'block', minHeight: 'auto', margin: '70px auto' }}>
              <h2
                className='form-signin-heading'
                style={{
                  background: primaryHeaderBackground,
                  fontSize: branding.fonts?.[0].size.find((size) => size === '2em'),
                  fontWeight: branding.fonts?.[0].weight.find((weight) => weight === 700),
                }}>
                Welcome!
              </h2>

              <div className='login-wrap'>
                {isXeroUser && (
                  <div style={{ display: 'block' }} className='col-xs-12 alert-info alert'>
                    <h5 style={{ textAlign: 'center' }}>
                      Your XERO account has been successfully connected to {wlSettings?.name}.
                    </h5>
                  </div>
                )}

                <div style={{ display: 'block' }} className='col-xs-12 alert-info alert'>
                  <h5 style={{ textAlign: 'center' }}> To get started, please click here to create a new organisation.</h5>
                </div>

                <AuthBtn
                  className='btn btn-lg btn-login btn-block onceonly'
                  id='register-pay-btn'
                  style={{ color: '#FFFFFF', margin: '10px 0 5px' }}
                  onClick={() => {
                    history.push('/organisations/new');
                    setShowIntegrationModal(false);
                  }}>
                  Create Organisation
                </AuthBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profileDetails: state.profile?.details,
    branding: state.branding,
    wlSettings: state.whiteLabelAdmin,
  };
};

export default connect(mapStateToProps)(IntegrationModal);
