import axios from 'axios';
import { delay } from '../../components/utils/GlobalFunctions';
import { showLoader, toggleAlertMessage } from '../action';
import { getAdminOrganisations, getAllItemsFromDB } from '../actions/adminActions';
import createSignedRequest from './createSignedRequest';
const { apiURL } = require('./apiURL');

const Types = {
  SET_CURRENT: 'ORGANISATION_SET_CURRENT',
  LIST: 'ORGANISATION_LIST',
  INDUSTRIES: 'ORGANISATION_INDUSTRIES',
  COUNTRIES: 'ORGANISATION_COUNTIRES',
  DETAILS: 'ORGANISATION_DETAILS',
  METRICS: 'ORGANISATION_METRICS',
  MODAL: 'ORGANISATION_MODAL',
  SAVE: 'ORGANISATION_SAVE',
  SAVE_METRIC: 'ORGANISATION_SAVE_METRIC',
  ADD_METRIC: 'ORGANISATION_ADD_METRIC',
  DELETE_METRIC: 'ORGANISATION_DELETE_METRIC',
  ORGANISATION_USERS: 'ORGANISATION_USERS',
  ORG_AGREEMENTS: 'ORGANISATION_AGREEMENTS',
  LIST_WHITELABEL_ADMIN_ORGS: 'ORGANISATION_LIST_WHITELABEL_ADMIN_ORGS',
  LIST_WHITELABEL_ADMIN_SETTINGS: 'ORGANISATION_LIST_WHITELABEL_ADMIN_SETTINGS',
  ORGANISATION_REPORT_YR_END_ISSUE: 'ORGANISATION_REPORT_YR_END_ISSUE',
  SAVE_TRANSFERRED_ORG: 'ORGANISATION_SAVE_TRANSFERRED_ORG',
};

const toggleModal = (status, item, action) => {
  return {
    type: Types.MODAL,
    status,
    item,
    action,
  };
};

const setCurrentOrganisation = (organisation) => ({
  type: Types.SET_CURRENT,
  organisation,
});

const updateOrganisation = (response) => ({
  type: Types.DETAILS,
  payload: response,
});

export const getOrganisation = () => {
  return async (dispatch, getState) => {
    const state = getState();

    if (state.currentOrganisation && state.currentOrganisation != 'new') {
      try {
        const response = await axios(
          await createSignedRequest('GET', apiURL + `/my/organisation`, null, {
            Organisation: String(state.currentOrganisation),
          })
        );

        console.log('getOrganisation', response);
        dispatch(updateOrganisation(response.data));
      } catch (e) {
        console.log('getOrganisation Error', e);
      }
    } else {
      dispatch(
        updateOrganisation({
          id: null,
          name: '',
          industry: '',
          currency: '',
          country: '',
          reportingYearEndDay: 31,
          reportingYearEndMonth: 12,
        })
      );
    }
  };
};

const updateOrgAgreements = (response) => ({
  type: Types.ORG_AGREEMENTS,
  payload: response,
});

const getOrgAgreements = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/organisation/agreements`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getOrgAgreements', response);
      dispatch(updateOrgAgreements(response.data));
    } catch (e) {
      console.log('getOrgAgreements Error:', e);
    }
  };
};

const updateOrganisationMetrics = (response) => ({
  type: Types.METRICS,
  payload: response,
});

const getOrganisationMetrics = () => {
  return async (dispatch, getState) => {
    const state = getState();

    if (!state.currentOrganisation) return;

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/organisation/metrics`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getOrganisationMetrics', response);
      dispatch(updateOrganisationMetrics(response.data));
    } catch (e) {
      console.log('getOrganisationMetrics Error:', e);
    }
  };
};

const updateOrganisations = (response) => ({
  type: Types.LIST,
  payload: response,
});

const listOrganisations = () => {
  return async (dispatch, getState) => {
    dispatch(showLoader(true));
    try {
      const response = await axios(await createSignedRequest('GET', apiURL + `/my/organisations`));

      console.log('listOrganisations', response);
      dispatch(updateOrganisations(response.data));
    } catch (e) {
      console.log('listOrganisations Error:', e);
    }
    dispatch(showLoader(false));
  };
};

const updateIndustries = (response) => ({
  type: Types.INDUSTRIES,
  payload: response,
});

const listIndustries = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios(await createSignedRequest('GET', apiURL + `/impact/industries`, null));

      dispatch(updateIndustries(response.data));
    } catch (e) {
      console.log('listIndustries Error:', e);
    }
  };
};

const updateCountries = (response) => ({
  type: Types.COUNTRIES,
  payload: response,
});

const listCountries = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest(
          'GET',
          apiURL + `/impact/countries`,
          null
          //{'Organisation': String(state.currentOrganisation)}
        )
      );

      // console.log('listCountries', response)
      dispatch(updateCountries(response.data));
    } catch (e) {
      console.log('listCountries Error:', e);
    }
  };
};

const savedOrganisation = (response) => ({
  type: Types.SAVE,
  payload: response,
});

const saveOrganisation = (organisation, history) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { accountType } = organisation;
    const whiteLabelAdminId = state?.whiteLabelAdmin?.whiteLabelId;
    const { cyfAdmin } = state.profile?.details || {};
    const { consultantAdmin } = state.profile?.details || {};
    const { list } = state.organisation;
    const { consultantId } = list?.find((org) => org?.consultantId !== 'NONE') || {};

    let savedOrg;
    if (cyfAdmin) {
      savedOrg = { ...organisation };
    } else if (accountType === 'Consultant') {
      // This links the org to its Consultant Admin
      savedOrg = { ...organisation, consultantId: String(consultantAdmin || consultantId), whiteLabelId: '0' };
    } else if (accountType === 'White Label') {
      // This links the org to its White Label Admin
      savedOrg = { ...organisation, whiteLabelId: String(whiteLabelAdminId) };
    } else if (accountType === 'Client Test Organisation') {
      // Client Test Orgs are added by White Label or Consultant Admins
      // If its done by a White Label Admin, the Consultant Admin is set to NONE
      // if its done by a Consultant Admin, the White Label Id is set to 0 and the Consultant Admin is set to the Consultant Admin
      const consultantId = whiteLabelAdminId !== '0' ? 'NONE' : consultantAdmin;
      savedOrg = { ...organisation, whiteLabelId: String(whiteLabelAdminId), consultantId: String(consultantId) };
    } else {
      // Direct Users
      savedOrg = { ...organisation, whiteLabelId: '0' };
    }

    const currentOrganisation = state.currentOrganisation ? String(state.currentOrganisation) : '';

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/organisation`, JSON.stringify(savedOrg), {
          Organisation: currentOrganisation,
        })
      );

      console.log('savedOrganisation', response);
      dispatch(savedOrganisation(response));
      if (response?.data?.id && history) {
        dispatch(checkReportingYearEndIssue(response.data.id));
        await delay(1000);
        history?.push(`/organisations/${response.data.id}`);
      }
    } catch (e) {
      console.log('savedOrganisation Error:', e);
    }
  };
};

const savedOrganisationMetric = (response, metric) => ({
  type: Types.SAVE_METRIC,
  payload: response,
  metric,
});

const saveOrganisationMetric = (metric) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/organisation/metrics`, JSON.stringify(metric), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('savedOrganisationMetric', response, metric);
      dispatch(savedOrganisationMetric(response, metric));
      dispatch(checkReportingYearEndIssue(state.currentOrganisation));
    } catch (e) {
      console.log('savedOrganisationMetric Error:', e);
    }
  };
};

const addOrganisationMetric = () => ({
  type: Types.ADD_METRIC,
});

const handleDeleteOrganisationMetric = (response, id) => ({
  type: Types.DELETE_METRIC,
  payload: response,
  id,
});

const deleteOrganisationMetric = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('DELETE', apiURL + `/my/organisation/metric/${id}`, '{}', {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('deleteOrganisationMetric', response);
      dispatch(handleDeleteOrganisationMetric(response, id));
      dispatch(checkReportingYearEndIssue(state.currentOrganisation));
    } catch (e) {
      console.log('deleteOrganisationMetric Error:', e);
    }
  };
};

const listOrganisationUsers = (response) => ({
  type: Types.ORGANISATION_USERS,
  response,
});

export const getOrganisationUsers = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/organisation/userList`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getOrganisationUsers', response);
      dispatch(listOrganisationUsers(response.data));
    } catch (e) {
      console.log('getOrganisationUsers Error:', e);
    }
  };
};

export const checkReportingYearEndIssue = (currentOrganisation) => {
  // Checks for RYE issue and if Org has any compulsory data missing
  return async (dispatch) => {
    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/checkReportingYearEndIssue`, null, {
          Organisation: String(currentOrganisation),
        })
      );

      console.log('checkReportingYearEndIssue', response);

      dispatch(updateReportingYearEndIssue(response?.data));
      return response.data;
    } catch (e) {
      console.log('checkReportingYearEndIssue Error:', e);
    }
  };
};

const disableUser = (username) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/organisation/disableUser`, JSON.stringify({ username }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      const { message, success } = response.data || {};
      if (success) {
        dispatch(toggleAlertMessage(true, message, 'success'));
        dispatch(getOrganisationUsers());
      }
      dispatch(toggleModal(false, null));
    } catch (e) {
      console.log('disableUser Error:', e);
    }
  };
};

export const listWhiteLabelAdminOrg = (response) => ({
  type: Types.LIST_WHITELABEL_ADMIN_ORGS,
  response,
});

const getWLAdminOrgs = () => {
  return async (dispatch, getState) => {
    const state = getState();

    const isUserSigningUp = window.location.pathname.includes('register');
    const currentOrg = state.currentOrganisation;

    if (isUserSigningUp) {
      fetch(apiURL + '/registration/adminOrganisations', { method: 'GET', headers: { Accept: 'application/json' } })
        .then((response) => response.json())
        .then((data) => dispatch(listWhiteLabelAdminOrg(data)))
        .catch((error) => console.log('getWLAdminOrgs error', error));
    } else if (currentOrg) {
      try {
        const response = await axios(
          await createSignedRequest('GET', apiURL + `/my/whitelabel/adminOrganisations`, '', {
            Organisation: String(state.currentOrganisation),
          })
        );

        console.log('whiteLabelAdminOrganisations', response);
        dispatch(listWhiteLabelAdminOrg(response.data));
      } catch (e) {
        console.log('whiteLabelAdminOrganisations Error:', e);
      }
    }
  };
};

const listWLSettings = (response) => ({
  type: Types.LIST_WHITELABEL_ADMIN_SETTINGS,
  response,
});

export const updateReportingYearEndIssue = (response) => ({
  type: Types.ORGANISATION_REPORT_YR_END_ISSUE,
  response,
});

const getWLAdminSettings = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/admin/whiteLabelSettings`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );
      console.log('getWLAdminSetting', response);
      dispatch(listWLSettings(response.data));
    } catch (e) {
      console.log('Error Getting WL Settings', e);
    }
  };
};

const saveTransferredOrg = (org) => ({
  type: Types.SAVE_TRANSFERRED_ORG,
  org,
});

const reportGeneration = (orgDetails) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/organisation/reportGeneration`, JSON.stringify({ orgDetails }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('reportGeneration', response);
      const { success, message } = response.data;
      if (success) {
        // Reuse savedOrganisation action to update the orgDetails on the state
        dispatch(savedOrganisation({ data: { id: orgDetails.id, organisation: orgDetails, item: orgDetails } }));
        dispatch(getAllItemsFromDB('organisation'));
        dispatch(toggleAlertMessage(true, message, 'success'));
      } else {
        dispatch(toggleAlertMessage(true, message, 'error'));
      }
    } catch (e) {
      console.log('reportGeneration Error:', e);
    }
  };
};

const organisationCreation = (orgDetails) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/organisation/organisationCreation`, JSON.stringify({ orgDetails }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('organisationCreation', response);
      const { success, message } = response.data;
      if (success) {
        // Reuse savedOrganisation action to update the orgDetails on the state
        dispatch(savedOrganisation({ data: { id: orgDetails.id, organisation: orgDetails, item: orgDetails } }));
        dispatch(getAllItemsFromDB('organisation'));
        dispatch(toggleAlertMessage(true, message, 'success'));
      } else {
        dispatch(toggleAlertMessage(true, message, 'error'));
      }
    } catch (e) {
      console.log('organisationCreation Error:', e);
    }
  };
};

const saveAddOnsToAllClientOrgs = (adminOrg, addOnId) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/organisations/addOns`, JSON.stringify({ adminOrg, addOnId }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('saveAddOnsToAllClientOrgs', response);

      const { success, message, error } = response.data || {};

      if (error?.includes('Not Authorised')) {
        dispatch(toggleAlertMessage(true, 'Not Authorised', 'error'));
      } else if (success) {
        dispatch(toggleAlertMessage(true, message, 'success'));
      } else {
        dispatch(toggleAlertMessage(true, message, 'warning'));
        // TODO: Show Organisations that were not updated in a modal??
      }

      // Refresh organisation list
      dispatch(getAdminOrganisations());
    } catch (e) {
      console.log('saveAddOnsToAllClientOrgs Error:', e);
    }
  };
};

const apiAccess = (orgDetails) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/organisation/apiAccess`, JSON.stringify({ orgDetails }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('apiAccess', response);
      const { success, message } = response.data;
      if (success) {
        // Reuse savedOrganisation action to update the orgDetails on the state
        dispatch(savedOrganisation({ data: { id: orgDetails.id, organisation: orgDetails, item: orgDetails } }));
        dispatch(getAllItemsFromDB('organisation'));
        dispatch(toggleAlertMessage(true, message, 'success'));
      } else {
        dispatch(toggleAlertMessage(true, message, 'error'));
      }
    } catch (e) {
      console.log('apiAccess Error:', e);
    }
  };
};

const Actions = {
  checkReportingYearEndIssue,
  addOrganisationMetric,
  deleteOrganisationMetric,
  disableUser,
  getOrganisation,
  getOrganisationMetrics,
  getOrganisationUsers,
  listCountries,
  listIndustries,
  listOrganisations,
  listWhiteLabelAdminOrg,
  saveOrganisation,
  saveOrganisationMetric,
  setCurrentOrganisation,
  updateOrganisation,
  toggleModal,
  getWLAdminOrgs,
  getWLAdminSettings,
  getOrgAgreements,
  saveTransferredOrg,
  reportGeneration,
  organisationCreation,
  saveAddOnsToAllClientOrgs,
  apiAccess,
};

export { Actions, Types };
