import { Alert, AlertTitle, LinearProgress, Link, Tooltip } from '@mui/material';
import { format, fromUnixTime } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Actions as PAYMENT_ACTIONS } from '../../store/actions/paymentActions';

const BillingPage = ({ branding, getStripeInvoices, gettingInvoices, invoices, cyfAdmin, currentOrg, ambassadorAdmin }) => {
  const [data, setData] = useState([]);
  const { headers } = branding?.fonts[0]?.font || {};

  useEffect(() => {
    if (!currentOrg) return;
    getStripeInvoices();
  }, [currentOrg]);

  useEffect(() => setData(invoices), [invoices]);

  const cellValue = ({ cellData, rowData, dataKey }) => {
    const { status, amount_paid } = rowData;
    if (dataKey == 'status') {
      return cellData.charAt(0).toUpperCase() + cellData.slice(1);
    } else if (dataKey == 'created') {
      return format(fromUnixTime(cellData), 'dd/MM/yyyy');
    } else if (dataKey == 'period') {
      return (
        format(fromUnixTime(rowData.period_start), 'dd/MM/yyyy') +
        ' - ' +
        format(fromUnixTime(rowData.period_end), 'dd/MM/yyyy')
      );
    } else if (dataKey == 'total' || dataKey == 'amount') {
      const currency = rowData.currency.toUpperCase();
      const price = amount_paid !== undefined || amount_paid !== null ? amount_paid / 100 : null; // Prices in Stripe are in cents, divide by 100 to get the actual price
      return amount_paid !== undefined || amount_paid !== null ? `${price} ${currency}` : '';
    } else if (dataKey == 'description') {
      const { lines: { data } = {} } = rowData;
      return data && data.length > 0 ? data[0].description : '';
    }
    if (dataKey == 'receipt' && status == 'paid') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>Payment Receipt</p>}>
            <Link sx={{ cursor: 'pointer' }} href={rowData.hosted_invoice_url} target='_blank' rel='noreferrer'>
              <i className='fa fa-link' style={{ marginLeft: 5 }} />
            </Link>
          </Tooltip>
        </div>
      );
    }
    if (dataKey == 'invoice') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>Itemised Invoice</p>}>
            <Link sx={{ cursor: 'pointer' }} href={rowData.invoice_pdf} target='_blank' rel='noreferrer'>
              <i className='fa fa-link' />
            </Link>
          </Tooltip>
        </div>
      );
    }
    return cellData;
  };

  return (
    <section className='panel' style={{ fontFamily: headers }}>
      <header
        className='panel-heading'
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        Billing Page
      </header>
      <div className='panel-body' style={{ height: '100vh' }}>
        {gettingInvoices && <LinearProgress color='inherit' />}
        {!data?.length > 0 && !gettingInvoices && (
          <Alert id='report-not-found' severity='warning' sx={{ fontSize: 15, width: '50%' }}>
            <AlertTitle>Not Found</AlertTitle>
            There are no invoices on record for your company.
          </Alert>
        )}
        <div style={{ width: '100%', height: '100%', display: !data?.length > 0 ? 'none' : 'block' }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                rowHeight={50}
                headerHeight={50}
                headerStyle={{ verticalAlign: 'bottom', width: '100%', display: 'flex', justifyContent: 'center' }}
                rowStyle={{ textAlign: 'center' }}
                headerClassName='admin_portal_column_headers'
                rowCount={data?.length || 0}
                rowGetter={({ index }) => data[index]}>
                {cyfAdmin && <Column label='ID' dataKey='id' width={270} cellRenderer={cellValue} />}
                {!ambassadorAdmin && <Column label='Invoice Number' dataKey='number' width={270} cellRenderer={cellValue} />}
                <Column label='Created at' dataKey='created' width={120} flexGrow={0.2} cellRenderer={cellValue} />
                {!ambassadorAdmin && (
                  <Column label='Period' dataKey='period' width={300} flexGrow={0.2} cellRenderer={cellValue} />
                )}
                <Column
                  label='Description'
                  dataKey='description'
                  width={!ambassadorAdmin ? 300 : 300}
                  flexGrow={0.1}
                  cellRenderer={cellValue}
                />
                {!ambassadorAdmin && (
                  <Column label='Total' dataKey='total' width={120} flexGrow={0.2} cellRenderer={cellValue} />
                )}
                {!ambassadorAdmin && <Column label='Status' dataKey='status' width={140} cellRenderer={cellValue} />}
                {!ambassadorAdmin && <Column label='Invoice' dataKey='invoice' width={140} cellRenderer={cellValue} />}
                {!ambassadorAdmin && <Column label='Receipt' dataKey='receipt' width={140} cellRenderer={cellValue} />}
                {ambassadorAdmin && <Column label='Amount' dataKey='amount' width={140} cellRenderer={cellValue} />}
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  const { gettingInvoices, invoices } = state.payment?.stripe || {};
  const { cyfAdmin, ambassadorAdmin } = state.profile?.details || {};
  return {
    ambassadorAdmin,
    currentOrg: state.currentOrganisation,
    branding: state.branding,
    cyfAdmin,
    gettingInvoices,
    invoices,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStripeInvoices: () => dispatch(PAYMENT_ACTIONS.getStripeInvoices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage);
