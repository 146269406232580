import _ from 'lodash';
import { Types } from '../actions/authenticationActions';

const defaultState = {};

const authenticationReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.AUTHENTICATION_LOGIN: {
      let newState = _.cloneDeep(state);

      console.log('sesttingCookie', action.payload);

      if (action.payload.result && action.payload.result.session) {
        newState.authentication.invalidLoginCredentials = false;
        var myDate = new Date();
        myDate.setMonth(myDate.getMonth() + 1);
        document.cookie = 'PHPSESSID=' + action.payload.result.session + ';expires=' + myDate + ';domain=.buddy.hr;path=/';

        console.log('sesttingCookie');
      } else if (action.attempt) {
        // invalid error
        newState.authentication.invalidLoginCredentials = true;
      }

      if (action.payload.profile) {
        newState.profile.details = action.payload.profile;
      }

      if (action.payload.error) {
        newState.authentication.error = action.payload.error;
      }

      newState.authentication.processing = false;

      return newState;
    }
    case Types.AUTHENTICATION_LOGOUT: {
      let newState = _.cloneDeep(state);

      newState.authentication.processing = false;
      newState.profile.details = false;
      newState.currentOrganisation = false;
      newState.organisation = {
        list: [],
        details: {},
      };
      newState.subentities = [];
      newState.constumption = {};
      newState.admin = {};

      return newState;
    }
    case Types.AUTHENTICATION_PROCESSING: {
      let newState = _.cloneDeep(state);

      newState.authentication.processing = action.saving;

      return newState;
    }
    case Types.AUTHENTICATION_VALIDATE_CODE: {
      let newState = _.cloneDeep(state);

      newState.authentication.validatingCode = true;

      return newState;
    }
    case Types.AUTHENTICATION_RECOVERY_CODE_CHECK: {
      let newState = _.cloneDeep(state);

      newState.authentication.validatingCode = false;

      if (action.payload.error) {
        newState.authentication.recoveryCodeError = action.payload.error;
      } else {
        newState.authentication.recoveryCodeError = false;
      }

      if (action.payload.profile) {
        newState.profile.details = action.payload.profile;
      }

      return newState;
    }
    case Types.AUTHENTICATION_SETTING_PASSWORD: {
      let newState = _.cloneDeep(state);

      newState.authentication.settingPassword = true;

      return newState;
    }
    case Types.AUTHENTICATION_SET_PASSWORD_RESULT: {
      let newState = _.cloneDeep(state);

      newState.authentication.settingPassword = false;

      if (action.payload.error) {
        newState.authentication.recoveryCodeError = action.payload.error;
      } else {
        newState.authentication.recoveryCodeError = false;
      }

      if (action.payload.profile) {
        newState.profile.details = action.payload.profile;
      }

      return newState;
    }
    case Types.AUTHENTICATION_LOGIN_COGNITO: {
      let newState = _.cloneDeep(state);

      const { payload } = action || {};

      //list of sso providers
      const ssoList = ['Xero', 'Google', 'SilverFish'];

      if (action.payload.attributes) {
        newState.profile.details = {
          id: payload?.attributes['sub'],
          userSub: payload?.attributes['sub'],
          firstName: payload?.attributes['given_name'],
          lastName: payload?.attributes['family_name'],
          email: payload?.attributes.email,
          company: payload?.attributes['custom:companyName'],
          isWhitelabeluser: payload?.attributes['custom:isWhitelabel'] && true,
          isConsultant: payload?.attributes['custom:isConsultant'] && true,
        };
      } else {
        newState.profile.details = {
          id: payload?.username,
          firstName: payload?.challengeParam.userAttributes['given_name'],
          lastName: payload?.challengeParam.userAttributes['family_name'],
          email: payload?.username,
          company: payload?.challengeParam.userAttributes['custom:companyName'],
          isWhitelabeluser: payload?.challengeParam.userAttributes['custom:isWhitelabel'] && true,
          isConsultant: payload?.challengeParam.userAttributes['custom:isConsultant'] && true,
        };
      }

      //check if user is from any of the sso providers and returns name of SSO provider
      const ssoFound = ssoList.find((sso) => payload?.username?.includes(sso));

      //if user is from SSO provider we set dynamic isXeroUser,isGoogleUser,etc to true
      if (ssoFound) {
        newState.profile.details['ssoUser'] = true;
        newState.profile.details[`is${ssoFound}User`] = true;
      }

      newState.authentication.processing = false;

      return newState;
    }
    case Types.AUTHENTICATION_USER_CREDENTIALS: {
      let newState = _.cloneDeep(state);

      if (action.payload && newState.profile.details) {
        newState.profile.details.credentials = action.payload;
      } else {
        newState.profile.credentials = action.payload;
      }

      return newState;
    }
    case Types.AUTHENTICATION_REGISTRATION_DETAILS: {
      let newState = _.cloneDeep(state);

      newState.profile.registrationDetails = { ...newState.profile.registrationDetails } || {};

      newState.profile.registrationDetails.user = { ...newState.profile.registrationDetails.user, ...action.payload.user };

      newState.profile.registrationDetails.organisation = {
        ...newState.profile.registrationDetails.organisation,
        ...action.payload.organisation,
      };

      return newState;
    }
    case Types.AUTHENTICATION_NEW_USER_DETAILS: {
      let newState = _.cloneDeep(state);

      const userId = action.payload.userId;
      const organisationId = action.payload.organisationId;
      const organistionDetails = action.payload.savedOrg;

      if (action.payload) {
        newState.profile.registrationDetails.user = { ...newState.profile.registrationDetails.user, userId };
        newState.profile.registrationDetails.organisation = {
          ...newState.profile.registrationDetails.organisation,
          ...organistionDetails,
        };
        newState.currentOrganisation = organisationId;
      }

      return newState;
    }
    case Types.AUTHENTICATION_ACTIVE_CAMPAIGN_DETAILS: {
      let newState = _.cloneDeep(state);

      newState.profile.registrationDetails.activeCampaign = {
        contactId: action.payload.contactId,
        contactTagId: action.payload.tag.id,
      };

      return newState;
    }
    case Types.UPDATE_LEGAL_AGREEMENT: {
      let newState = _.cloneDeep(state);

      const isOrg = action.payload;

      if (isOrg) {
        newState.profile.details.legalAgreements = {
          ...newState.profile.details.legalAgreements,
          softwareLicenseAgreementConfirmed: true,
          orgPrivacyPolicyAgreementConfirmed: true,
        };
      } else {
        newState.profile.details.legalAgreements = {
          ...newState.profile.details.legalAgreements,
          userLicenseAgreementConfirmed: true,
          userPrivacyPolicyAgreementConfirmed: true,
        };
      }

      return newState;
    }
    case Types.LIST_WHITE_LABEL_SETTINGS: {
      let newState = _.cloneDeep(state);
      const wlSettings = action.response || {};

      newState.whiteLabelAdmin = { ...newState.whiteLabelAdmin } || {};
      newState.branding = { ...newState.branding } || {};

      newState.whiteLabelAdmin = { ...wlSettings };

      newState.branding = {
        colors: wlSettings.brandingColors,
        fonts: wlSettings.font,
        name: wlSettings.name,
        favicon: wlSettings.favicon,
        logo: wlSettings.logo,
        appTopLeftLogo: wlSettings.appTopLeftLogo,
        clientWebsite: wlSettings.clientWebsite,
        inviteUsers: wlSettings.inviteUsers,
      };

      return newState;
    }
    default:
      return state;
  }
};

export default authenticationReducers;
