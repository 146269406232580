import { createStore, applyMiddleware, compose } from 'redux';

// Logger with default options
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import reducer from './reducer';

export default function configureStore(initialState) {
  // to check the trace of the actions in redux devtools
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
    compose;

  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, logger))
    // applyMiddleware(thunk,logger)
  );
  return store;
}
