import React from 'react';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/css/modal-video.css';

const VideoModal = ({ link, isOpen, onClose }) => {
  let vidId = '';
  if (link?.includes('youtube.com/')) {
    if (link.includes('&')) {
      const eq = link.indexOf('=');
      const and = link.indexOf('&');
      vidId = link.slice(eq + 1, and);
    } else {
      vidId = link.split('v=')[1];
    }
  } else {
    vidId = link.split('be/')[1];
  }

  return (
    <React.Fragment>
      <ModalVideo
        channel={link.includes('yout') ? 'youtube' : 'custom'} //custom is used to display MP4 videos
        youtube={{
          mute: 0,
          autoplay: 1,
          color: 'white',
          controls: 1,
          modestbranding: 1,
          showinfo: 0,
          iv_load_policy: 3,
        }}
        isOpen={isOpen}
        videoId={vidId}
        url={link} //Only used if video link provided is an MP4
        onClose={onClose}
      />
    </React.Fragment>
  );
};

export default VideoModal;
