const Types = {
  SIDEBAR_TOGGLE: 'SIDEBAR_TOGGLE',
  SHOW_BILLING_DETAILS: 'SIDEBAR_SHOW_BILLING_DETAILS',
};

const toggleSidebar = () => ({
  type: Types.SIDEBAR_TOGGLE,
});

const showBillingDetails = (status) => ({
  type: Types.SHOW_BILLING_DETAILS,
  status,
});

const Actions = {
  toggleSidebar,
  showBillingDetails,
};

export { Types, Actions };
