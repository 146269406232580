import { TextField } from '@mui/material';
import axios from 'axios';
import { endOfYear, isAfter, isBefore, isDate, parse, parseISO, startOfYear } from 'date-fns';
import React from 'react';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';
import createSignedRequest from '../../store/actions/createSignedRequest';

const { apiURL } = require('../../store/actions/apiURL');

export const itemToOptions = (item) => ({
  value: item?.id,
  label: item?.name?.trim() || item?.priceType?.trim(),
  description: item?.description?.trim(),
});

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const isISODate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d) && d.toISOString() === str; // valid date
};

export const getIndexCount = async (indexName) => {
  try {
    const response = await axios(await createSignedRequest('GET', apiURL + `/admin/portal/count/${indexName}`, null, null));
    return response?.data?.itemCount;
  } catch (e) {
    console.log('getIndexCount Error:', e);
  }
};

export const parseDateToTz = (date, format) => {
  if (typeof date == 'object') return date;
  const getDateFromISOFormat = date.substring(0, 10) || date;
  return parse(getDateFromISOFormat, format, new Date());
};

export const checkReportingYearEndAndIndustry = (orgDetails) => {
  const reportingYearEndMissing = !orgDetails?.reportingYearEndMonth || !orgDetails?.reportingYearEndDay;
  const industryMissing = !orgDetails?.industry;

  return reportingYearEndMissing && industryMissing
    ? 'Please set your reporting year end and industry'
    : reportingYearEndMissing
    ? 'Please set your reporting year end'
    : industryMissing
    ? 'Please set your industry'
    : null;
};

export const checkReportingYearEnd = (orgDetails) => !orgDetails?.reportingYearEndMonth || !orgDetails?.reportingYearEndDay;

export const removeCamelCase = (str) => {
  // Regex to remove camel case from a string
  return str?.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
};

export const getDataFromLocalStorage = (key) => {
  return new Promise((resolve) => {
    const data = localStorage.getItem(key);
    if (data) resolve(typeof data === 'object' ? JSON.parse(data) : data);
    else resolve(null);
  });
};

export const NumericTextField = (props) => {
  const { value, onChange } = props;

  // Validate the input to allow only numeric values
  const handleInputChange = (event) => {
    const input = event.target.value;
    // Regular expression to match integers and decimals
    const numericRegex = /^-?\d*\.?\d*$/;

    if (numericRegex.test(input) || input === '') {
      onChange(event);
    }
  };

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleInputChange}
      inputProps={{
        pattern: '[-]?[0-9]*[.,]?[0-9]*',
        inputMode: 'decimal',
      }}
    />
  );
};

export function convertToTitleCase(str) {
  // Converts from Camel Case to Title Case

  const specialStrings = ['originlocation', 'finallocation'];
  if (typeof str !== 'string') return str;
  else if (specialStrings.some((x) => x === str)) {
    if (str === 'originlocation') return 'Origin Location';
    else if (str === 'finallocation') return 'Final Location';
  } else {
    return str
      .split(/(?=[A-Z])/) // Split by capital letters
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' '); // Join words with spaces
  }
}

export const removeLastCharacter = (str) => {
  if (!str) return '';
  else return str.substring(0, str.length - 1);
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function uuidValidateV4(uuid) {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4;
}

export function isNumericId(str) {
  return typeof str === 'string' && /^\d+$/.test(str);
}

export function convertStringToNull(str) {
  if (str === 'null' || str === undefined) return null;
  else return str;
}

export function checkForYesOrNoValues(value) {
  if (value === 1 || value === '1') {
    return 'Yes';
  } else if (value === 0 || value === '0') {
    return 'No';
  } else {
    return false;
  }
}

export const RemoveTags = (str) => {
  if (str === null || str === '' || str === undefined) return '';
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '');
};

export function isValidDate(dateString) {
  // Check if the input is a valid date string in ISO format
  const date = parseISO(dateString);

  // Check if it's a valid date and not a single year value
  if (isDate(date) && !isNaN(date.getTime())) {
    const year = date.getFullYear();

    // Check if the date is within a valid range
    const startCurrentYear = startOfYear(new Date());
    const endCurrentYear = endOfYear(new Date());

    if (isAfter(date, startCurrentYear) && isBefore(date, endCurrentYear)) {
      return true; // Date is valid
    }

    if (year.toString().length === 4 && dateString.length === 4) {
      return false; // Single year value (e.g., 2023, 1865, etc.) is considered invalid
    }
  }

  return false; // Date is invalid or not in the current year
}

export function capitalizeFirstLetter(inputString) {
  if (typeof inputString !== 'string') {
    return inputString;
  }

  // Capitalizes First Letter just if first letter of the string is lowercase
  if (inputString?.charAt(0) === inputString?.charAt(0).toLowerCase()) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
  }

  return inputString; // Return the string unchanged if the first letter is not lowercase
}

export function getUsageTypeDetails(usageTypeDetails, type) {
  return usageTypeDetails?.find((usageType) => usageType.type === type);
}

export const PeriodMaker = (startDate, endDate, referenceYear) => {
  let start = startDate.split('-');
  let end = endDate.split('-');

  if (start[0] == end[0]) {
    return referenceYear;
  } else {
    let startYear = referenceYear - 1;
    let endYear = referenceYear;
    return `${startYear} - ${endYear.toString().slice(2)}`;
  }
};

export const RemoveLeadingZeroes = (str) => {
  // Regex to remove leading zeros from a string
  const regex = new RegExp('^0+(?!$)', 'g');
  // Replaces the matched value with given string
  return str.replaceAll(regex, '');
};

/**
 * Returns the application version based on the current hostname.
 * @param {string} version - The default version to be returned if no match is found. Default set to "v1"
 * @returns {string} - The determined application version.
 */
export const getAppVersion = (version = 'v1') => {
  const hostname = window.location.hostname;

  if (['staging2', 'testcyf', 'worldlandtrust.compareyourfootprint.com'].some((x) => hostname.includes(x))) {
    version = 'stage2';
  } else if (['staging', 'staging-green'].some((x) => hostname.includes(x))) {
    version = 'stage';
  } else if (['local', 'dev', 'testcyf'].some((x) => hostname.includes(x))) {
    version = 'dev';
  }

  return version;
};

export const delay = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));
