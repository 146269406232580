const questionStyles = {
  fontSize: 14,
  fontFamily: 'sans-serif',
  display: 'flex',
  padding: 10,
  // float: "right"
};

const dottedLine = {
  borderBottom: '2px dotted #56cbb1',
  height: 18,
  flexGrow: 1,
  margin: '0 20px',
  display: 'inline-flex',
};

const customStyles = {
  clearIndicator: (defaultStyles) => ({
    ...defaultStyles,
    top: '0',
    right: '-47px',
    position: 'absolute',
    background: 'red',
    color: 'white',
    borderRadius: '4px',
  }),
  indicatorSeparator: () => {},
  indicatorsContainer: () => ({
    // position: "absolute",
    // background: "red",
    // right: "0px",
    // top: "0",
    // borderRadius: "4px",
  }),
  dropdownIndicator: (defaultStyles) => ({
    ...defaultStyles,
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '15px',
    backgroundImage: "url('https://maze.taxtaker.com/assets/images/gray-arrow-down.svg')",
    backgroundSize: '10px',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    backgroundPosition: 'center',
    '& svg': { display: 'none' },
  }),
  container: (provided, state) => ({
    ...provided,
    // color: state.isSelected ? 'red' : 'blue',
    // padding: 20,
    alignItems: 'center',
    backgroundColor: 'rgb(241, 241, 242)',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 0,
    borderRadius: 3,
    // boxSizing: "border-box",
    color: 'rgb(0, 0, 0)',
    cursor: 'default',
    display: 'inline-block',
    fontFamily: 'sans-serif',
    fontSize: 14,
    fontWeight: '400',
    height: '37px',
    lineHeight: '21px',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    padding: '3px',
    // paddingLeft: "10px",
    // paddingRight: "10px",
    // paddingTop: "7px",
    textAlign: 'start',
    textIndent: 0,
    whiteSpace: 'pre',
    width: 287.594,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    // background: "transparent",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const headerStyles = {
  fontSize: 24,
  fontFamily: 'Lato, sans-serif',
  fontWeight: 500,
  textAlign: 'center',
  marginBottom: '25px',
  // float: "left"
};

const labelStyles = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: 0,
};

const buttonStyles = {
  display: 'inline-block',
  padding: '6px 12px',
  marginBottom: 0,
  fontSize: 14,
  fontWeight: 400,
  fontFamily: 'sans-serif',
  lineHeight: 1.42857143,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  msTouchAction: 'manipulation',
  touchAction: 'manipulation',
  cursor: 'pointer',
  WebkitUserSelect: 'none',
  mozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
  backgroundImage: 'none',
  border: '1px solid transparent',
  borderRadius: 4,
  textDecoration: 'none',

  color: '#FFF',
  backgroundColor: '#56cbb1',
  borderColor: '#56cbb1',
};

const noButton = {
  ...buttonStyles,
  color: '#56cbb1',
  backgroundColor: '#fff',
  borderColor: '#56cbb1',
};

const underline = {
  display: 'inline-block',
  borderBottom: '2px dotted #56cbb1',
  marginLeft: '5px',
  marginRight: '5px',
  cursor: 'pointer',
  position: 'relative',
};

const explainerModal = {
  position: 'absolute',
  padding: '20px 15px',
  marginTop: '10px',
  top: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '280px',
  zIndex: '1000',
  float: 'left',
  fontSize: '13px',
  lineHeight: '20px',
  color: '#6d6e70',
  textAlign: 'left',
  listStyle: 'none',
  backgroundColor: '#fff',
  backgroundClip: 'padding-box',
  borderRadius: '0',
  border: '0',
  fontWeight: '400',
  boxShadow: '0 2px 5px 0 rgba(0,0,0,.25)',
};

const explainerModalArrow = {
  top: '-8px',
  borderColor: 'transparent transparent #eee',
  borderWidth: '0 8px 8px',
  position: 'absolute',
  display: 'block',
  left: '50%',
  width: '0',
  height: '0',
  borderStyle: 'solid',
  transform: 'translateX(-50%)',
};

export {
  questionStyles,
  dottedLine,
  customStyles,
  headerStyles,
  labelStyles,
  buttonStyles,
  noButton,
  underline,
  explainerModal,
  explainerModalArrow,
};
