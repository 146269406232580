import { Error } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import * as yup from 'yup';
import { convertToTitleCase } from './GlobalFunctions';

export const ShowTooltip = ({ content }) => {
  if (content) {
    return (
      <Tooltip
        title={<span style={{ fontSize: 12 }}>{content}</span>}
        placement='right'
        componentsProps={{ tooltip: { sx: { bgcolor: 'rgb(255,15,15)', display: content ? 'block' : 'none' } } }}>
        <Error sx={{ marginLeft: '10px', cursor: 'pointer', color: 'rgb(255,15,15)' }} />
      </Tooltip>
    );
  } else return null;
};

export const getOwnershipHierarchy = (ownershipHierarchy) => {
  let trueValueKey = null;

  for (const key in ownershipHierarchy) {
    if (ownershipHierarchy[key] === true) {
      trueValueKey = key;
      break; // Exit the loop once the first true value is found
    }
  }
  return convertToTitleCase(trueValueKey);
};

export const saveOrgValidationSchema = yup.object().shape({
  name: yup.string().required('Organisation name is required'),
  reportingYearEndDay: yup.string().required('Reporting year end is required'),
  industry: yup.string().required('Industry sector is required'),
  country: yup.string().required('Country is required'),
  currency: yup.string().required('Reporting currency is required'),
  industrySubcategory: yup.string().required('Industry subcategory is required').nullable(),
});

export const alertMessage =
  'Are you sure this is the correct financial year end for your organisation? ' +
  'Changing reporting year end date after entering activity data requires a full recalculation and can incur a support fee. ' +
  'N.B. The app automatically generates a calendar year report in addition to your FY report.';

export const setOrgOwnershipHierarchy = (newOwnershipHierarchy, org) => {
  // Set all values to false
  Object.keys(newOwnershipHierarchy).map((x) => (newOwnershipHierarchy[x] = false));
  if (org?.id === null || !org) {
    // Adding new Organisation, set default value to directUser
    newOwnershipHierarchy['directUser'] = true;
  } else if (org?.id === org?.whiteLabelId) {
    // Org is a White Label Admin
    newOwnershipHierarchy['whiteLabelAdmin'] = true;
  } else if (org?.id !== org?.whiteLabelId && org?.whiteLabelId !== '0') {
    // Org is a White Label Client
    newOwnershipHierarchy['whiteLabelClient'] = true;
  } else if (org?.id === org?.consultantId) {
    // Org is a Consultant Admin
    newOwnershipHierarchy['consultantAdmin'] = true;
  } else if (org?.id !== org?.consultantId && org?.consultantId !== 'NONE') {
    // Org is a Consultant Client
    newOwnershipHierarchy['consultantClient'] = true;
  } else {
    // Org is a Direct User
    newOwnershipHierarchy['directUser'] = true;
  }
  return newOwnershipHierarchy;
};
