import SearchIcon from '@mui/icons-material/Search';
import { Alert, AlertTitle, LinearProgress, TextField } from '@mui/material';
import cleanDeep from 'clean-deep';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions.js';
import { convertToTitleCase, itemToOptions, removeCamelCase } from '../utils/GlobalFunctions.js';
import { adminPortalSelect } from '../utils/ReactSelectStyles.js';
import { filteringTable, formatCellValue, formatHeaderValue } from './utils/adminPortalHelpers.js';

const AdminPortalTable = ({
  branding,
  getAllItemsFromDB,
  fetchedData,
  table,
  profile,
  stateVariables,
  tableColumns,
  tableActions,
  toggleModal,
  fetchDataFromOtherTables,
  tableFilters,
  searchableFields,
  getItemsByTermsFromIndex,
}) => {
  const history = useHistory();
  const { headers } = branding?.fonts[0]?.font || {};
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtering, setFiltering] = useState({});

  useEffect(() => {
    // Fetch data from DB if it's not already fetched
    (async () => {
      setLoading(true);
      setColumns(tableColumns);
      try {
        if (!fetchedData || fetchedData.length < 1) {
          const { data: items = {} } = await getAllItemsFromDB(table);
          setData(items && items?.items);
        }

        if (fetchDataFromOtherTables.length) {
          // Checks if we need to fetch data from other tables
          fetchDataFromOtherTables.forEach(async (table) => await getAllItemsFromDB(table));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    // Get the columns for the table, based on the profile details
    setColumns(tableColumns);
  }, [profile]);

  useEffect(() => {
    setData(filteringTable(fetchedData, filtering));
  }, [fetchedData, filtering, stateVariables]);

  if (loading) {
    // Show loading bar
    return <LinearProgress color='inherit' />;
  }

  return (
    <section className='panel' style={{ fontFamily: headers }}>
      <header
        className='panel-heading'
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        {/* Admin Portal Table Name */}
        {removeCamelCase(table) || 'Admin Portal Table'}
        <div style={{ marginRight: 10, display: 'flex' }}>
          {Object.keys(cleanDeep(stateVariables)).length > 5 &&
            tableFilters?.map((filter) => {
              const options = stateVariables?.[filter.options]?.map(itemToOptions) || filter.options;
              return (
                <Select
                  name={filter.name}
                  options={options}
                  onChange={(selected, x) => {
                    const newFilteringState = { ...filtering };
                    if (!selected) {
                      delete newFilteringState[x.name];
                    } else {
                      newFilteringState[x.name] = selected.value;
                    }
                    setFiltering(newFilteringState);
                  }}
                  isOptionDisabled={!Array.isArray(stateVariables[filter.options]) ? true : false}
                  styles={adminPortalSelect}
                  isClearable={true}
                  placeholder={filter.label}
                  menuPlacement='auto'
                />
              );
            })}
          <TextField
            InputProps={{ endAdornment: <SearchIcon /> }}
            id='outlined-basic'
            label='Search'
            variant='outlined'
            size='small'
            name={searchableFields?.join('_') || 'text'}
            sx={{ marginLeft: 5 }}
            onChange={(e) => setFiltering({ ...filtering, [e.target.name]: e.target.value })}
          />
        </div>
      </header>
      <div className='panel-body' style={{ height: '100vh' }}>
        {data?.length < 1 && (
          <Alert severity='warning' sx={{ fontSize: 15, width: '50%' }}>
            <AlertTitle>No Match Found</AlertTitle>
            There are no search results!
          </Alert>
        )}
        <div className='panel-body' style={{ height: '100vh', overflowX: 'auto' }}>
          <div style={{ minWidth: '100%', height: '100%', display: data?.length < 1 ? 'none' : 'block' }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width + 500}
                  height={height}
                  rowHeight={100}
                  headerHeight={60}
                  rowStyle={{ textAlign: 'center', justifyContent: 'space-between' }}
                  headerClassName='admin_portal_column_headers'
                  rowCount={data && data.length}
                  rowGetter={({ index }) => data[index]}>
                  {/* Table Columns */}
                  {Object.keys(cleanDeep(stateVariables)).length > 5 &&
                    columns?.map((c, index) => {
                      return (
                        <Column
                          key={index}
                          label={convertToTitleCase(c?.label || c)}
                          dataKey={c?.key || c}
                          width={400}
                          cellRenderer={(x) => {
                            return formatCellValue({
                              ...x,
                              table,
                              stateVariables,
                              tableActions,
                              toggleModal,
                              history,
                              label: c?.label || c,
                              getItemsByTermsFromIndex,
                            });
                          }}
                          headerRenderer={formatHeaderValue}
                        />
                      );
                    })}
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    tableDetails: {
      tableName: table,
      sortIndex,
      columns,
      actions,
      fetchDataFromOtherTables,
      tableFilters,
      tableData,
      searchableFields,
    } = {},
    stateVariables,
  } = ownProps || {};

  let fetchedData = [];

  if (tableData?.length) {
    // The Data to display on the table comes from the getTableDetails function
    fetchedData = _.sortBy(tableData, [sortIndex]);
  } else if (table === 'benchmark') {
    // We need to handle differently the data for benchmark table
    const benchmarkData = state?.admin?.adminPortal?.[table];
    fetchedData = benchmarkData?.map((bmk) => {
      const orgDetails = _.find(stateVariables['adminOrgs'], { id: bmk.org });
      const industry = _.find(stateVariables['industries'], { id: orgDetails?.industry });
      return {
        ...bmk,
        orgName: orgDetails?.name,
        industry: industry?.id,
        industryName: industry?.name,
        industryIncludes: industry?.include,
        parent: industry?.parent,
      };
    });
  } else if (table === 'userPermissions') {
    // We need to handle differently the data for userPermissions table -> only view, no actions.
    const userPermissions = state?.admin?.adminPortal?.[table];
    fetchedData = userPermissions?.map((userPerm) => {
      const profile = _.find(stateVariables['profile'], { id: userPerm.user });
      return {
        ...userPerm,
        user: profile ? `${profile?.firstName} ${profile?.lastName}` : userPerm.user,
        email: profile ? profile?.email : userPerm.email,
      };
    });
  } else if (table === 'usageTypeDetails') {
    const usageTypeDetails = state?.admin?.adminPortal?.[table];
    fetchedData = usageTypeDetails?.map((usageType) => {
      if (usageType?.subentity === 'null' || usageType?.subentity === null || usageType?.subentity === undefined) {
        usageType.subentity = 'All';
      }

      return {
        ...usageType,
      };
    });
  } else {
    // Fetch it from the DB and get it from the state
    fetchedData = _.sortBy(state?.admin?.adminPortal?.[table], [sortIndex]) || state?.admin?.adminPortal?.[table];
  }

  // Add a searchable key to the data in case there are any searchable fields
  if (searchableFields.length > 0) {
    const searchableKey = searchableFields.join('_');
    const newItem = fetchedData?.map((item) => {
      return { ...item, [searchableKey]: searchableFields.map((field) => item[field]).join('_') };
    });
    fetchedData = newItem;
  }

  return {
    fetchedData,
    branding: state.branding,
    table,
    tableColumns: columns,
    tableActions: actions,
    fetchDataFromOtherTables,
    tableFilters,
    searchableFields,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
  getItemsByTermsFromIndex: (index, filters, page, size) =>
    dispatch(ADMINACTIONS.getItemsByTermsFromIndex(index, filters, page, size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPortalTable);
