import { Search } from '@mui/icons-material';
import { LinearProgress, Link, Switch, TextField, Tooltip } from '@mui/material';
import _, { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { Actions as ORGANISATIONACTIONS } from '../../../store/actions/organisationActions';
import { RowBtn } from '../../../styledComponents/GlobalStyle';
import { itemToOptions } from '../../utils/GlobalFunctions';
import { adminPortalSelect } from '../../utils/ReactSelectStyles';

const OrganisationListTable = ({
  sortedOrganisations,
  tableName,
  getAllItemsFromDB,
  branding,
  industryOptions,
  setCurrentOrganisation,
  countries,
  toggleModal,
  getWLAdminOrgs,
  cyfAdmin,
  WhiteLabelAdmin,
  consultantAdmin,
  members,
  statusOptions,
  isWhiteLabelApp,
  whiteLabelAdminOrgs,
  consultantAdminOrgs,
  accountTypeOpt,
}) => {
  const history = useHistory();

  const { headers } = branding.fonts?.[0].font || {};
  const { primaryLinkText } = branding.colors || {};

  const [data, setData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterIndustry, setFilterIndustry] = useState('');
  const [filterByWhiteLabel, setFilterByWhiteLabel] = useState('');
  const [filterByConsultant, setFilterByConsultant] = useState('');
  const [filterByAccountType, setFilterByAccountType] = useState('');

  useEffect(() => {
    if (!sortedOrganisations) getAllItemsFromDB(tableName);
    if (!members) getAllItemsFromDB('profile');
    if (!whiteLabelAdminOrgs) getWLAdminOrgs();
  }, []);

  const filteringByName = () => {
    const filterOrgs =
      isArray(sortedOrganisations) &&
      sortedOrganisations?.filter(
        (org) =>
          (!filterName ||
            org.name?.toLowerCase().trim().includes(filterName?.toLowerCase().trim()) ||
            org.accountType?.toLowerCase().trim().includes(filterName?.toLowerCase().trim()) ||
            org.vatNumber?.toLowerCase().trim().includes(filterName?.toLowerCase().trim()) ||
            org.leiCode?.toLowerCase().trim().includes(filterName?.toLowerCase().trim()) ||
            org.isinNumber?.toLowerCase().trim().includes(filterName?.toLowerCase().trim()) ||
            org.countryName?.toLowerCase().trim().includes(filterName?.toLowerCase().trim())) &&
          (!filterIndustry || org.industry === filterIndustry) &&
          (!filterByWhiteLabel || org.whiteLabelId === filterByWhiteLabel) &&
          (!filterByConsultant || org.consultantId === filterByConsultant) &&
          (!filterByAccountType || org.accountType === filterByAccountType)
      );
    setData(filterOrgs);
  };

  useEffect(() => {
    filteringByName();
  }, [sortedOrganisations, filterName, filterIndustry, filterByWhiteLabel, filterByConsultant, filterByAccountType]);

  if (_.isString(sortedOrganisations)) {
    return (
      <div>
        <h1>No Clients</h1>
      </div>
    );
  }

  const updateOrganisation = (organisation) => {
    setCurrentOrganisation(organisation);
    localStorage.setItem('Organisation', organisation.id);
  };

  const getArray = (dataKey) => {
    let arr;
    switch (dataKey) {
      case 'industry':
        arr = industryOptions;
        break;
      case 'country':
        arr = countries;
        break;
      case 'userId':
        arr = members;
        break;
      case 'whiteLabelId':
        arr = whiteLabelAdminOrgs;
        break;
      case 'consultantId':
        arr = consultantAdminOrgs;
        break;
      case 'parentOrganisation':
        arr = sortedOrganisations;
        break;
      case 'active':
        arr = statusOptions;
        break;
      default:
        break;
    }
    return arr;
  };

  const formatCellValue = ({ cellData, dataKey, rowData }) => {
    if (dataKey === 'parentOrganisation') {
      const value = cellData && cellData !== 'true' ? _.find(sortedOrganisations, { id: cellData })?.name : 'Yes';
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<span style={{ fontSize: 12 }}>{value}</span>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {value}
            </div>
          </Tooltip>
        </div>
      );
    } else if (dataKey === 'reportingYearEndDay') {
      const { reportingYearEndDay, reportingYearEndMonth } = rowData;
      return (
        <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
          {reportingYearEndDay && reportingYearEndMonth ? `${reportingYearEndDay}/${reportingYearEndMonth} ` : ''}
        </div>
      );
    } else if (dataKey === 'country') {
      const value = cellData ? _.find(countries, { id: cellData })?.name : '';
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<span style={{ fontSize: 12 }}>{value}</span>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {value}
            </div>
          </Tooltip>
        </div>
      );
    } else {
      const arr = getArray(dataKey);
      return (
        <div>
          <Tooltip
            sx={{ fontSize: 20 }}
            title={
              <span style={{ fontSize: 12 }}>{cellData && arr ? _.find(arr, { value: cellData })?.label : cellData}</span>
            }>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {cellData && arr ? _.find(arr, { value: cellData })?.label : cellData}
            </div>
          </Tooltip>
        </div>
      );
    }
  };

  const reportingPeriod = ({ cellData, rowData }) => {
    return cellData === null || cellData === undefined
      ? 'N/A'
      : `${rowData.reportingYearEndDay}/${rowData.reportingYearEndMonth}`;
  };

  const action = ({ rowData, dataKey }) => {
    if (['facility', 'report', 'usage'].includes(dataKey)) {
      const path = dataKey == 'report' ? 'reports' : dataKey == 'usage' ? 'usage' : 'facilities';
      return (
        <Link
          sx={{ cursor: 'pointer', color: primaryLinkText, textDecoration: 'none' }}
          onClick={() => {
            updateOrganisation(rowData);
            history.push(`/${path}`);
          }}>
          {path.charAt(0).toUpperCase() + path.slice(1)}
        </Link>
      );
    } else if (['reportGeneration'].includes(dataKey)) {
      const textField = rowData[dataKey] ? 'Disable Reports' : 'Enable Reports';
      // Show the switch White Label Admin and Consultant Admin -> CYF Admin View
      const showOnlyAdminOrgs = [rowData.whiteLabelId, rowData.consultantId].includes(rowData.id);
      if (showOnlyAdminOrgs && !cyfAdmin) return null;
      return (
        <Tooltip sx={{ fontSize: 20 }} title={<span style={{ fontSize: 12 }}>{textField}</span>}>
          <Switch
            checked={!rowData[dataKey]}
            onClick={() => {
              if (rowData[dataKey]) {
                toggleModal(true, 'disableOrgReports-table-disable', { ...rowData, reportGeneration: false });
              } else {
                toggleModal(true, 'enableOrgReports-table-disable', { ...rowData, reportGeneration: true });
              }
            }}
          />
        </Tooltip>
      );
    } else if (['organisationCreation'].includes(dataKey)) {
      const textField = rowData[dataKey] ? 'Disable Add Organisation' : 'Enable Add Organisation';
      // Show the switch White Label Admin and Consultant Admin -> CYF Admin View
      const showOnlyAdminOrgs = [rowData.whiteLabelId, rowData.consultantId].includes(rowData.id);
      if (showOnlyAdminOrgs && !cyfAdmin) return null;
      return (
        <Tooltip sx={{ fontSize: 20 }} title={<span style={{ fontSize: 12 }}>{textField}</span>}>
          <Switch
            checked={!rowData[dataKey]}
            onClick={() => {
              // Enable / disable organisation generation
              if (rowData[dataKey]) {
                toggleModal(true, 'disableOrgCreation-table-disable', { ...rowData, organisationCreation: false });
              } else {
                toggleModal(true, 'enableOrgCreation-table-disable', { ...rowData, organisationCreation: true });
              }
            }}
          />
        </Tooltip>
      );
    } else if (['excludeFromDeletion'].includes(dataKey)) {
      const textField = !rowData[dataKey] ? 'Exclude Organisation' : 'Include Organisation';
      if (rowData?.accountType?.includes('Test')) {
        return (
          <Tooltip sx={{ fontSize: 20 }} title={<span style={{ fontSize: 12 }}>{textField}</span>}>
            <Switch
              checked={rowData[dataKey]}
              onClick={() => {
                if (rowData[dataKey]) {
                  toggleModal(true, 'excludeOrgFromDeletion-table-disable', { ...rowData, excludeFromDeletion: false });
                } else toggleModal(true, 'excludeOrgFromDeletion-table-enable', { ...rowData, excludeFromDeletion: true });
              }}
            />
          </Tooltip>
        );
      }
    } else if (['apiAccess'].includes(dataKey)) {
      return (
        <Tooltip
          sx={{ fontSize: 20 }}
          title={<span style={{ fontSize: 12 }}>{`${!rowData.apiAccess ? 'Enable' : 'Disable'} API Access`}</span>}>
          <Switch
            checked={rowData.apiAccess}
            onClick={() => {
              // Enable / disable API Access
              if (!rowData.apiAccess) toggleModal(true, 'enableAPIAccess-table-disable', { ...rowData, apiAccess: true });
              else toggleModal(true, 'disableAPIAccess-table-disable', { ...rowData, apiAccess: false });
            }}
          />
        </Tooltip>
      );
    } else {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Tooltip title={<span style={{ fontSize: 12 }}>View/Edit Organisation</span>}>
            <RowBtn to={`/organisations/${rowData.id}`}>View/Edit</RowBtn>
          </Tooltip>
          {cyfAdmin && (
            <Tooltip title={<span style={{ fontSize: 12 }}>Recalculate Usage Organisation</span>}>
              <i
                id='recalcOrgUsage'
                className='fa fa-refresh'
                style={{ cursor: 'pointer', marginLeft: 10, display: isWhiteLabelApp ? 'none' : 'block' }}
                onClick={() => toggleModal(true, 'orgs-table-recalcOrgUsage', rowData)}
              />
            </Tooltip>
          )}
        </div>
      );
    }
  };

  if (!isArray(data)) return <LinearProgress color='inherit' />;

  return (
    <section className='panel' style={{ fontFamily: headers }}>
      <header
        className='panel-heading'
        style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'start', width: '100%' }}>
        <div style={{ marginRight: 10, display: 'flex', flexWrap: 'wrap' }}>
          <Select
            id='industry-select'
            options={industryOptions}
            onChange={(selected) => setFilterIndustry(selected?.value)}
            isClearable={true}
            isOptionDisabled={!isArray(industryOptions) ? true : false}
            styles={adminPortalSelect}
            placeholder={'Industry'}
            menuPlacement='auto'
            menuPosition='fixed'
          />
          {cyfAdmin && (
            <Select
              id='industry-select'
              options={whiteLabelAdminOrgs}
              onChange={(selected) => setFilterByWhiteLabel(selected?.value)}
              isClearable={true}
              isOptionDisabled={!isArray(whiteLabelAdminOrgs) ? true : false}
              styles={adminPortalSelect}
              placeholder={'White Label'}
              menuPlacement='auto'
              menuPosition='fixed'
            />
          )}
          {cyfAdmin && (
            <Select
              id='industry-select'
              options={consultantAdminOrgs}
              onChange={(selected) => setFilterByConsultant(selected?.value)}
              isClearable={true}
              isOptionDisabled={!isArray(consultantAdminOrgs) ? true : false}
              styles={adminPortalSelect}
              placeholder={'Consultant'}
              menuPlacement='auto'
              menuPosition='fixed'
            />
          )}
          {cyfAdmin && (
            <Select
              id='industry-select'
              options={accountTypeOpt}
              onChange={(selected) => setFilterByAccountType(selected?.value)}
              isClearable={true}
              isOptionDisabled={!isArray(accountTypeOpt) ? true : false}
              styles={adminPortalSelect}
              placeholder={'Account Type'}
              menuPlacement='auto'
              menuPosition='fixed'
            />
          )}
          <TextField
            InputProps={{ endAdornment: <Search /> }}
            id='outlined-basic'
            label='Search'
            variant='outlined'
            size='small'
            sx={{ marginLeft: 5 }}
            onChange={(e) => setFilterName(e.target.value)}
          />
        </div>
      </header>
      {isArray(data) && (
        <div className='panel-body' style={{ height: '100vh', overflowX: 'auto' }}>
          <div style={{ width: '100%', height: '100%', display: !data?.length > 0 ? 'none' : 'block' }}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width + 500}
                  height={height}
                  rowHeight={50}
                  headerHeight={60}
                  headerStyle={{ verticalAlign: 'bottom', width: '100%', display: 'flex', justifyContent: 'center' }}
                  rowStyle={{ textAlign: 'center' }}
                  headerClassName='admin_portal_column_headers'
                  rowCount={data?.length}
                  rowGetter={({ index }) => data[index]}>
                  {cyfAdmin && <Column label='ID' dataKey='id' width={200} cellRenderer={formatCellValue} />}
                  <Column label='Name' dataKey='name' width={300} cellRenderer={formatCellValue} />
                  <Column
                    label='Industry'
                    dataKey='industry'
                    width={300}
                    className='cell-data'
                    cellRenderer={formatCellValue}
                  />
                  {cyfAdmin && <Column label='Country' dataKey='country' width={300} cellRenderer={formatCellValue} />}
                  {cyfAdmin && (
                    <Column label='Account Type' dataKey='accountType' width={300} cellRenderer={formatCellValue} />
                  )}
                  <Column
                    style={{ textAlign: 'center' }}
                    label='Currency'
                    dataKey='currency'
                    width={200}
                    cellRenderer={formatCellValue}
                  />
                  <Column
                    style={{ textAlign: 'center' }}
                    label='Reporting Period'
                    dataKey='reportingYearEndDay'
                    width={300}
                    cellRenderer={(reportingPeriod, formatCellValue)}
                  />
                  {cyfAdmin && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='Parent Organisation'
                      dataKey='parentOrganisation'
                      width={250}
                      cellRenderer={formatCellValue}
                    />
                  )}
                  {cyfAdmin && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='Member'
                      dataKey='userId'
                      width={300}
                      cellRenderer={formatCellValue}
                    />
                  )}
                  {cyfAdmin && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='VAT Number'
                      dataKey='vatNumber'
                      width={150}
                      cellRenderer={formatCellValue}
                    />
                  )}
                  {cyfAdmin && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='ISIN'
                      dataKey='isinNumber'
                      width={150}
                      cellRenderer={formatCellValue}
                    />
                  )}
                  {cyfAdmin && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='LEI'
                      dataKey='leiCode'
                      width={150}
                      cellRenderer={formatCellValue}
                    />
                  )}
                  {cyfAdmin && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='White Label'
                      dataKey='whiteLabelId'
                      width={350}
                      cellRenderer={formatCellValue}
                    />
                  )}
                  {cyfAdmin && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='Consultant'
                      dataKey='consultantId'
                      width={350}
                      cellRenderer={formatCellValue}
                    />
                  )}
                  <Column
                    style={{ textAlign: 'center' }}
                    label='Facility'
                    dataKey='facility'
                    width={160}
                    cellRenderer={action}
                  />
                  <Column
                    style={{ textAlign: 'center' }}
                    label='Report'
                    dataKey='report'
                    width={160}
                    cellRenderer={action}
                  />
                  <Column
                    style={{ textAlign: 'center' }}
                    label='Usage Data'
                    dataKey='usage'
                    width={160}
                    cellRenderer={action}
                  />
                  {(cyfAdmin || WhiteLabelAdmin || consultantAdmin) && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='Disable Reports'
                      dataKey='reportGeneration'
                      width={160}
                      cellRenderer={action}
                    />
                  )}
                  {(cyfAdmin || WhiteLabelAdmin || consultantAdmin) && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='Disable Add Organisation'
                      dataKey='organisationCreation'
                      width={250}
                      cellRenderer={action}
                    />
                  )}
                  {cyfAdmin && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='Exclude From Deletion'
                      dataKey='excludeFromDeletion'
                      width={300}
                      cellRenderer={action}
                    />
                  )}
                  {cyfAdmin && (
                    <Column
                      style={{ textAlign: 'center' }}
                      label='API Access'
                      dataKey='apiAccess'
                      width={160}
                      cellRenderer={action}
                    />
                  )}
                  <Column
                    style={{ alignSelf: 'center' }}
                    label='Action'
                    dataKey='action'
                    width={300}
                    cellRenderer={action}
                  />
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  const tableName = 'organisation';
  const adminOrgs = state?.admin?.organisations;
  const { id } = state.profile?.details;
  let sortedOrganisations;

  if (adminOrgs) {
    sortedOrganisations = isArray(adminOrgs) && _.sortBy(adminOrgs, ['id']);
  } else
    sortedOrganisations = isArray(state?.admin?.adminPortal) && _.sortBy(state?.admin?.adminPortal?.[tableName], ['id']);

  const statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  const isAdminOpt = [
    'Direct User',
    'Benchmark',
    'Consultant',
    'Demo',
    'Green Element Org',
    'CYF Test Organisation',
    'Client Test Organisation',
    'White Label',
  ];

  return {
    customerManagementRole: ['621810'].includes(id),
    sortedOrganisations: adminOrgs || sortedOrganisations || state?.admin?.adminPortal?.[tableName],
    branding: state.branding,
    industryOptions: state.organisation?.industries?.map((item) => ({
      label: item.name,
      value: item.id,
    })),
    countries: state.organisation?.countries,
    tableName,
    cyfAdmin: state.profile.details.cyfAdmin,
    WhiteLabelAdmin: state.profile.details.whiteLabelAdmin,
    consultantAdmin: state.profile.details.consultantAdmin,
    members: state?.admin?.adminPortal?.profile?.map((x) => ({
      label: `${x.firstName} ${x.lastName}`,
      value: x.id,
    })),
    statusOptions,
    isWhiteLabelApp: state.isWhiteLabelApp,
    whiteLabelAdminOrgs: state?.admin?.whiteLabelAdminOrgs?.map(itemToOptions),
    consultantAdminOrgs: sortedOrganisations
      ? sortedOrganisations?.filter((x) => x.consultantId === x.id).map(itemToOptions)
      : [],
    accountTypeOpt: isAdminOpt.map((x) => ({ label: x, value: x })),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  setCurrentOrganisation: (organisation) => dispatch(ORGANISATIONACTIONS.setCurrentOrganisation(organisation)),
  getWLAdminOrgs: () => dispatch(ORGANISATIONACTIONS.getWLAdminOrgs()),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationListTable);
