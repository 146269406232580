import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import InviteUsersForm from './InviteUsersForm';
import UsersListRow from './UsersListRow';
import { AddButton } from '../../styledComponents/GlobalStyle';
import Modal from '../Organisations/Modal';
import LinearProgress from '@mui/material/LinearProgress';

const UsersListTable = ({ branding, sortedUserList }) => {
  const [showInviteUserForm, setShowInviteUserForm] = useState(false);

  const { body } = branding.fonts?.[0].font;

  if (!sortedUserList?.length > 0 && !sortedUserList) return <LinearProgress color='inherit' />;
  return (
    <div className='row' style={{ fontFamily: body }}>
      <div className='col-lg-12'>
        <section className='panel'>
          <header className='panel-heading'>Your Users</header>
          <div className='panel-body'>
            <table id='users-table' className='table table-hover general-table'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sortedUserList &&
                  Array.isArray(sortedUserList) &&
                  sortedUserList.map((userDetails, index) => <UsersListRow key={index} userDetails={userDetails} />)}
                {branding && branding?.inviteUsers ? <tr>
                  <td style={{ border: 'none' }}>
                    <AddButton
                      id='invite-user-button'
                      onClick={() => setShowInviteUserForm(!showInviteUserForm)}
                      className='btn btn-success'
                      bordercolor='true'>
                      Invite User
                    </AddButton>
                  </td>
                </tr> : null}
              </tbody>
            </table>
          </div>
        </section>
      </div>
      {showInviteUserForm && (
        <InviteUsersForm setShowInviteUserForm={setShowInviteUserForm} showInviteUserForm={showInviteUserForm} />
      )}
      <Modal />
    </div>
  );
};

const mapStateToProps = (state) => {
  const sortedUserList =
    state.organisation &&
    state.organisation.users &&
    _.uniqBy(state.organisation.users, 'sub').sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return {
    currentOrganisation: state.currentOrganisation,
    branding: state.branding,
    sortedUserList,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UsersListTable);
