import React, { useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const colors = [
  '#1f9b38',
  '#111111',
  '#FFE711',
  '#FF9300',
  '#737373',
  '#e6ce00',
  '#e68400',
  '#595959',
  '#b3a000',
  '#b36700',
  '#156a26',
  '#404040',
  '#807300',
  '#804a00',
  '#6ae182',
  '#a6a6a6',
  '#ffed4d',
  '#ffb34d',
  '#0d4017',
  '#262626',
  '#4d4500',
  '#4d2c00',
  '#40d95f',
  '#8c8c8c',
  '#ffe81a',
  '#ff9e1a',
];

const BenchmarkReport = ({
  chartOptions,
  avgBenchmarkWithPerM2,
  avgBenchmarkWithPerFTE,
  avgBenchmarkWithPerMillion,
  scope1And2PerMillion,
  scope1And2PerM2,
  scope1And2PerFTE,
  ratioPerMillion,
  ratioPerM2,
  ratioPerFTE,
  year,
  orgTurnover,
}) => {
  const ref = useRef(null);

  if (!year || !orgTurnover) return null;

  return (
    <div>
      <h3>Benchmark Report: Emissions intensity against industry benchmark</h3>
      <table className='table table-hover general-table' id='rankTable'>
        <thead>
          <tr>
            <th>Year</th>
            <th> tCO2e Scope 1+2 Intensity</th>
            <th>Industry Benchmark tCO2e Scope 1+2 Intensity (per million $ revenue)</th>
            <th> Scope 1+2 Operational Rating</th>
            <th>Rank</th>
            {avgBenchmarkWithPerFTE &&
              scope1And2PerFTE[((<th> tCO2e per FTE</th>), (<th>Industry Average tCO2e per FTE</th>), (<th>Rank</th>))]}
            {avgBenchmarkWithPerM2 &&
              scope1And2PerM2 && [<th> tCO2e per m2</th>, <th>Industry Average tCO2e per m2</th>, <th>Rank</th>]}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{year}</td>
            <td>{scope1And2PerMillion && scope1And2PerMillion.toFixed(2)}</td>
            <td>{avgBenchmarkWithPerMillion && avgBenchmarkWithPerMillion.toFixed(2)}</td>
            <td>{avgBenchmarkWithPerMillion && ((scope1And2PerMillion / avgBenchmarkWithPerMillion) * 100).toFixed(2)}%</td>
            <td>{ratioPerMillion}</td>
            {avgBenchmarkWithPerFTE &&
              scope1And2PerFTE[
                ((<td>{scope1And2PerFTE && scope1And2PerFTE.toFixed(2)}</td>),
                (<td>{avgBenchmarkWithPerFTE && avgBenchmarkWithPerFTE.toFixed(2)}</td>),
                (<td>{ratioPerFTE}</td>))
              ]}
            {avgBenchmarkWithPerM2 &&
              scope1And2PerM2 && [
                <td>{scope1And2PerM2 && scope1And2PerM2.toFixed(2)}</td>,
                <td>{avgBenchmarkWithPerM2 && avgBenchmarkWithPerM2.toFixed(2)}</td>,
                <td>{ratioPerM2}</td>,
              ]}
          </tr>
        </tbody>
      </table>

      <p>
        Intensity: Organisation’s emissions per million $ revenue
        <br />
        Operational Rating: Ratio of your organisation’s Scope 1+2 intensity compared wit the industry average benchmark
        <br />
        Rank: Ranking of your organisation’s Scope 1+2 intensity within the group of organisations in your industry
        <br />
        FTE: Full Time Equivalent employee
        <br />
        m2: Square metre of floorspace (Gross Internal Area, GIA)
      </p>
      <br></br>

      <HighchartsReact ref={ref} highcharts={Highcharts} options={chartOptions} />

      <br></br>
      <p>
        <small>tCO2e - tonnes of carbon dioxide equivalent;</small>
      </p>
      <p>
        <small>FTE - Full Time Equivalent Employees - one FTE is one person working for one year;</small>
      </p>
      <p>
        <small>Scope - description of types of direct / indirect emissions defined by the GHG Protocol;</small>
      </p>
      <p>
        <small>
          Intensity Ratio - a measure of GHG emissions per unit measure of productivity such as revenue, employees, number of
          products made etc.;
        </small>
      </p>
      <p>
        <small>
          WTT - Well to Tank - this describes the Scope 3 emissions associated with transporting fuel from its origin to the
          site where it is turned into energy.
        </small>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  const organisationDetails = state.organisation?.details;
  const industries = state?.organisation.industries;
  const { totalEmissionsByScope } = state.consumption || {};
  const metrics = state.metric?.list;
  const benchmarkData = state.benchmarkData;

  let m2 = 0;
  let fte = 0;
  let revenue;

  if (!benchmarkData || benchmarkData.length == 0 || !organisationDetails) {
    console.log('No Benchmark Data');
    return {};
  }

  const benchmarkWithPerM2 = benchmarkData?.filter((item) => item.scope1and2perm2);
  const avgBenchmarkWithPerM2 =
    benchmarkWithPerM2?.length > 0 &&
    _.sumBy(benchmarkWithPerM2, (item) => Number(item.scope1and2perm2)) / benchmarkWithPerM2.length;

  const benchmarkWithPerFTE = benchmarkData?.filter((item) => item.scope1and2perfte);
  const avgBenchmarkWithPerFTE =
    benchmarkWithPerFTE?.length > 0 &&
    _.sumBy(benchmarkWithPerFTE, (item) => Number(item.scope1and2perfte)) / benchmarkWithPerFTE.length;

  const benchmarkWithPerMillion = benchmarkData?.filter((item) => item.scope1and2perm);
  const avgBenchmarkWithPerMillion =
    benchmarkWithPerMillion?.length > 0 &&
    _.sumBy(benchmarkWithPerMillion, (item) => Number(item.scope1and2perm)) / benchmarkWithPerMillion.length;

  const year = state.report?.details?.year;
  const organisationMetrics = state.organisation?.metrics?.find((item) => item.year == year);

  const orgTurnover = organisationMetrics?.turnover || organisationMetrics?.originalTurnover;

  organisationMetrics &&
    organisationDetails &&
    ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
      const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
      const metricID = orgIndustry?.[ir];
      const metric = metrics?.find((metric) => metric.id == metricID);
      const metricName = metric && (metric.name == 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

      const metricValue = organisationMetrics[`metric${index + 1}`];

      if (metricName == 'm2') m2 = metricValue;

      if (metricName == 'fte') fte = metricValue;
    });

  const totalEmissionsByScopeThisYear = totalEmissionsByScope?.[year];

  const scope1And2 =
    totalEmissionsByScopeThisYear &&
    (totalEmissionsByScopeThisYear?.[1]?.co2e || 0) + (totalEmissionsByScopeThisYear?.[2]?.co2e || 0);
  const scope3 = totalEmissionsByScopeThisYear && totalEmissionsByScopeThisYear?.[3]?.co2e;

  switch (organisationDetails.currency) {
    case '£':
      revenue = orgTurnover / 1000000;
      break;

    case '€':
      revenue = orgTurnover / 1000000;
      break;

    default:
      revenue = orgTurnover / 1000000;
  }

  const scope1And2PerMillion = revenue > 0 && scope1And2 / revenue;
  const scope1And2PerM2 = m2 > 0 && scope1And2 / m2;
  const scope1And2PerFTE = fte > 0 && scope1And2 / fte;

  const positionPerMillion =
    benchmarkWithPerMillion?.filter((item) => item.scope1and2perm < scope1And2PerMillion).length + 1 || 1;
  const positionPerM2 = benchmarkWithPerM2?.filter((item) => item.scope1and2perm2 < scope1And2PerM2).length + 1 || 1;
  const positionPerFTE = benchmarkWithPerFTE?.filter((item) => item.scope1and2perfte < scope1And2PerFTE).length + 1 || 1;

  const ratioPerMillion = `${positionPerMillion}:${(benchmarkWithPerMillion && benchmarkWithPerMillion.length + 1) || 1}`;
  const ratioPerM2 = `${positionPerM2}:${(benchmarkWithPerM2 && benchmarkWithPerM2.length + 1) || 1}`;
  const ratioPerFTE = `${positionPerFTE}:${(benchmarkWithPerFTE && benchmarkWithPerFTE.length + 1) || 1}`;

  const chartData = benchmarkWithPerMillion.map((item, i) => ({
    name: `Org ${i + 1}`,
    y: parseFloat(item.scope1and2perm) || 0,
    color: colors[1],
  }));

  chartData.push({
    name: organisationDetails && organisationDetails.name,
    y: parseFloat((scope1And2PerMillion && scope1And2PerMillion.toFixed(2)) || 0),
    color: '#1f9b38',
  });

  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column',
    },
    title: {
      text: `Tonnes CO2e per million revenue`,
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
    },
    yAxis: [
      {
        title: {
          text: '',
        },
        showFirstLabel: false,
      },
    ],

    xAxis: {
      type: 'category',
      labels: {
        enabled: false,
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
        },
      },
    },

    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 100,
    },
    series: [
      {
        //color: 'rgb(158, 159, 163)',
        data: _.orderBy(chartData, 'y'),
        name: '',
        pointWidth: 50,
        showInLegend: false,
      },
    ],
    // series: [{ data: _.orderBy(chartData,'y')}],
  };

  return {
    chartOptions,
    year,
    avgBenchmarkWithPerM2,
    avgBenchmarkWithPerFTE,
    avgBenchmarkWithPerMillion,
    scope1And2PerMillion: scope1And2PerMillion ? scope1And2PerMillion : 0,
    scope1And2PerM2,
    scope1And2PerFTE,
    ratioPerMillion,
    ratioPerM2,
    ratioPerFTE,
    orgTurnover,
  };
};

export default connect(mapStateToProps, {})(BenchmarkReport);
