import React from 'react';

import { Link } from 'react-router-dom';

import { BreadCrumbs } from '../../styledComponents/GlobalStyle';

const Breadcrumb = ({ items }) => {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <BreadCrumbs className='breadcrumbs-alt'>
          <li>
            <Link className={`${items.length == 0 ? 'current' : ''}`} to='/'>
              Home
            </Link>
          </li>
          {items &&
            items.map((item, index) => (
              <li key={index}>
                <Link
                  style={{ fontWeight: 600 }}
                  key={index}
                  className={`${index == items.length - 1 ? 'current' : ''}`}
                  to={item.link}>
                  {item.label}
                </Link>
              </li>
            ))}
        </BreadCrumbs>
      </div>
    </div>
  );
};

export default Breadcrumb;
