import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../../Common/Breadcrumb';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { Actions as PAYMENTACTIONS } from '../../../store/actions/paymentActions';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import ValidationMessage from '../../utils/ValidationMessage';
import ListPaymentMethods from '../../Payments/ListPaymentMethods';

const Settings = ({ settingsOpt, saveSettings, user, consultantAdmin }) => {
  const [editedState, setEditedState] = useState({});
  const [error, setError] = useState([]);

  const updateField = (e) => {
    const { name, value } = e.target;
    const newEditedState = { ...editedState };

    newEditedState[name] = value;
    setEditedState(newEditedState);
  };

  const handleSave = async (settings) => {
    try {
      await saveSettings(settings);
    } catch (error) {
      if (error.inner) {
        // If here, it means there are validation errors
        setError(error.inner);
      } else console.log('Error', error);
    }
  };

  return (
    <>
      <Breadcrumb items={[{ label: 'Settings', link: '/admin/settingsPage' }]} />
      {!consultantAdmin && (
        <div className='row'>
          <div className='col-lg-12'>
            <section className='panel'>
              <aside className='profile-nav alt'>
                <section className='panel'>
                  <header
                    className='panel-heading'
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Settings
                    <div style={{ display: 'flex', alignItems: 'center' }}></div>
                  </header>
                  {settingsOpt && (
                    <ul className='nav nav-pills nav-stacked'>
                      {Object.keys(settingsOpt)
                        .filter((o) => !settingsOpt[o]?.exclusiveFor || settingsOpt[o]?.exclusiveFor.includes(user))
                        .map((key, index) => {
                          const type = settingsOpt[key].type;
                          const styles = settingsOpt[key].style;
                          const value = settingsOpt[key].value;
                          return (
                            <li key={index}>
                              <a style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {typeof settingsOpt[key].icon == 'string' ? (
                                    // Fontawesome icons
                                    <i className={settingsOpt[key].icon} style={{ paddingRight: 20 }} />
                                  ) : (
                                    // MUI icons
                                    settingsOpt[key].icon
                                  )}
                                  {settingsOpt[key].title}
                                  {settingsOpt[key].tooltipText && (
                                    // In case options has some tooltip to show
                                    <Tooltip title={<p style={{ fontSize: 12 }}>{settingsOpt[key].tooltipText}</p>}>
                                      <i className='fas fa-info-circle' style={{ marginLeft: 10 }} />
                                    </Tooltip>
                                  )}
                                </div>
                                {['text', 'email', 'password'].includes(type) && (
                                  <input
                                    data-lpignore='true'
                                    disabled={settingsOpt[key].disable}
                                    className='form-control'
                                    name={key}
                                    value={editedState?.[key] || value || ''}
                                    style={{ width: '25vw', height: 48, padding: 8 }}
                                    onChange={updateField}
                                    type={type}
                                  />
                                )}
                              </a>
                              {error.length > 0 && (
                                <ValidationMessage
                                  message={_.find(error, { path: key })?.message}
                                  style={{ fontSize: 12 }}
                                  wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}
                                />
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </section>
              </aside>
            </section>
          </div>
        </div>
      )}

      <ListPaymentMethods />
    </>
  );
};

const mapStateToProps = (state) => {
  const settingsOpt = {};
  const { stripeCustomerId } = state.payment.stripe || {};
  const { supportEmail, name, liveDomain } = state.whiteLabelAdmin;
  const { cyfAdmin, whiteLabelAdmin, consultantAdmin } = state.profile.details || {};
  const user = cyfAdmin ? 'CYF' : whiteLabelAdmin ? 'White Label' : consultantAdmin ? 'Consultant' : null;
  settingsOpt.name = {
    type: 'text',
    icon: 'fa fa-user',
    title: 'Name',
    exclusiveFor: ['CYF', 'White Label'],
    value: name,
    disable: true,
  };
  settingsOpt.supportEmail = {
    type: 'text',
    icon: 'fa fa-ticket',
    title: 'Support Email',
    exclusiveFor: ['CYF', 'White Label'],
    value: supportEmail,
    disable: true,
  };
  settingsOpt.liveDomain = {
    type: 'text',
    icon: 'fa fa-globe',
    title: 'Live Domain',
    exclusiveFor: ['CYF', 'White Label'],
    value: liveDomain,
    disable: true,
  };

  return {
    settingsOpt,
    stripeCustomerId,
    consultantAdmin,
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveContract: (contract, history) => dispatch(ADMINACTIONS.saveContract(contract, history)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
