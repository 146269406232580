import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Alert } from '@mui/material';
import { ArrowDownward, Menu, FileCopy } from '@mui/icons-material';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';

const PreviewDraftContract = ({ setShow, setEdit, pandaDocId }) => {
  const editDocuent = () => {
    setShow(false);
    setEdit(true);
  };
  return (
    <div>
      <p style={{ fontSize: 16 }}>
        <span style={{ fontWeight: 'bold', fontSize: 20, color: '#D0342C' }}>IMPORTANT:</span> Preview of draft contracts is
        only available in PandaDocs. If you want to edit contract form inputs, please make changes in the current page. Click{' '}
        <a style={{ cursor: 'pointer' }} onClick={editDocuent}>
          HERE
        </a>{' '}
        to return to contract form.
      </p>
      <p style={{ fontSize: 16 }}>
        To preview and edit the text of your contract please log-in to PandaDocs{' '}
        <a style={{ cursor: 'pointer' }} href={`https://app.pandadoc.com/a/#/documents/${pandaDocId}`} target='blank'>
          HERE
        </a>{' '}
        . By clicking this link, you will be redirected to a new PandaDocs tab. In the PandaDocs app, you can edit text or
        add non-standard verbiage.{' '}
      </p>
      <Alert severity='warning' sx={{ fontSize: 16, color: '#D0342C', marginBottom: 2 }}>
        When you are done editing - DO NOT CLICK SEND - simply click the menu icon, <Menu />, in the top left corner of the
        page and select Documents, <FileCopy />. Your modifications will be saved.
      </Alert>
      <Alert severity='warning' sx={{ fontSize: 16, color: '#D0342C' }}>
        DO NOT edit the primary yellow "form input" fields in PandaDocs.
      </Alert>
    </div>
  );
};

const ContractPreview = ({ setShow, showDoc, contract, getContract, setEdit }) => {
  const { status, pandaDocId, pandaDocLinkId, id } = contract || {};
  const { show, recall } = showDoc || {};
  let source, width, right;

  const handleClose = () => {
    if (status == 'Pending Customer Signature') {
      getContract(id);
    }
    setShow((prev) => ({ ...prev, show: false, recall: false }));
  };

  if (status && ['Pending Internal Approval', 'Pending Customer Signature'].includes(status) && recall) {
    source = `https://app.pandadoc.com/a/#/documents/${pandaDocId}`;
    width = '1000';
    right = '400px';
  } else {
    source = `https://app.pandadoc.com/s/${pandaDocLinkId}`;
  }

  useEffect(() => {
    const handleMessage = async (event) => {
      const type = event.data && event.data.type;
      const payload = event.data && event.data.payload;

      switch (type) {
        case 'session_view.document.loaded':
          console.log('Session view is loaded');
          break;
        case 'session_view.document.completed':
          console.log('Document is completed');
          console.log(payload);
          break;
        case 'session_view.document.exception':
          console.log('Exception during document finalization');
          console.log(payload);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Modal open={show} onClose={handleClose} sx={{ right: right ? right : '0' }}>
      <div className='modal-dialog'>
        <div className='modal-content' style={{ width: 'fit-content' }}>
          <div className='modal-header'>
            <button onClick={handleClose} className='close'>
              x
            </button>
            <h4 className='modal-title'>Preview Document</h4>
          </div>
          <div
            className='modal-body'
            style={{ overflow: 'auto', height: status == 'Pending Customer Signature' && recall && '80vh' }}>
            <div>
              {status == 'Pending Customer Signature' && recall && (
                <div className='recall-document-instructions'>
                  <p style={{ fontWeight: 'bold', fontSize: 16 }}>Instructions - You have recalled an executed document. </p>
                  <ol>
                    <li>
                      If you can't see the contract in this window, please sign in to PandaDocs{' '}
                      <a href={`https://app.pandadoc.com/a/#/documents/${pandaDocId}`} onClick={handleClose} target='blank'>
                        HERE
                      </a>
                      .
                    </li>
                    <li>Please click the green "Actions" button in the top right and select "Edit Document".</li>
                    <li>When document shows as "Editing", then close the PandaDoc pop-up window.</li>
                    <li>
                      <strong>DO NOT </strong> edit the yellow “form input” fields in this document as they can only be
                      changed in the Contract Creation form. These fields can only be edited in CYF.
                    </li>
                    <li>
                      The current page allows edits to standard text only. Once your standard text edits are complete –{' '}
                      <strong>DO NOT</strong> click send – simply close this PandaDocs window. Your changes will be saved,
                      and you can then re-Execute the document.
                    </li>
                  </ol>
                  <ArrowDownward />
                </div>
              )}
              {!['draft', 'Pending Internal Approval'].includes(status) ? (
                <iframe src={source} width={width ? width : '570'} height='680' style={{ border: 'none' }} />
              ) : (
                <PreviewDraftContract setEdit={setEdit} setShow={setShow} pandaDocId={pandaDocId} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getContract: (id) => dispatch(ADMINACTIONS.getContract(id)),
});

export default connect(null, mapDispatchToProps)(ContractPreview);
