import React from 'react';
import { connect } from 'react-redux';

const LogoSide = ({ branding }) => {
  const { clientWebsite, name, logo } = branding;
  const { bgColor } = branding.colors || {};

  return (
    <div className='logoSide' style={{ background: bgColor }}>
      <div className='logoDescription'>
        <img
          src={logo}
          alt={name}
          style={{ maxWidth: '30vw', cursor: 'url(), pointer' }}
          onClick={() => window.open(`https://${clientWebsite}`, '_blank')}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    branding: state.branding,
  };
};
export default connect(mapStateToProps, null)(LogoSide);
