import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import Select from 'react-select';
import _ from 'lodash';
import { dottedLine, questionStyles, customStyles, headerStyles, labelStyles } from '../codeStyles';

const Method = ({ method, setMethod }) => {
  const options = [
    {
      value: 'accountcodes',
      label: 'Account Codes',
    },
    {
      value: 'trackingcategories',
      label: 'Tracking Categories',
    },
  ];

  const handleMethodChange = (selectedOption) => {
    setMethod(selectedOption.value);
  };

  const selectedMethod = _.find(options, { value: method });

  return (
    <li style={{ padding: '10px 15px' }}>
      Select how you want to categorise your expenses
      <span
        className='pull-right'
        style={{
          marginRight: '47px',
          lineHeight: '0',
        }}>
        <Select
          id='selectMethod'
          value={selectedMethod || null}
          onChange={handleMethodChange}
          options={options}
          styles={customStyles}
        />
      </span>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    method: state.method,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMethod: (method) => dispatch(ACTIONS.setMethod(method)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Method);
