import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import { Actions as ADMIN_ACTIONS } from '../../store/actions/adminActions';
import ImportFromSage from './ImportFromSage';
import { useHistory, useParams } from 'react-router';
import SageAccountCodes from './SageAccountCodes';
import SageTenants from './SageTenants';
import SaveSageSettings from './SaveSageSettings';
import './styles.css';

const SageFaq = ({ branding }) => {
  const [show, setShow] = useState(false);

  const iconStyle = {
    fontSize: '20px',
    marginLeft: '10px',
    verticalAlign: 'middle',
  };

  if (!show) {
    return (
      <a
        href='#'
        style={iconStyle}
        className='fa fa-question-circle'
        onClick={(e) => {
          e.preventDefault();
          setShow(true);
        }}></a>
    );
  }
  return (
    <div
      style={{
        padding: '40px 20px',
        background: 'white',
        position: 'relative',
        borderRadius: '5px',
      }}>
      <a
        href='#'
        className='fa fa-close'
        style={iconStyle}
        onClick={(e) => {
          e.preventDefault();
          setShow(false);
        }}></a>
      <p>
        Compare Your Footprint is now plugged in to Sage, the online accounting software, to make it even easier to report on
        your energy usage and carbon footprint.
      </p>
      <p>
        If you already use Sage, you can easily link your accounts to Compare Your Footprint and our APP does the rest! It's
        then integrated directly with your Sage accounts, so your footprint updates every time your accounting is updated!{' '}
      </p>

      <h4>To connect to Sage follow these steps:</h4>

      <ol>
        <li>Login and go to Organisations and click view/edit.</li>
        <li>Scroll down and click on Connect to Sage.</li>
      </ol>

      <p>
        Any questions? Contact{' '}
        <a className='link' href={`mailto:${branding.supportEmail}`}>
          {branding.supportEmail}
        </a>
      </p>

      <h4>Is it secure?</h4>

      <p>
        Sage is using an industry-standard top-grade solution in terms of security. This is known as oAuth 2.0. The
        authentication process is coming from Sage's side and depending on how your account is set up, and the last time you
        connected, they may or may not ask for a password and dual authentication.
      </p>

      <p>
        Compare Your Footprint obtains two tokens from the initial request, one which is valid for 30 minutes and a second
        one which is by default valid for 30 days. Every time the 30-minute token expires, we renew this using the 30-day
        token, which is at that point extended for 30 days. For this token to work, requests must be originating from within
        Compare Your Footprint with parameters that are provided by Sage. This is done so that no token is used continuously
        for a period of time increasing inherent security. Sage's own accounting app runs on the very same security
        technology behind the hood.
      </p>

      <p>Sage allows you to disconnect Compare Your Footprint at any point in time from within Sage's portal. </p>

      <p>
        All data in between Sage and Compare Your Footprint is always passed through encrypted channels and is stored within
        a database which is encrypted at rest. So, your tokens’ short or long life are not exposed nor is the data being
        transacted.{' '}
      </p>

      <p>
        Furthermore, should any breach ever be discovered in Compare Your Footprint that could potentially compromise your
        data, we are able to immediately invalid our API keys and tokens to render any remaining tokens invalid. However, if
        it is a matter of your account details being compromised, no financial data is ever exposed, as this is not even
        available to the Compare Your Footprint team to view within the backend.
      </p>

      <h4>Data Protection</h4>

      <p>
        Compare Your Footprint Limited is committed to protecting the privacy of the users of our APP. We are the sole owner
        of the information collected via the APP. We will not sell, share, or rent this information to others in ways
        different from those disclosed in this statement.
      </p>

      <p>
        Please take time to read our <a href='https://compareyourfootprint.com/privacy-policy/'>Privacy Policy</a>.
      </p>
    </div>
  );
};

const SageApp = ({
  isConnectedToSage,
  connected,
  sageLoginUrl,
  sageCallBack,
  branding,
  organisationId,
  getSageOrganisationSettings,
  xeroConnected,
  toggleModal,
  currentTenant,
  showLoader,
}) => {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if ((window.location.pathname == '/sage/callback' && !connected) || window.location.href.includes('?test=true')) {
      sageCallBack(history);
    } else {
      isConnectedToSage();
    }
  }, [id]);

  useEffect(() => {
    if (connected) {
      getSageOrganisationSettings();
    }
  }, [connected]);
  return (
    <>
      <div>
        {!xeroConnected && (
          <div className='App'>
            {!connected && (
              <a
                className='App-link btn btn-success integration-btn'
                id='connectSage'
                href={String(sageLoginUrl)}
                onClick={() => localStorage.setItem('organisation', organisationId)}>
                Connect to Sage
              </a>
            )}
            {connected && (
              <div>
                <section className='panel'>
                  <aside className='profile-nav alt'>
                    <section className='panel'>
                      <header className='panel-heading'>
                        Configure your Sage Integration Account Codes for{' '}
                        <u id='configureSage'>{currentTenant?.tenantName}</u>
                      </header>
                      <ul className='nav nav-pills nav-stacked'>
                        <SageTenants />
                        <SageAccountCodes />
                      </ul>
                    </section>
                  </aside>
                </section>

                <SaveSageSettings />
                <ImportFromSage />

                <a
                  id='disconnectSage'
                  className='App-link btn btn-danger integration-btn'
                  style={{ margin: 10 }}
                  onClick={() => {
                    toggleModal(true, 'disconnect-sage', 'sage', null);
                  }}>
                  Disconnect from SAGE
                </a>
              </div>
            )}

            <SageFaq branding={branding} />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const currentTenant = state?.tenants?.find((tenant) => tenant.tenantId == state?.tenant);
  return {
    connected: state?.sageConnected,
    xeroConnected: state?.connected,
    sageLoginUrl: state?.sageLoginUrl,
    currentTenant,
    session: state?.session,
    branding: state?.branding,
    organisationId: state?.organisation?.details?.id,
    showLoader: state?.showLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  isConnectedToSage: () => dispatch(ACTIONS.isConnectedToSage()),
  sageCallBack: (history) => dispatch(ACTIONS.sageCallBack(history)),
  getSageOrganisationSettings: () => dispatch(ACTIONS.getSageOrganisationSettings()),
  toggleModal: (status, action, item, modalProps) => dispatch(ADMIN_ACTIONS.toggleModal(status, action, item, modalProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SageApp);
