import React from 'react';
import { connect } from 'react-redux';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { AddButton } from '../../styledComponents/GlobalStyle';

const ImportModal = ({
  importSuccesses,
  importErrors,
  showAnalysisImportModal,
  importModal,
  lastRowNumber,
  templateError,
  errorLink,
}) => {
  if (!importModal) return null;

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button onClick={() => showAnalysisImportModal(false, null)} type='button' className='close'>
              x
            </button>
            <h4 className='modal-title'>Import Analysis</h4>
          </div>
          <div className='modal-body'>
            {importSuccesses >= 0 && (
              <div className='form-group'>
                <div id='import-link'>
                  {lastRowNumber == 2 && importSuccesses < 1 && importErrors.length < 1 && (
                    <p className='sucess-imports' style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'red' }}>
                      No rows imported due to blank first row in import template.
                    </p>
                  )}
                  {importSuccesses > 0 && (
                    <p className='sucess-imports' style={{ fontSize: '1.2em', fontWeight: 'bold', color: '#60B12F' }}>
                      {importSuccesses} {importSuccesses > 1 ? 'rows' : 'row'} imported sucessfully
                    </p>
                  )}

                  {templateError && (
                    <p className='sucess-imports' style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'red' }}>
                      {templateError}
                    </p>
                  )}
                </div>
              </div>
            )}

            {importErrors?.length > 0 && (
              <div className='form-group'>
                <div id='import-link'>
                  <p className='error-imports' style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'red' }}>
                    {importErrors.length > 1
                      ? `Encountered ${importErrors.length} errors on the following rows:`
                      : 'Encountered 1 error on the following row:'}
                  </p>
                  {importErrors.map((item, key) => (
                    <div style={{ marginTop: 5, marginLeft: 5 }} key={key}>
                      <span style={{ fontWeight: 'bold', fontSize: '1em' }}>Row {item.row}:</span>
                      <span> {item.err}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {errorLink && (
              <div className='form-group'>
                <div id='import-link'>
                  <p className='error-imports' style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'red' }}>
                    Received more than 10 errors: Please download the <a href={errorLink}>error file</a> to view all errors.
                  </p>
                </div>
              </div>
            )}

            <div className='row block buttons' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              {errorLink && (
                <AddButton className='btn btn-success' bordercolor='true' style={{ marginRight: 20 }}>
                  <a href={errorLink}>Download Error File</a>
                </AddButton>
              )}

              <AddButton
                onClick={() => showAnalysisImportModal(false, null)}
                className='btn btn-success'
                bordercolor='true'
                style={{ marginRight: 20 }}>
                Close
              </AddButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { importModal } = state;
  return {
    processingUsageImport: state.processingImport?.status,
    importSuccesses: importModal?.importSuccesses,
    importErrors: importModal?.importErrors,
    errorLink: importModal?.errorLink,
    templateError: importModal?.templateError,
    importModal,
    lastRowNumber: importModal?.lastRowNumber,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showAnalysisImportModal: (status, payload) => dispatch(USAGEACTIONS.showAnalysisImportModal(status, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
