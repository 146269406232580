import React from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import Select from 'react-select';
import _ from 'lodash';
import { customStyles } from '../codeStyles';

const SageTenants = ({ sageTenants, sageTenant, saveSageTenant }) => {
  if (!sageTenants) {
    return false;
  }

  const options = sageTenants.map((tenant) => ({
    value: tenant.tenantId,
    label: tenant.tenantName,
  }));

  const handleTenantChange = (selectedOption) => {
    saveSageTenant(selectedOption.value);
  };

  const selectedTenant = _.find(options, { value: sageTenant });
  if (sageTenants.length <= 1) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <li style={{ padding: '10px 15px' }}>
      Select Sage Business
      <span
        className='pull-right'
        style={{
          marginRight: '47px',
          lineHeight: '0',
        }}>
        <Select value={selectedTenant} onChange={handleTenantChange} options={options} styles={customStyles} />
      </span>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    sageTenants: state?.tenants,
    sageTenant: state?.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveSageTenant: (tenant) => dispatch(ACTIONS.saveSageTenant(tenant)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SageTenants);
