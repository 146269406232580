import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import gfm from 'remark-gfm';
import * as yup from 'yup';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';

import { AuthBtn } from '../../styledComponents/GlobalStyle';

const UserLicenseAgreementModal = ({
  branding,
  confirmUserAgreement,
  userLicenseAgreementConfirmed,
  userLicenseAgreement,
  privacyPolicy,
  isWhiteLabelApp,
  showWhiteLabelStatement,
  whiteLabelClientName,
}) => {
  const [validationError, setValidationError] = useState(null);
  const [userLicenseConfirmed, setUserLicenseConfirmed] = useState(false);

  let validationSchema = yup.object().shape({
    userLicenseConfirmed: yup
      .boolean()
      .oneOf([true], 'Accepting the User License Agreement and Privacy Policy is required')
      .required(),
  });

  const errorsToDisplay = {};

  validationError?.map((error) => {
    if (error.path === 'userLicenseConfirmed') {
      errorsToDisplay['userLicenseConfirmed'] = error.message;
    }
  });

  const confirmUserLicenseAgreement = async () => {
    await validationSchema
      .validate(
        {
          userLicenseConfirmed: userLicenseConfirmed,
          softwareLicenseConfirmed: true,
        },
        { abortEarly: false }
      )
      .then(async () => {
        confirmUserAgreement(false);
      })
      .catch((error) => {
        console.log('ValidationError', error);
        setValidationError(error.inner);
      });
  };

  if (userLicenseAgreementConfirmed !== undefined && userLicenseAgreementConfirmed == false && userLicenseAgreement) {
    //Confirming User Agreements

    return (
      <React.Fragment>
        <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)', zIndex: 2000000 }}>
          <div
            className='modal-dialog userLicenseAgreementModal'
            style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>User License Agreement and Privacy Policy</h4>
              </div>
              <div className='modal-body'>
                <div style={{ textAlign: 'justify', color: 'black' }}>
                  {showWhiteLabelStatement && <p>Powered by Compare Your Footprint Ltd for {whiteLabelClientName}.</p>}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {!isWhiteLabelApp && (
                      <ReactMarkdown>
                        ![ScreenShot](https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/branding/compareyourfootprint/Picture+1.jpg)
                      </ReactMarkdown>
                    )}
                  </div>
                  <p
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: 14,
                      display: 'inline-block',
                      backgroundColor: branding && branding.colors && branding.colors.primaryColor,
                      padding: 5,
                      borderRadius: 4,
                    }}>
                    Please review these agreements and click to accept to continue.
                  </p>
                  <ReactMarkdown remarkPlugins={[gfm]} className={'userAgreement-text'} children={userLicenseAgreement} />
                  <ReactMarkdown
                    remarkPlugins={[gfm]}
                    className={'privacyPolicy-text privacy-policy-text'}
                    children={privacyPolicy}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '12px',
                    color: '#333',
                    margin: 10,
                    marginTop: 20,
                    backgroundColor: 'white',
                  }}>
                  <div id='accept-ula-checkbox' style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type='checkbox'
                      style={{ marginBottom: 10 }}
                      onChange={() => setUserLicenseConfirmed(!userLicenseConfirmed)}
                    />
                    <label style={{ fontWeight: 400, marginBottom: 7, marginLeft: 5 }}>
                      By clicking you are accepting to our <strong>User License Agreement</strong> and{' '}
                      <strong>Privacy Policy</strong>.
                    </label>
                  </div>

                  {errorsToDisplay && errorsToDisplay.userLicenseConfirmed && (
                    <div>
                      <p style={{ textAalign: 'left', color: 'red', fontSize: '11px', fontWeight: 700, marginTop: '-10px' }}>
                        {errorsToDisplay.userLicenseConfirmed}.
                      </p>
                    </div>
                  )}

                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBotton: 20 }}>
                    <AuthBtn
                      className='btn btn-lg btn-login btn-block onceonly'
                      bgcolor
                      type
                      style={{ color: 'white', width: 'fit-content', fontSize: 16, padding: '10 10' }}
                      onClick={(e) => {
                        e.preventDefault();
                        confirmUserLicenseAgreement();
                      }}>
                      Confirm
                    </AuthBtn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return null;
};

const mapDispatchToProps = (dispatch) => ({
  confirmUserAgreement: (inclOrg) => dispatch(AUTHENTICATION_ACTIONS.confirmUserAgreement(inclOrg)),
});

const mapStateToProps = (state) => {
  const licenses = state.licenses;

  const userLicenseAgreement =
    licenses && Array.isArray(licenses) && licenses.find((license) => license.type === 'User License Agreement').text;
  const privacyPolicy =
    licenses && Array.isArray(licenses) && licenses.find((license) => license.type === 'Privacy Policy').text;

  const whitelabelClient = state.whiteLabelClient;
  let showWhiteLabelStatement = false;
  let whiteLabelClientName = '';

  switch (whitelabelClient) {
    case 'gogreenexperts':
      showWhiteLabelStatement = true;
      whiteLabelClientName = 'Go Green Experts Ltd';
      break;
    default:
      showWhiteLabelStatement = false;
  }

  return {
    userLicenseAgreement,
    privacyPolicy,
    userLicenseAgreementConfirmed: state?.profile?.details?.legalAgreements?.userLicenseAgreementConfirmed,
    branding: state.branding,
    isWhiteLabelApp: state.isWhiteLabelApp,
    showWhiteLabelStatement,
    whiteLabelClientName,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLicenseAgreementModal);
