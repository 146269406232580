import React from 'react';
import { connect } from 'react-redux';
import { RowBtn } from '../../styledComponents/GlobalStyle';
import _ from 'lodash';

const OrganisationsRow = ({ organisationDetails, industries }) => {
  return (
    <tr>
      <td id='organisation-name' width='20%' style={{ textAlign: 'left' }}>
        {organisationDetails.name}
      </td>
      <td width='20%'>{_.find(industries, { id: organisationDetails?.industry })?.name}</td>
      <td width='20%'>{organisationDetails.currency}</td>
      <td>
        <RowBtn className='view-edit-button' to={`/organisations/${organisationDetails.id}`}>
          View/Edit
        </RowBtn>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    industries: state.organisation?.industries,
  };
};

export default connect(mapStateToProps, null)(OrganisationsRow);
