import React from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';

const SaveSageSettings = ({ saveSageOrganisationSettings, selectedAccounts }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '60px' }}>
      <div
        className='btn btn-success'
        id='saveSageSettings'
        disabled={!selectedAccounts}
        style={{ marginTop: '10px' }}
        onClick={() => {
          saveSageOrganisationSettings({ selectedAccounts, type: 'sage' });
        }}>
        Save Sage Settings
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedAccounts: state.selectedAccounts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveSageOrganisationSettings: (settings) => dispatch(ACTIONS.saveSageOrganisationSettings(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveSageSettings);
