import { Types } from '../actions/paymentActions';

const defaultState = {};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.STRIPE_CREATE_CHARGE: {
      const newState = { ...state };

      newState.payment.stripe.processingCharge = false;

      if (action.payload.invoiceNo) {
        newState.payment.invoiceNo = action.payload.invoiceNo;
      }

      if (action.payload.paymentIntent == 'success') {
        newState.payment.modal = false;
      } else {
        newState.payment.stripe.paymentIntent = action.payload.paymentIntent;
      }

      console.log('actionCREATECHARTE', action);
      // newState.payment.selectedBundle = action.bundle

      return newState;
    }

    case Types.PAYMENT_SELECT_PRODUCT: {
      const newState = { ...state };

      newState.payment.selectedProduct = action.product;

      return newState;
    }
    case Types.PAYMENT_SELECT_PRICE: {
      const newState = { ...state };

      newState.payment.selectedPrice = action.price;

      return newState;
    }

    case Types.STRIPE_INTENT: {
      const newState = { ...state };

      newState.payment.stripe.intent = action.payload;

      return newState;
    }

    case Types.STRIPE_UPDATE_SUBSCRIPTION: {
      const newState = { ...state };

      newState.payment.subscription = [{ ...action.payload }];

      return newState;
    }

    case Types.PAYMENT_SUBSCRIPTION_PLAN: {
      const newState = { ...state };

      newState.payment.subscriptionPlan = action.subscriptionPlan;

      return newState;
    }

    case Types.PAYMENT_SHOW_MODAl: {
      const newState = { ...state };

      newState.payment.subscriptionSucessfull = action.status;

      return newState;
    }

    case Types.PAYMENT_BILLING_DETAILS: {
      const newState = { ...state };

      newState.payment.billingDetails = action.billingDetails;

      return newState;
    }

    case Types.STRIPE_GETTING_COUPON: {
      const newState = { ...state };

      newState.payment.gettingCoupon = action.status;

      return newState;
    }

    case Types.STRIPE_COUPON: {
      const newState = { ...state };

      const { stripeCoupon } = action || null;

      newState.payment.stripeCoupon = stripeCoupon
        ? { ...stripeCoupon.coupon, id: stripeCoupon.id }
        : { message: 'Invalid coupon' };
      newState.payment.gettingCoupon = false;

      return newState;
    }

    case Types.STRIPE_CREATE_SUBSCRIPTION: {
      const newState = { ...state };

      //Reseting the values used when creating the subscription
      newState.profile.registrationDetails.user = {};
      newState.profile.registrationDetails.organisation = {};

      return newState;
    }

    case Types.STRIPE_LIST_INVOICES: {
      const newState = { ...state };

      const { invoices, loader } = action.payload?.invoices;

      newState.payment.stripe.gettingInvoices = loader;
      newState.payment.stripe.invoices = invoices;

      return newState;
    }

    case Types.SET_STRIPE_CUSTOMER_ID: {
      const newState = { ...state };

      if (action.response) {
        newState.payment.stripe.stripeCustomerId = action.response;
      } else {
        newState.payment.stripe.stripeSubscriptions = [];
        newState.payment.stripe.stripeCustomerId = action.response;
      }

      return newState;
    }

    case Types.LIST_STRIPE_PAYMENT_METHODS: {
      const newState = { ...state };
      const { loader, paymentMethods: { paymentMethods, defaultPaymentMethod, subscriptions } = {} } = action || {};
      newState.payment.stripe.stripePaymentMethods = { ...newState.payment.stripe.stripePaymentMethods } || {};
      newState.payment.stripe.stripePaymentMethods = {
        // When there's no payment methods, action.paymentMethods is an empty array.
        paymentMethods: paymentMethods || action.paymentMethods,
        defaultPaymentMethod: defaultPaymentMethod,
        loader: loader,
      };
      newState.payment.stripe.stripeSubscriptions = { ...newState.payment.stripe.stripeSubscriptions } || {};
      newState.payment.stripe.stripeSubscriptions = subscriptions?.data;

      return newState;
    }

    case Types.UPDATE_STRIPE_SUBSCRIPTION: {
      const newState = { ...state };
      const { subscriptions, defaultPaymentMethod } = action.response || {};

      if (defaultPaymentMethod) {
        newState.payment.stripe.stripePaymentMethods = { ...newState.payment.stripe.stripePaymentMethods } || {};
        newState.payment.stripe.stripePaymentMethods.defaultPaymentMethod = defaultPaymentMethod;
      }

      newState.payment.stripe.stripeSubscriptions = { ...newState.payment.stripe.stripeSubscriptions } || {};
      newState.payment.stripe.stripeSubscriptions = subscriptions?.data;

      return newState;
    }

    case Types.UPDATE_PRICING_BANDS: {
      const newState = { ...state };
      const { pricingBands } = action || {};

      newState.admin.adminPortal.pricingBands = [...newState.admin.adminPortal.pricingBands] || {};

      const index = newState.admin.adminPortal.pricingBands.findIndex((x) => x.id == pricingBands.id);

      if (index < 0) {
        // New pricing band
        if (pricingBands.result) delete pricingBands.result;
        newState.admin.adminPortal.pricingBands.push(pricingBands);
      } else {
        // Update pricing band
        newState.admin.adminPortal.pricingBands[index] = pricingBands;
      }

      return newState;
    }

    case Types.LIST_STRIPE_SUBSCRIPTIONS: {
      const newState = { ...state };
      const { subscriptions } = action || {};

      newState.payment.stripe.stripeSubscriptions = { ...newState.payment.stripe.stripeSubscriptions } || {};
      newState.payment.stripe.stripeSubscriptions = subscriptions;

      return newState;
    }

    case Types.LIST_STRIPE_PRICING_TIERS: {
      const newState = { ...state };
      newState.payment.stripe.annualPricingTiers = action.response?.['Annual Plan'];
      if (action.response?.['Unlimited Plan']) {
        newState.payment.stripe.unlimitedPricingTiers = action.response['Unlimited Plan'];
      }

      return newState;
    }

    default:
      return state;
  }
};

export default reducer;
