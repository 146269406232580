import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import ACTIONS from '../../store/action';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import softwareLicenseAgreement from '../Register/SoftwareLicenseAgreement';
import privacyPolicy from '../Register/PrivacyPolicy';
import * as yup from 'yup';

import { AuthBtn } from '../../styledComponents/GlobalStyle';

const OrgAgreementModal = ({
  branding,
  confirmUserAgreement,
  userLicenseAgreement,
  softwareLicenseAgreementConfirmed,
  showFooterAgreements,
  isFooterAgreementOpen,
  legalAgreement,
  isWhiteLabelApp,
  wlSettings,
  //privacyPolicy,
  //softwareLicenseAgreement,
}) => {
  const { primaryColor } = branding.colors || {};
  const { name } = wlSettings || {};
  const [validationError, setValidationError] = useState(null);
  const [softwareLicenseConfirmed, setSoftwareLicenseConfirmed] = useState(false);

  let validationSchema = yup.object().shape({
    softwareLicenseConfirmed: yup
      .boolean()
      .oneOf([true], 'Accepting the Software License Agreement and Privacy Policy is required')
      .required(),
  });

  const errorsToDisplay = {};

  validationError &&
    validationError.map((error) => {
      if (error.path === 'softwareLicenseConfirmed') {
        errorsToDisplay['softwareLicenseConfirmed'] = error.message;
      }
    });

  const confirmOrgAgreements = async () => {
    await validationSchema
      .validate(
        {
          softwareLicenseConfirmed: softwareLicenseConfirmed,
          userLicenseConfirmed: true,
        },
        { abortEarly: false }
      )
      .then(async () => {
        confirmUserAgreement(true);
      })
      .catch((error) => {
        console.log('ValidationError', error);
        setValidationError(error.inner);
      });
  };

  if (softwareLicenseAgreementConfirmed === false) {
    //Confirming Org Agreements

    return (
      <React.Fragment>
        <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)', zIndex: 2000000 }}>
          <div
            className='modal-dialog userLicenseAgreementModal'
            style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>Software License Agreement and Privacy Policy</h4>
              </div>
              <div className='modal-body'>
                <div style={{ textAlign: 'justify', color: 'black' }}>
                  {isWhiteLabelApp && (
                    <div>
                      <p>Powered by Compare Your Footprint Ltd for {name}.</p>
                    </div>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {!isWhiteLabelApp && (
                      <ReactMarkdown>
                        ![ScreenShot](https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/branding/compareyourfootprint/Picture+1.jpg)
                      </ReactMarkdown>
                    )}
                  </div>
                  <p
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: 14,
                      display: 'inline-block',
                      backgroundColor: primaryColor,
                      padding: 5,
                      borderRadius: 4,
                    }}>
                    Please review these agreements and click to accept to continue.
                  </p>
                  <ReactMarkdown
                    remarkPlugins={[gfm]}
                    className={'userAgreement-text'}
                    children={softwareLicenseAgreement()}
                  />
                  <ReactMarkdown
                    remarkPlugins={[gfm]}
                    className={'privacyPolicy-text privacy-policy-text'}
                    children={privacyPolicy()}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '12px',
                    color: '#333',
                    margin: 10,
                    marginTop: 20,
                    backgroundColor: 'white',
                  }}>
                  <div id='accept-ula-checkbox' style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type='checkbox'
                      style={{ marginBottom: 10 }}
                      onChange={() => setSoftwareLicenseConfirmed(!softwareLicenseConfirmed)}
                    />
                    <label style={{ fontWeight: 400, marginBottom: 7, marginLeft: 5 }}>
                      By clicking you are accepting to our <strong>Software License Agreement</strong> and{' '}
                      <strong>Privacy Policy</strong>.
                    </label>
                  </div>

                  {errorsToDisplay && errorsToDisplay.softwareLicenseConfirmed && (
                    <div>
                      <p style={{ textAalign: 'left', color: 'red', fontSize: '11px', fontWeight: 700, marginTop: '-10px' }}>
                        {errorsToDisplay.softwareLicenseConfirmed}.
                      </p>
                    </div>
                  )}

                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBotton: 20 }}>
                    <AuthBtn
                      className='btn btn-lg btn-login btn-block onceonly'
                      type
                      style={{ color: 'white', width: 'fit-content', fontSize: 16, padding: '10 10' }}
                      onClick={() => confirmOrgAgreements()}>
                      Confirm
                    </AuthBtn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (isFooterAgreementOpen) {
    // Modals to show just for white labels when click on the Terms & Conditions
    // Or Privacy Policy from the Footer
    return (
      <React.Fragment>
        <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
          <div
            className='modal-dialog userLicenseAgreementModal'
            style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
            <div className='modal-content'>
              <div className='modal-header'>
                <button onClick={() => showFooterAgreements(false, null)} type='button' className='close'>
                  x
                </button>
                <h4 className='modal-title'>
                  {legalAgreement == 'tncs' && `Software License Agreement and User License Agreement`}
                  {legalAgreement == 'privacyPolicy' && `Privacy Policy`}
                </h4>
              </div>
              <div className='modal-body'>
                <div style={{ textAlign: 'justify', color: 'black' }}>
                  {legalAgreement == 'tncs' && (
                    <>
                      <br />
                      <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Software License Agreement and User License Agreement
                      </h4>
                      <br />

                      {isWhiteLabelApp && <p>Powered by Compare Your Footprint Ltd for {name}.</p>}

                      <>
                        <ReactMarkdown
                          remarkPlugins={[gfm]}
                          className={'userAgreement-text'}
                          children={softwareLicenseAgreement()}
                        />
                        <ReactMarkdown
                          remarkPlugins={[gfm]}
                          className={'userAgreement-text'}
                          children={userLicenseAgreement}
                        />
                      </>
                    </>
                  )}

                  {legalAgreement == 'privacyPolicy' && (
                    <>
                      {isWhiteLabelApp && (
                        <div>
                          <p style={{ position: 'absolute', top: 70 }}>Powered by Compare Your Footprint Ltd for {name}.</p>
                        </div>
                      )}

                      <ReactMarkdown
                        remarkPlugins={[gfm]}
                        className={'privacyPolicy-text privacy-policy-text'}
                        children={privacyPolicy()}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return null;
};

const mapDispatchToProps = (dispatch) => ({
  confirmUserAgreement: (inclOrg) => dispatch(AUTHENTICATION_ACTIONS.confirmUserAgreement(inclOrg)),
  showFooterAgreements: (status, agreement) => dispatch(ACTIONS.showFooterLegalAgreements(status, agreement)),
});

const mapStateToProps = (state) => {
  const licenses = state.licenses;
  const userLicenseAgreement =
    licenses && Array.isArray(licenses) && licenses.find((license) => license.type === 'User License Agreement').text;
  /* const privacyPolicy =
    licenses && Array.isArray(licenses) && licenses.find((license) => license.type === 'Privacy Policy').text; */
  /* const softwareLicenseAgreement =
      licenses && Array.isArray(licenses) && licenses.find((license) => license.type === 'Software License Agreement').text; */

  const isFooterAgreementOpen = state.footerAgreements?.status;
  const legalAgreement = state.footerAgreements?.agreement;

  return {
    //softwareLicenseAgreement,
    //privacyPolicy,
    isWhiteLabelApp: state.isWhiteLabelApp,
    wlSettings: state.whiteLabelAdmin,
    userLicenseAgreement,
    softwareLicenseAgreementConfirmed: state?.profile?.details?.legalAgreements?.softwareLicenseAgreementConfirmed,
    branding: state.branding,
    isFooterAgreementOpen,
    legalAgreement,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgAgreementModal);
