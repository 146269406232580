import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import { hexToRgb } from './GlobalFunctions';

const style = {
  width: '100%',
  maxWidth: 300,
  borderRadius: '15px',
  fontSize: 15,
  maxHeight: 150,
  textAlign: 'center',
};

const AlertMUI = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert className='Test Alert' elevation={6} ref={ref} variant='filled' {...props} />;
});

const Alert = ({ type, message, open, hideDuration = 2500, close, toggleAlertMessage, branding }) => {
  
  if (!open) return null;

  const { primaryColor } = branding.colors;
  const primaryColorToRGB = hexToRgb(primaryColor);
  const { r, g, b } = primaryColorToRGB;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    if (close) {
      close(false, null);
    }
    toggleAlertMessage(false, null);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={hideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <AlertMUI
        className='alert-mui'
        onClose={handleClose}
        severity={type}
        sx={{ ...style, background: type == 'success' ? `rgba(${r},${g},${b},0.9)` : 'rgba(255,0,0, 0.9)' }}>
        {message}
      </AlertMUI>
    </Snackbar>
  );
};

const mapStateToProps = (state) => {
  const { message, hideDuration, status, severity } = state.alertMessage || {};
  return {
    branding: state.branding,
    message,
    hideDuration,
    open: status,
    type: severity,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleAlertMessage: (status, message) => dispatch(ACTIONS.toggleAlertMessage(status, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
