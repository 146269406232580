import { Link } from 'react-router-dom';
import styled from 'styled-components';

// H1 Headeres - Primary Headers
export const PrimaryHeaders = styled.h1.attrs((props) => ({
  className: props.className,
}))`
  color: ${(props) => props.color && props.theme.colors?.primaryHeaderText};
  font-size: ${(props) => props.fontSize && props.theme.fonts?.[0].size.find((size) => size === '2.3em')};
  font-weight: ${(props) => props.fontWeight && props.theme.fonts?.[0].weight.find((weight) => weight === 900)};
  text-align: ${(props) => (props.textAlign ? 'center' : props.textAlign)};
  background-color: ${(props) => (props.bgcolor ? props.theme.colors?.primaryHeaderBackground : '')};
  text-transform: ${(props) => (props.textTransform ? 'uppercase' : props.textTransform)};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;

// H2 Headers - Secondary Headers
export const SecondaryHeaders = styled.h2.attrs((props) => ({
  className: props.className,
}))`
  color: ${(props) => props.theme.colors?.primaryHeaderText};
  font-size: ${(props) => props.fontSize && props.theme.fonts?.[0].size.find((size) => size === '2em')};
  font-weight: ${(props) => props.theme.fonts?.[0].weight.find((weight) => weight === 700)};
  text-align: ${(props) => (props.textAlign ? 'center' : props.textAlign)};
  background-color: ${(props) => props.theme.colors?.primaryHeaderBackground};
  text-transform: ${(props) => (props.textTransform ? 'uppercase' : props.textTransform)};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  border-bottom: ${(props) => (props.borderBottom ? `10px solid ${(props) => props.theme.colors?.secondaryColor}` : '')};
  font-family: ${(props) => props.theme.fonts?.[0].font.headers};
  border-radius: 2vh 2vh 0 0;
`;

// H3 Headeres - SubHeaders
export const SubHeaders = styled.h3.attrs((props) => ({
  className: props.className,
}))`
  color: ${(props) => props.color && 'white'};
  font-size: ${(props) => props.fontSize && props.theme.fonts?.[0].size.find((size) => size === '1.1em')};
  font-weight: ${(props) => props.fontWeight && props.theme.fonts?.[0].weight.find((weight) => weight === 500)};
  text-align: ${(props) => (props.textAlign ? 'center' : props.textAlign)};
  background-color: ${(props) => (props.bgcolor ? props.theme.colors?.primaryColor : '')};
  text-transform: ${(props) => (props.textTransform ? 'uppercase' : props.textTransform)};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;

// H4 Headeres - Quotations
export const Quotations = styled.h4.attrs((props) => ({
  className: props.className,
}))`
  color: ${(props) => props.color && 'white'};
  font-size: ${(props) => props.fontSize && props.theme.fonts?.[0].size.find((size) => size === '1.1em')};
  font-weight: ${(props) => props.fontWeight && props.theme.fonts?.[0].weight.find((weight) => weight === 400)};
  font-style: ${(props) => (props.fontStyle ? props.theme.fonts?.[0].style : '')};
  text-align: ${(props) => (props.textAlign ? 'center' : props.textAlign)};
  background-color: ${(props) => (props.bgcolor ? props.theme.colors?.primaryColor : '')};
  text-transform: ${(props) => (props.textTransform ? 'uppercase' : props.textTransform)};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;

//Navbar

export const BrandLogo = styled.div.attrs((props) => ({
  className: props.className,
}))`
  background-color: ${(props) => props.theme.colors?.mainLogoBackground};
`;

//Burger Menu Navbar

export const SidebarToogleBox = styled.div.attrs((props) => ({
  className: props.className,
}))`
  background-color: ${(props) => props.theme.colors?.menuToggleBackground};

  &:hover {
    background-color: ${(props) => props.theme.colors?.menuToggleBackgroundHover};
  }

  &&& div {
    color: ${(props) => props.theme.colors?.hamburgerMenuIcon};

    &:hover {
      color: ${(props) => props.theme.colors?.hamburgerMenuIconHover};
    }
  }
`;

export const SidebarInfoToogleBox = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &&& div {
    color: ${(props) => props.theme.colors?.informationBtnBackground};
    &:hover {
      color: ${(props) => props.theme.colors?.informationBtnBackgroundHover};
    }
  }
`;

export const SidebarLink = styled(Link)`
  font-family: ${(props) => props.theme.fonts?.[0].font.body};

  &&& {
    color: ${(props) => props.theme.colors?.sidebarLinkColor};
  }

  &&&:focus {
    color: ${(props) => props.theme.colors?.sidebarLinkFocus};
    background-color: ${(props) => props.theme.colors?.sidebarLinkBackgroundFocus};

    i {
      color: ${(props) => props.theme.colors?.sidebarLinkFocus};
    }
  }

  &&&:hover {
    color: ${(props) => props.theme.colors?.sidebarLinkHover};
    background-color: ${(props) => props.theme.colors?.sidebarLinkBackgroundHover};

    i {
      color: ${(props) => props.theme.colors?.sidebarLinkHover};
    }
  }
`;

export const SidebarMailto = styled.a.attrs((props) => ({
  className: props.className,
}))`
  font-family: ${(props) => props.theme.fonts?.[0].font.body};

  &&& {
    color: ${(props) => props.theme.colors?.sidebarLinkColor};
  }

  &&&:hover {
    color: ${(props) => props.theme.colors?.sidebarLinkHover};
    background-color: ${(props) => props.theme.colors?.sidebarLinkBackgroundHover};

    i {
      color: ${(props) => props.theme.colors?.sidebarLinkHover};
    }
  }

  &&&:focus {
    color: ${(props) => props.theme.colors?.sidebarLinkFocus};

    i {
      color: ${(props) => props.theme.colors?.sidebarLinkFocus};
    }
  }
`;

/*Dashboard*/

export const DashboardIcon = styled.span.attrs((props) => ({
  className: props.classNameprimaryLinkText,
}))`
  background-color: ${(props) => props?.bgcolor};
`;

export const DashboardButton = styled(Link)`
  background-color: ${(props) => props.bgcolor};
  font-family: ${(props) => props.fontFamily};
  padding: 1vmin 1vmin;
  color: ${(props) => props.color};
  font-weight: 600;
  &&&:hover {
    background-color: ${(props) => props.bgcolorhover};
    color: white;
  }
`;

//BreadCrumbs

export const BreadCrumbs = styled.ul.attrs((props) => ({
  className: props.className,
}))`
  a {
    color:  color: ${(props) => props.color && props.theme.colors?.breadCrumbsText};
    &:after {
      border-bottom: 1.5em solid rgba(0,0,0,0);
      border-left: 1em solid #E0E1E7;
      border-top: 1.5em solid rgba(0,0,0,0);
      content: "";
      margin-top: -1.5em;
      position: absolute;
      right: -1em;
      top: 50%;
      transition: all 0.3s ease-in-out 0s;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors?.breadCrumbsBackground};
      color: ${(props) => props.theme.colors?.breadCrumbsText};

      &:before {
        border-color: ${(props) => props.theme.colors?.breadCrumbsBackground} ${(props) =>
  props.theme.colors?.breadCrumbsBackground} ${(props) => props.theme.colors?.breadCrumbsBackground} rgba(0,0,0,0);
        border-style: solid;
        border-width: 1.5em 0 1.7em 1em;
        content: "";
        left: -1em;
        margin-top: -1.6em;
        position: absolute;
        top: 50%;
        transition: all 0.3s ease-in-out 0s;
      }

      &:after {
        border-bottom: 1.5em solid rgba(0,0,0,0);
        border-left: 1em solid ${(props) => props.theme.colors?.breadCrumbsBackground};
        border-top: 1.5em solid rgba(0,0,0,0);
        content: "";
        margin-top: -1.5em;
        position: absolute;
        right: -1em;
        top: 50%;
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }
    }
  }

  a.current {
    background-color:${(props) => props.theme.colors?.breadCrumbsBackground};
    color: ${(props) => props.theme.colors?.breadCrumbsText};

      &:before {
        border-color: ${(props) => props.theme.colors?.breadCrumbsBackground} ${(props) =>
  props.theme.colors?.breadCrumbsBackground} ${(props) => props.theme.colors?.breadCrumbsBackground} rgba(0,0,0,0);
        border-style: solid;
        border-width: 1.5em 0 1.7em 1em;
        content: "";
        left: -1em;
        margin-top: -1.6em;
        position: absolute;
        top: 50%;
        transition: all 0.3s ease-in-out 0s;
      } 

      &:after {
        border-bottom: 1.5em solid rgba(0,0,0,0);
        border-left: 1em solid ${(props) => props.theme.colors.breadCrumbsBackground};
        border-top: 1.5em solid rgba(0,0,0,0);
        content: "";
        margin-top: -1.5em;
        position: absolute;
        right: -1em;
        top: 50%;
        transition: all 0.3s ease-in-out 0s;
      }
  }
`;

// Add Facilities, Organisations, Usage Data, Reports, Metric

export const AddButton = styled.button.attrs((props) => ({
  className: props.className || 'btn btn-success',
  id: props.id,
  style: props.style,
  onClick: props.onClick,
  borderColor: props.borderColor ? props.borderColor : props.theme.colors?.primaryColor,
}))`
  background-color: ${(props) => props.theme.colors?.primaryButton};
  border: ${(props) => (props.border ? props.border : 'none')};
  font-family: ${(props) => props.theme.fonts?.[0].font.body};
  color: ${(props) => (props.id?.includes('cancel') ? '#FFFFFF' : props.theme.colors?.btnTextColor)};
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.theme.colors?.buttonHover};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors?.secondaryColor};
  }
`;

// Usage Data Guide Button
export const UsageDataGuide = styled.a.attrs((props) => ({
  className: props.className,
  style: props.style,
  onClick: props.onClick,
}))`
  background-color: ${(props) => props.theme.colors?.primaryButton};
  border: ${(props) => (props.border ? props.border : 'none')};
  border-color: ${(props) => props.bordercolor && props.theme.colors?.primaryColor};
  font-family: ${(props) => props.theme.fonts?.[0].font.body};
  color: ${(props) => props.theme.colors?.btnTextColor};
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.theme.colors?.buttonHover};
  }

  &:active {
    background-color: ${(props) => props.theme.colors?.secondaryColor};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors?.secondaryColor};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors?.primaryButton};
  }
`;

// Authentication Button

export const AuthBtn = styled.button.attrs((props) => ({
  className: props.className,
  type: props.type ? 'submit' : 'button',
}))`
  // Ampersands to overwrite css classes
  &&& {
    background-color: ${(props) => props.theme.colors?.primaryColor};
    color: ${(props) => props.theme.colors?.btnTextColor};
    font-weight: 600;
    text-transform: none;
  }
  &&&:hover {
    color: ${() => '#FFFFFF'};
  }
`;

//Row Buttons

export const RowBtn = styled(Link).attrs(() => ({
  className: 'label label-success label-mini',
}))`
  background-color: ${(props) => props.theme.colors?.primaryButton};
  border: ${(props) => (props.border ? props.border : 'none')};
  border-color: ${(props) => (props.bordercolor ? props.theme.colors?.secondaryColor : '')};
  color: ${(props) => props.theme.colors?.btnTextColor};
  font-weight: 700;
  padding: '0.7em 0.9em';
  &&&:hover {
    background-color: ${(props) => props.theme.colors?.buttonHover};
  }
`;

/** REPORTS **/

// Report Overview
// PDF Report, Excel Report, Consumption Report, Carbon Footprint Report
export const ReportOverviewBtn = styled(RowBtn)`
  float: right;
  margin-left: 5px;
  font-size: 12px;
  &&&:focus {
    background-color: ${(props) => props.theme.colors?.secondaryColor};
  }
`;

// Absolute, per million revenue, per FTE, perm2, 2015
export const ConsumptionReportBtn = styled.button.attrs((props) => ({
  className: props.className,
  style: props.style,
  onClick: props.onClick,
  key: props.key,
}))`
  border: ${(props) => (props.border ? props.border : 'none')};
  margin-right: 5px;

  /*  &:active {
      background: ${(props) => props.theme.colors?.secondaryColor};
    };

    &:focus {
      background: ${(props) => props.theme.colors?.secondaryColor};
    }; */

  &.btn-success {
    background: ${(props) => props.theme.colors?.primaryColor};
    &:hover {
      background: ${(props) => props.theme.colors?.buttonHover};
    }
  }

  &.btn-default {
    background: '#c7cbd6';
    &&&:hover {
      background: '#b0b5b9';
    }
  }
`;

// Consumption Entity Table Title
export const CategoryTableTitle = styled.td.attrs((props) => ({
  className: props.className,
}))`
  background-color: ${(props) => props.theme.colors?.categoryBackground};
  color: ${(props) => props.theme.colors?.categoryText};
`;

// Market Based, Location Based, Check Comparative
export const AnchorLink = styled.a.attrs((props) => ({
  className: props.className,
  style: props.style,
  onClick: props.onClick,
}))`
  border: ${(props) => (props.border ? props.border : 'none')};

  &.btn-success {
    background: ${(props) => props.theme.colors?.primaryColor};
    &:hover {
      background: ${(props) => props.theme.colors?.buttonHover};
    }
  }

  &.btn-default {
    background: '#c7cbd6';
    &&&:hover {
      background: '#b0b5b9';
    }
  }
`;

// Usage Data

// Add Usage Data

export const CalendarBtn = styled.span.attrs((props) => ({
  className: props.className,
}))`
      &&& {
        background-color: ${(props) => props.theme.colors?.primaryButton};
        color: ${(props) => props.theme.colors?.btnTextColor};
        border: ${(props) => (props.border ? props.border : 'none')};
        border-color: ${(props) => (props.bordercolor ? props.bordercolor : '')};
        height: 34px
      }

      &&&:hover {
        background-color: ${(props) => props.theme.colors?.buttonHover}
        border: ${(props) => (props.border ? props.border : 'none')};
        border-color: ${(props) => (props.bordercolor ? props.bordercolor : '')};
      }
    `;

// Import Usage data
export const ImportUsageData = styled.span.attrs((props) => ({
  className: props.classname,
  id: props.id,
}))`
  color: ${(props) => props.theme.colors?.primaryColor};
`;

// Import Excel CSV Links
export const ImportLinks = styled.a.attrs((props) => ({
  className: props.className,
  id: props.id,
}))`
  color: ${(props) => props.theme.colors?.primaryColor};
`;

// Import Excel CSV Links
export const DownloadCSVExcel = styled.a.attrs((props) => ({
  className: props.className || 'btn',
  type: props.type || 'button',
  id: props.id,
  borderColor: props.borderColor ? props.borderColor : props.theme.colors?.primaryColor,
}))`
  background-color: ${(props) => props.theme.colors?.primaryColor};
  border: ${(props) => (props.border ? props.border : 'none')};
  border-color: ${(props) => props.theme.colors?.primaryColor};
  font-family: ${(props) => props.theme.fonts?.[0].font.body};

  &:hover {
    background-color: ${(props) => props.theme.colors?.buttonHover};
  }
`;

// Upload Files Button
export const UploadFileBtn = styled.label.attrs((props) => ({
  className: props.className,
  id: props.id,
  htmlFor: props.htmlFor,
}))`
  background-color: ${(props) => props.theme.colors?.primaryButton};
  border: ${(props) => (props.border ? props.border : 'none')};
  border-color: ${(props) => (props.bordercolor ? props.theme.colors?.secondaryColor : '')};
  color: ${(props) => props.theme.colors?.btnTextColor || 'white'};
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.theme.colors?.buttonHover};
  }
`;

// Start Import Button
export const StartImportBtn = styled.button.attrs((props) => ({
  className: props.className,
  disable: props.disable,
}))`
  background-color: ${(props) => props.theme.colors?.primaryButton};
  border: ${(props) => (props.border ? props.border : 'none')};
  border-color: ${(props) => (props.bordercolor ? props.theme.colors?.secondaryColor : '')};
  color: ${(props) => props.theme.colors?.primaryColor};
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.theme.colors?.buttonHover};
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors?.primaryButton};
  }
`;

export const bulkEditTextInput = {
  alignItems: 'center',
  backgroundColor: 'rgb(255, 255, 255)',
  borderColor: '#e2e2e4',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: 3,
  color: '#555',
  // display: 'inline-block',
  fontFamily: 'Open Sans,sans-serif',
  fontSize: 14,
  fontWeight: 400,
  height: '37px',
  lineHeight: '21px',
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  padding: '3px',
  paddingLeft: '10px',
  textAlign: 'start',
  textIndent: 0,
  whiteSpace: 'pre',
  width: '100%',
};

// Conversion factor styles.
export const ConversionFactorSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '700px',
    float: 'right',
  }),
  control: (base) => ({
    ...base,
    height: 30,
    minHeight: 30,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: 'rgb(137, 129, 127)',
    lineHeight: '14px',
    fontSize: 14,
    padding: '0 6px',
    position: 'auto',
  }),

  clearIndicator: (provided, state) => ({
    ...provided,
    padding: '0 4px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '0 4px',
  }),
};

export const ConversionFactorTextInputStyles = {
  float: 'right',
  textAlign: 'left',
  border: 'solid 1px rgba(0,0,0,.05)',
  padding: '0 5px',
  width: '700px',
};

export const AddUsageInputStyles = {
  alignItems: 'center',
  backgroundColor: 'rgb(255, 255, 255)',
  borderColor: '#e2e2e4',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: 3,
  color: '#555',
  display: 'inline-block',
  fontFamily: 'Open Sans,sans-serif',
  fontSize: 14,
  fontWeight: 400,
  height: '37px',
  lineHeight: '21px',
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  padding: '3px',
  paddingLeft: '10px',
  textAlign: 'start',
  textIndent: 0,
  whiteSpace: 'pre',
  width: '100%',
};

export const AdminPortalBulkChange = styled(Link)`
  background-color: ${(props) => props.theme.colors?.primaryButton};
  font-family: ${(props) => props.theme.fonts?.[0].font.body};
  color: ${(props) => props.theme.colors?.btnTextColor};
  border: none;
  font-weight: 600;

  &:hover {
    background-color: ${(props) => props.theme.colors?.buttonHover};
  }

  &:active {
    background-color: ${(props) => props.theme.colors?.secondaryColor};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors?.secondaryColor};
  }
`;
