import LinearProgress from '@mui/material/LinearProgress';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Actions } from '../../store/actions/facilityActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { checkReportingYearEndAndIndustry } from '../utils/GlobalFunctions';
import DeleteFacilityModal from './DeleteFacilityModal';
import FacilityRow from './FacilityRow';
import UploadFacilityData from './UploadFacilityData';

const FacilityTable = ({
  list,
  getFacilityMetrics,
  currentOrganisation,
  showFacilityImportModal,
  branding,
  showLoader,
  addFacilityIssue,
}) => {
  const { body } = branding.fonts?.[0].font || {};
  const history = useHistory();

  if (showLoader && !list.length) {
    return <LinearProgress color='inherit' />;
  }

  return (
    <div className='row' style={{ fontFamily: body }}>
      <div className='col-lg-12'>
        <section className='panel'>
          <header className='panel-heading'>Your Facilities</header>
          <div className='panel-body'>
            <table id='your-facilities-table' className='table table-hover general-table'>
              <thead>
                <tr>
                  <th>Facility</th>
                  <th>Primary</th>
                  <th>Post code</th>
                  <th>External ID</th>
                  <th>Metrics</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list &&
                  Array.isArray(list) &&
                  list.map((facilityDetails, index) => <FacilityRow key={index} facilityDetails={facilityDetails} />)}
                <tr>
                  <td>
                    <AddButton
                      id='add-facility-button'
                      onClick={() => {
                        // if issue to add facility then redirect to organisation page, otherwise redirect to add facility page
                        addFacilityIssue
                          ? history.push(`/organisations/${currentOrganisation}`)
                          : history.push('/facilities/new');
                      }}
                      className='btn btn-success'>
                      {/* if issue to add facility then show the message, otherwise show add facility */}
                      {addFacilityIssue ? `${addFacilityIssue} to add a facility` : 'Add a Facility'}
                    </AddButton>
                  </td>
                  <td>
                    <AddButton
                      className='btn btn-success'
                      onClick={() => showFacilityImportModal(true)}
                      bordercolor='true'
                      style={{ marginRight: 15 }}>
                      <i className='fa fa-cloud-upload' />
                      &#160; Import Facilities
                    </AddButton>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <UploadFacilityData />
      <DeleteFacilityModal />
    </div>
  );
};

const mapStateToProps = (state) => {
  const orgDetails = state?.organisation?.details;

  const addFacilityIssue = checkReportingYearEndAndIndustry(orgDetails);

  return {
    list: _.sortBy(_.uniqBy(state.facility?.list, 'id'), ['name']),
    currentOrganisation: state.currentOrganisation,
    branding: state.branding,
    showLoader: state?.showLoader,
    addFacilityIssue,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showFacilityImportModal: (status) => dispatch(Actions.showFacilityImportModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilityTable);
