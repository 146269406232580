import React from 'react';
import { connect } from 'react-redux';
import { Actions as ORGANISATIONACTIONS } from '../../store/actions/organisationActions';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';

const SubscriptionFailed = ({ branding }) => {
  const { primaryColor } = branding.colors;

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.8)' }}>
      <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px', margin: '100px auto' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>Payment Failed</h4>
          </div>

          <div className='modal-body' style={{ textAlign: 'center', marginTop: '2em' }}>
            <h4>We are sorry to inform you your payment failed! </h4>
            <h4>Please try again</h4>

            <div className='row block buttons' style={{ marginLeft: 0, marginTop: '2em' }}>
              <a
                href='/'
                className='btn btn-lg btn-login btn-block succesful-subscription'
                style={{
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  width: '20%',
                  backgroundColor: primaryColor,
                  color: 'white',
                }}>
                Try again
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isNewUser: window.location.pathname.includes('register'),
    branding: state.branding,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, item) => dispatch(ORGANISATIONACTIONS.toggleModal(status, item)),
  showSubscriptionModal: (status) => dispatch(PAYMENTACTIONS.showSubscriptionModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionFailed);
