import React from 'react';
import { connect } from 'react-redux';
import ReportTable from '../components/Reports/ReportTable';
import AddReport from '../components/Reports/AddReport';
import Breadcrumb from '../components/Common/Breadcrumb';

const Reports = () => {
  return (
    <React.Fragment>
      <Breadcrumb items={[{ label: 'Reports', link: '/reports' }]} />

      <ReportTable />
      <AddReport />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
