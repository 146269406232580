import React, { useState } from 'react';
import { connect } from 'react-redux';
import { format, addYears, addDays } from 'date-fns';
import { parseFromTimeZone } from 'date-fns-timezone';
import { parseDateToTz } from '../utils/GlobalFunctions';
import Select from 'react-select';
import { Actions } from '../../store/actions/organisationActions';
import NumberFormat from 'react-number-format';
import Tooltip from '@mui/material/Tooltip';

const OrganisationMetricRow = ({ metric, reportingPeriods, orgMetrics, saveOrganisationMetric, list, toggleModal }) => {
  //Filtering the ReportingPeriodsOptions based on the organisation metrics list
  const organistionMetricDates = list.map((date) => date.startDate);
  const filteredReportingPeriods = reportingPeriods.filter((report) => !organistionMetricDates.includes(report.startDate));

  const [editing, setEditing] = useState(metric.id == metric.uuid);
  const [editedState, setEditedState] = useState(null);
  const [invalidInput, setInvalidInput] = useState(false);

  const updateField = (e) => {
    const newEditedState = { ...editedState };

    newEditedState[e.target.name] = e.target.value.replace(/,(?=\d{3})/g, '');

    // Check for 0 values -> disallowing users to input 0 metrics
    Object.keys(newEditedState).every((metric) => {
      if (newEditedState[metric] == '0') {
        setInvalidInput(true);
        return false;
      } else {
        setInvalidInput(false);
        return true;
      }
    });

    setEditedState(newEditedState);
  };

  if (editing) {
    const selectedPeriod = reportingPeriods.find((period) => {
      if (editedState && editedState.startDate) {
        return metric.startDate == editedState.startDate;
      } else if (metric.startDate) {
        return metric.startDate == period.startDate;
      }
    });

    return (
      <tr id='saveOrgMetric-Row'>
        <td style={{ textAlign: 'left' }}>
          <Select
            value={selectedPeriod}
            options={filteredReportingPeriods}
            onChange={(selected) => {
              const newEditedState = { ...editedState };

              newEditedState['year'] = String(selected.value);
              newEditedState['startDate'] = selected.startDate;
              newEditedState['endDate'] = selected.endDate;

              setEditedState(newEditedState);
            }}
          />
        </td>
        {
          <td>
            <NumberFormat
              thousandSeparator={true}
              className='form-control'
              inputMode='numeric'
              onChange={updateField}
              defaultValue={metric.originalTurnover}
              name='originalTurnover'
              type='text'
            />
          </td>
        }
        {orgMetrics.metric1 && orgMetrics.metric1.name !== 'revenue' && (
          <td>
            <NumberFormat
              thousandSeparator={true}
              className='form-control'
              inputMode='numeric'
              onChange={updateField}
              defaultValue={metric.metric1}
              name='metric1'
              type='text'
            />
          </td>
        )}
        {orgMetrics.metric2 && orgMetrics.metric2.name !== 'revenue' && (
          <td>
            <NumberFormat
              thousandSeparator={true}
              className='form-control'
              inputMode='numeric'
              onChange={updateField}
              defaultValue={metric.metric2}
              name='metric2'
              type='text'
            />
          </td>
        )}
        {orgMetrics.metric3 && orgMetrics.metric3.name !== 'revenue' && (
          <td>
            <NumberFormat
              thousandSeparator={true}
              className='form-control'
              inputMode='numeric'
              onChange={updateField}
              defaultValue={metric.metric3}
              name='metric3'
              type='text'
            />
          </td>
        )}

        <td>
          {invalidInput ? (
            <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12 }}>Cannot save 0 input values.</p>}>
              <i
                style={{ cursor: 'pointer', paddingRight: 10 }}
                className={`fa fa-question-circle fa-lg`}
                id='save-org-metric '
              />
            </Tooltip>
          ) : (
            <>
              <i
                style={{ cursor: 'pointer', paddingRight: 10 }}
                onClick={() => {
                  saveOrganisationMetric({ ...metric, ...editedState, status: 'Active' });
                  setEditedState(null);
                  setEditing(false);
                }}
                className={`fa fa-save fa-lg`}
                id='save-org-metric '
              />
            </>
          )}

          {metric.id != metric.uuid && (
            <i
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setEditedState(null);
                setEditing(false);
              }}
              className='fa fa-times fa-lg'
              id='cancel-save-org-metric'
            />
          )}
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td style={{ textAlign: 'left' }}>
        {metric.startDate && format(parseDateToTz(metric.startDate, 'yyyy-MM-dd'), 'do MMM yyyy')} -{' '}
        {metric.endDate && format(parseDateToTz(metric.endDate, 'yyyy-MM-dd'), 'do MMM yyyy')}
      </td>
      {
        <td>
          {metric && metric.originalTurnover
            ? metric.originalTurnover.replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ',')
            : 'N/A'}
        </td>
      }
      {orgMetrics.metric1 && orgMetrics.metric1.name !== 'revenue' && (
        <td>{metric && metric.metric1 ? metric.metric1.replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ',') : 'N/A'}</td>
      )}
      {orgMetrics.metric2 && orgMetrics.metric2.name !== 'revenue' && (
        <td>{metric && metric.metric2 ? metric.metric2.replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ',') : 'N/A'}</td>
      )}
      {orgMetrics.metric3 && orgMetrics.metric3.name !== 'revenue' && (
        <td>{metric && metric.metric3 ? metric.metric3.replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ',') : 'N/A'}</td>
      )}
      <td>
        <i
          style={{ cursor: 'pointer' }}
          onClick={() => setEditing(true)}
          className='fa fa-pencil fa-lg'
          id='edit-org-metric'
        />
        <i
          style={{ cursor: 'pointer', marginLeft: 10 }}
          onClick={() => toggleModal(true, metric, 'deleteOrgMetric')}
          className='fa fa-trash-o fa-lg'
          id='delete-org-metric'
        />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  const organisation =
    state.organisation.details || state.organisation.list.find((item) => item.id == state.currentOrganisation);
  const thisYear = format(new Date(), 'yyyy');

  const lastYear =
    format(new Date(), 'MM-dd') >= `${organisation.reportingYearEndMonth}-${organisation.reportingYearEndDay}`
      ? +thisYear + +'1'
      : thisYear;

  const reportingYearEndMonth = organisation?.reportingMonth || organisation?.reportingYearEndMonth; // property name changes depending on Symph or Dynamo
  const reportingYearEndDay = organisation?.reportingDay || organisation?.reportingYearEndDay; // property name changes depending on Symph or Dynamo

  const reportingPeriods = [];

  if (organisation && reportingYearEndMonth && reportingYearEndDay) {
    for (var i = lastYear; i >= 2018; i--) {
      const endDate = parseFromTimeZone(new Date(`${i}-${reportingYearEndMonth}-${reportingYearEndDay}`), {
        timeZone: 'Europe/London',
      });
      const startDate = addDays(addYears(endDate, -1), 1);

      reportingPeriods.push({
        year: i,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),

        label: `${format(startDate, 'do MMM yyyy')} - ${format(endDate, 'do MMM yyyy')}`,
        value: i,
      });
    }
  }

  return { reportingPeriods };
};

const mapDispatchToProps = (dispatch) => ({
  saveOrganisationMetric: (organisationMetric) => dispatch(Actions.saveOrganisationMetric(organisationMetric)),
  toggleModal: (status, item, action) => dispatch(Actions.toggleModal(status, item, action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationMetricRow);
