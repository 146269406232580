import React from 'react';
import { connect } from 'react-redux';
import OrganisationsTable from '../components/Organisations/OrganisationsTable';
import Breadcrumb from '../components/Common/Breadcrumb';

const Organisations = () => {
  return (
    <React.Fragment>
      <Breadcrumb
        items={[
          {
            label: 'Organisations',
            link: '/organisations',
          },
        ]}
      />

      <OrganisationsTable />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Organisations);
