import { Types } from '../actions/helpActions';
import _ from 'lodash';

const defaultState = {
  my: {},
};

const helpReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.TOGGLE: {
      let newState = _.cloneDeep(state);

      if (typeof action.status == 'boolean') {
        newState.help.show = action.status;
      } else {
        newState.help.show = !newState.help.show;
      }

      return newState;
    }
    case Types.LIST: {
      let newState = _.cloneDeep(state);

      newState.help = state.help ? { ...state.help } : {};
      newState.help.list = action.payload;

      return newState;
    }

    default:
      return state;
  }
};

export default helpReducers;
