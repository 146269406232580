import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { Actions as ADMIN_ACTIONS } from '../../store/actions/adminActions';
import { Checkbox } from '@mui/material';
import { format } from 'date-fns';
import { parseDateToTz } from '../utils/GlobalFunctions';
import _ from 'lodash';

const UsageRow = ({
  row,
  showUsageDataModal,
  showUsageDataDeleteModal,
  hash,
  cyfAdmin,
  updateUsageDataCheckBox,
  i,
  facilityName,
  usageDetailLabels,
  types,
}) => {
  const start = parseDateToTz(row.dateStart, 'yyyy-MM-dd');
  const end = parseDateToTz(row.dateEnd, 'yyyy-MM-dd');
  const usageDetail = [];

  return (
    <tr className={`sub_row-${i}`}>
      <td width='20%'>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          {cyfAdmin && <Checkbox checked={row.checked} onChange={() => updateUsageDataCheckBox(row)} />}
          <span className='usage-subentityName'>{row.subentityName || row.entityName}</span>
        </div>
        <span className='usage-detail'>
          {Object.keys(usageDetailLabels).map((key, index) => {
            if (row[key]) {
              const type = types && _.find(types[key], { id: row[key] });
              const typeName = type && (type?.name || type?.priceType);
              if (typeName !== undefined) {
                usageDetail.push(
                  <span key={index} style={{ display: 'block' }}>{`${usageDetailLabels[key]}: ${typeName}`}</span>
                );
              }
            }
          })}
          {usageDetail}
        </span>
      </td>
      <td className='usage-value' width='20%'>
        {row.usage !== undefined && parseFloat(row.usage).toFixed(2)} {row.metricName}
      </td>
      <td className='usage-facility' width='15%'>
        {row?.facilityName || facilityName}
      </td>
      <td className='usage-period' width='25%'>
        {format(start, 'dd/MM/yyyy')}
        {end && start && start != end && ` - ${format(end, 'dd/MM/yyyy')}`}
      </td>
      <td width='20%'>
        <a
          onClick={() => {
            showUsageDataModal(true, row);
            localStorage.setItem('hash', hash);
          }}
          id='edit-usage'
          className='edit_usage'
          type='button'
          data-toggle='modal'
          title='Edit'
          style={{ cursor: 'pointer' }}>
          <i className='fa fa fa-pencil fa-lg' />
        </a>

        <a
          id='delete-usage'
          onClick={() => showUsageDataDeleteModal(hash, row)}
          style={{ paddingLeft: '40px', cursor: 'pointer' }}>
          <i className='fa fa-trash-o fa-lg' />
        </a>

        <a
          id='copy-usage'
          onClick={() => {
            const copiedRow = { ...row, copy: true };
            delete copiedRow.id;
            showUsageDataModal(true, copiedRow);
          }}
          style={{ paddingLeft: '40px', cursor: 'pointer' }}>
          <i className='fa fa-copy' />
        </a>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  const usageTypeDetails = state?.admin?.adminPortal?.usageTypeDetails;
  const usageDetailLabels = usageTypeDetails.reduce((acc, curr) => {
    acc[curr.type] = curr.displayName;
    return acc;
  }, {});

  return {
    cyfAdmin: state?.profile?.details?.cyfAdmin,
    usageDetailLabels,
    types: state?.metric?.types,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showUsageDataModal: (status, item) => dispatch(USAGEACTIONS.showUsageDataModal(status, item)),
  showUsageDataDeleteModal: (status, item) => dispatch(USAGEACTIONS.showUsageDataDeleteModal(status, item)),
  updateUsageDataCheckBox: (usageItem) => dispatch(ADMIN_ACTIONS.updateUsageDataCheckBox(usageItem)),
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(UsageRow));
