import _ from 'lodash';
import { ModalPropsAdminPortal } from '../../components/AdminPortal/ModalPropsAdminPortal';
import { Types } from '../actions/adminActions';

const defaultState = {};

const adminReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.ADMIN_GETTING: {
      let newState = { ...state };

      newState.admin.updating = true;

      return newState;
    }

    case Types.ADMIN_ORGANISATIONS: {
      let newState = { ...state };

      newState.admin.organisations = _.sortBy(action.payload, ['name']);

      return newState;
    }

    case Types.ADMIN_SET_OS_TERMS: {
      let newState = { ...state };
      let { payload } = action;

      newState.admin.openSearchTerms = { ...payload };

      return newState;
    }

    case Types.ADMIN_GET_CONVERSION_FACTORS: {
      let newState = { ...state };
      let { payload } = action;
      newState.admin.conversionFactors = payload;

      return newState;
    }

    case Types.ADMIN_GET_ALL_ITEMS: {
      let newState = { ...state };

      const data = action.payload.items.message ? action.payload.items.message : action.payload.items;
      const itemCount = action.payload?.itemCount;

      newState.admin.adminPortal = { ...newState.admin.adminPortal };

      newState.admin.adminPortal[action.table] = data;

      if (itemCount) newState.admin.adminPortal.itemCount = itemCount;

      return newState;
    }

    case Types.ADMIN_UPDATE_CHECKED_ITEMS: {
      let newState = { ...state };
      const { itemID } = action.payload;
      newState.admin.termsItems.items = newState?.admin?.termsItems?.items?.map((item) =>
        item.id == itemID ? { ...item, checked: !item.checked } : { ...item }
      );
      return newState;
    }
    case Types.ADMIN_TOGGLE_SELECT_ALL_CHECKED: {
      let newState = { ...state };

      const { checked: selectAllChecked } = action.payload;
      newState.admin.termsItems.items = newState?.admin?.termsItems?.items?.map((item) => ({
        ...item,
        checked: selectAllChecked,
      }));
      return newState;
    }
    case Types.MODAL: {
      let newState = { ...state };
      newState.admin.modal = {};

      const escapeAction = action?.action?.includes('escapeKeyDown');

      if (escapeAction) {
        action.status = false;
        action.action = null;
      }

      const isAlertMessage = newState.alertMessage?.status;

      const optionType = action.item?.type;
      const modalProps = ModalPropsAdminPortal(action.action, newState, optionType, action.item);
      action.item = modalProps?.item ? modalProps?.item : action.item;

      newState.admin.modal = {
        modal: action.action,
        item: action.item,
        modalProps: modalProps,
        notAutoClose: !action.notAutoClose,
      };

      if (action.openSearchTerms) {
        newState.admin.openSearchTerms = action.openSearchTerms;
      }

      if (!action.status && isAlertMessage && action?.action !== 'recalcAllUsage') {
        //close alert message when closing the modal
        newState.alertMessage.status = false;
        delete newState.alertMessage.message;
        delete newState.alertMessage.severity;
      }

      const confirmationModalActions = [
        'disconnect',
        'disable',
        'enable',
        'delete',
        'saveXero',
        'recalcOrgUsage',
        'recalcAllUsage',
        'confirm',
      ];

      if (confirmationModalActions.some((x) => action.action?.includes(x))) {
        // If action is one of the confirmation modal action, set confirmationModal status
        newState.admin.modal = { ...newState.admin.modal, confirmationModal: action.status };
      } else if (['bulk_edit'].some((x) => action.action?.includes(x))) {
        // If bulk edit modal, set bulkEditModal status
        newState.admin.modal = { ...newState.admin.modal, bulkEditModal: action.status };
      } else {
        // Else, set adminPortalModal, for example to edit an item
        newState.admin.modal = { ...newState.admin.modal, adminPortalModal: action.status };
      }

      if (newState.progress >= 100) delete newState.progress;

      return newState;
    }

    case Types.ADMIN_SUBMIT_BULK_USAGE_DATA: {
      let newState = { ...state };
      const { entity } = action;
      newState.admin.checkboxes.checkedUsage = newState.usageData?.filter((item) => item.checked && item.entity == entity);

      if (newState.progress) delete newState.progress;
      return newState;
    }

    case Types.ADMIN_SET_CHECKBOXES: {
      let newState = { ...state };
      newState.admin.checkboxes = {};
      newState.admin.checkboxes.entities = {};
      newState.admin.checkboxes.usageTotals = {};

      const usageTotals = newState.usageTotals;
      const groupedIds = _.groupBy(usageTotals, (item) => item.entity); //group usageTotals by entityName
      const entityIds = Object.keys(groupedIds).sort();

      entityIds.map((entityId) => {
        newState.admin.checkboxes.entities[entityId] = { checked: false };
      });

      usageTotals.map((usageTotal) => {
        newState.admin.checkboxes.usageTotals[usageTotal.hash] = { checked: false, entity: usageTotal.entity };
      });

      return newState;
    }

    case Types.ADMIN_UPDATE_ENTITY_BOX: {
      let newState = { ...state };
      const { entity } = action;

      const newCheckedValue = !newState?.admin?.checkboxes?.entities[entity]?.checked;
      const usageTotalIds = Object.keys(newState?.admin?.checkboxes?.usageTotals);
      // const usageDataIds = Object.keys(newState?.admin?.checkboxes?.usageData);

      newState.usageData = newState?.usageData.map((usage) =>
        usage.entity === entity ? { ...usage, checked: newCheckedValue } : { ...usage }
      );

      usageTotalIds.map((id) => {
        if (newState?.admin?.checkboxes?.usageTotals[id].entity === entity) {
          newState.admin.checkboxes.usageTotals[id] = {
            ...newState?.admin?.checkboxes?.usageTotals[id],
            checked: newCheckedValue,
          };
        }
      });

      newState.admin.checkboxes.entities[entity].checked = newCheckedValue;

      return newState;
    }

    case Types.ADMIN_UPDATE_USAGE_TOTAL_BOX: {
      let newState = { ...state };
      const { usageTotalId } = action;
      //initializing obj + ids...
      const newCheckBoxesObj = newState?.admin?.checkboxes;
      const usageTotalIds = Object.keys(newState?.admin?.checkboxes?.usageTotals);
      const newCheckedValue = !newCheckBoxesObj?.usageTotals[usageTotalId]?.checked;

      newState.usageData = newState?.usageData.map((usage) =>
        usage.hash === usageTotalId ? { ...usage, checked: newCheckedValue } : { ...usage }
      );

      newCheckBoxesObj.usageTotals[usageTotalId].checked = newCheckedValue;

      //entity checked logic.
      const entity = newCheckBoxesObj.usageTotals[usageTotalId].entity;
      const isEntityChecked = usageTotalIds.find(
        (id) => newCheckBoxesObj.usageTotals[id].checked && newCheckBoxesObj.usageTotals[id].entity == entity
      );
      newCheckBoxesObj.entities[entity].checked = isEntityChecked && true;

      //set new checkboxes
      newState.admin.checkboxes = { ...newCheckBoxesObj };

      return newState;
    }

    case Types.ADMIN_UPDATE_USAGE_DATA_BOX: {
      let newState = { ...state };
      const { usageItem } = action;

      // const usageDataObj = newCheckBoxesObj?.usageData;

      //usageData checked logic.
      // newCheckBoxesObj.usageData[usageDataId].checked = !usageDataObj?.[usageDataId]?.checked;

      //initializing objs...
      const newCheckBoxesObj = newState?.admin?.checkboxes;
      const usageTotalsObj = newCheckBoxesObj?.usageTotals;

      newState.usageData = newState?.usageData.map((usage) =>
        usage.id === usageItem.id ? { ...usage, checked: !usage.checked } : { ...usage }
      );

      //usageTotal checked logic.
      const hash = usageItem?.hash;
      const isUsageTotalChecked = newState.usageData.find((item) => item.hash === hash && item.checked === true);
      newCheckBoxesObj.usageTotals[hash].checked = isUsageTotalChecked;

      //entity checked logic.
      const entity = usageItem?.entity;
      const usageTotalIds = Object.keys(usageTotalsObj);
      const isEntityChecked = usageTotalIds.find(
        (id) => newCheckBoxesObj.usageTotals[id].checked && newCheckBoxesObj.usageTotals[id].entity == entity
      );
      newCheckBoxesObj.entities[entity].checked = isEntityChecked;

      //set new checkboxes
      newState.admin.checkboxes = { ...newCheckBoxesObj };

      return newState;
    }

    case Types.SHOW_UPLOAD_LOGO_MODAL: {
      let newState = { ...state };

      newState.admin.uploadLogoModal = {};

      newState.admin.uploadLogoModal = {
        status: action.status,
      };

      return newState;
    }

    case Types.ADMIN_PAYMENT_ORGANISATION: {
      let newState = { ...state };
      const { organisationType } = action;

      newState.admin.paymentOrganisation = organisationType;

      return newState;
    }

    case Types.ADMIN_UPDATE_STEP_FUNCTION_STATUS: {
      let newState = _.cloneDeep(state);

      newState.admin.export = state.admin.export ? { ...state.admin.export } : {};

      if (!newState.admin.export.downloading) newState.admin.export.downloading = {};

      if (action.processingReport) {
        newState.processingImport = state?.processingImport ? { ...state.report } : {};
        newState.processingImport.status = action.processingReport?.data || action.processingReport;
      }

      newState.admin.export.downloading[action.reportFiletype] = action.status;

      return newState;
    }

    case Types.ADMIN_INDEX_RANGE_ITEMS: {
      let newState = { ...state };

      newState.admin.indexItems = {};

      newState.admin.indexItems = {
        items: action.items,
        index: action.index,
        totalCount: action.totalCount,
      };

      return newState;
    }

    case Types.ADMIN_INDEX_TERMS_ITEMS: {
      let newState = { ...state };

      newState.admin.termsItems = {};

      newState.admin.termsItems = {
        items: action.items?.map((item) => ({ ...item, checked: false })),
        index: action.index,
        totalCount: action.totalCount,
      };

      return newState;
    }

    case Types.ADD_OR_UPDATE_ADMIN_PORTAL_ITEMS: {
      let newState = { ...state };
      const { item, table } = action;
      const tablesFetchedAtFirstRender = ['entity', 'subentity', 'usageTypes', 'industries', 'metric', 'scope3Categories'];
      const nestedStates = tablesFetchedAtFirstRender.slice(2);

      // Match global state names
      let stateName;
      let type;
      switch (table) {
        case 'entity':
          stateName = 'entities';
          break;
        case 'subentity':
          stateName = 'subentities';
          break;
        case 'metric':
          stateName = 'list';
          break;
        case 'scope3Categories':
          stateName = 'scope3Categories';
          break;
        case 'usageTypes':
          stateName = 'types';
          type = window.location.pathname.split('/')[2].split('Type')[0];
          break;
        default:
          break;
      }

      if (tablesFetchedAtFirstRender.includes(table)) {
        if (nestedStates.includes(table)) {
          item.map((it) => {
            if (type && type !== 'metric') {
              // For usage types
              const findIndex = newState['metric']['types'][type].findIndex((x) => x.id == it.id);
              if (findIndex == -1) newState['metric']['types'][type].push({ ...it, id: action.id });
              else newState['metric']['types'][type][findIndex] = { ...it };
            } else if (table == 'industries') {
              // For industries
              const findIndex = newState['organisation']['industries'].findIndex((x) => x.id == it.id);
              if (findIndex == -1) newState['organisation']['industries'].push({ ...it, id: action.id });
              else newState['organisation']['industries'][findIndex] = { ...it };
            } else {
              // For scope3Categories and metric
              const findIndex = newState?.['metric']?.[stateName].findIndex((x) => x.id == it.id);
              if (findIndex == -1) newState?.['metric']?.[stateName].push({ ...it, id: action.id });
              else newState['metric'][stateName][findIndex] = { ...it };
            }
          });
        } else {
          item.map((it) => {
            const findIndex = newState[stateName].findIndex((x) => x.id == it.id);
            if (findIndex == -1) newState?.[stateName].push({ ...it, id: action.id });
            else newState[stateName][findIndex] = { ...it };
          });
        }
      } else {
        item.map((it) => {
          // Update admin portal state
          const findIndex = newState.admin.adminPortal?.[table]?.findIndex((x) => x.id == it.id);
          if (findIndex == -1) {
            newState.admin.adminPortal?.[table].push({ ...it, id: action.id });
          } else {
            newState.admin.adminPortal[table][findIndex] = { ...it };
          }
        });
      }

      return newState;
    }

    case Types.DELETE_ADMIN_PORTAL_ITEMS: {
      let newState = { ...state };
      const { item, table } = action;
      const tablesFetchedAtFirstRender = ['entity', 'subentity', 'usageTypes', 'industries', 'metric', 'scope3Categories'];
      const nestedStates = tablesFetchedAtFirstRender.slice(2);

      // Match global state names
      let stateName;
      let type;
      switch (table) {
        case 'entity':
          stateName = 'entities';
          break;
        case 'subentity':
          stateName = 'subentities';
          break;
        case 'metric':
          stateName = 'list';
          break;
        case 'scope3Categories':
          stateName = 'scope3Categories';
          break;
        case 'usageTypes':
          stateName = 'types';
          type = window.location.pathname.split('/')[2].split('Type')[0];
          break;
        default:
          break;
      }

      if (tablesFetchedAtFirstRender.includes(table)) {
        if (nestedStates.includes(table)) {
          item.map((it) => {
            if (type) {
              // For usage types
              const findIndex = newState['metric']['types'][type].findIndex((x) => x.id == it.id.id);
              if (findIndex > -1) newState['metric']['types'][type]?.splice(findIndex, 1);
            } else if (table == 'industries') {
              // For industries
              const findIndex = newState['organisation']['industries'].findIndex((x) => x.id == it.id.id);
              if (findIndex > -1) newState['organisation']['industries']?.splice(findIndex, 1);
            } else {
              // For scope3Categories and metric
              const findIndex = newState['metric'][stateName].findIndex((x) => x.id == it.id.id);
              if (findIndex > -1) newState['metric'][stateName]?.splice(findIndex, 1);
            }
          });
        } else {
          item.map((it) => {
            const findIndex = newState[stateName].findIndex((x) => x.id == it.id.id);
            if (findIndex > -1) newState[stateName]?.splice(findIndex, 1);
          });
        }
      } else {
        item.map((it) => {
          // Delete item from admin portal state
          const findIndex = newState.admin.adminPortal?.[table]?.findIndex((x) => x.id == it.id);
          if (findIndex > -1) {
            newState.admin.adminPortal?.[table].splice(findIndex, 1);
          }
        });
      }

      return newState;
    }

    case Types.LIST_CONTRACTS: {
      let newState = { ...state };

      const { contracts, loader } = action.action;

      newState.admin.contracts = { ...newState.admin.contracts } || {};

      newState.admin.contracts.contracts = contracts;
      newState.admin.contracts.loader = loader;

      return newState;
    }

    case Types.UPDATE_CONTRACTS: {
      let newState = { ...state };

      newState.admin.contracts = { ...newState.admin.contracts } || {};

      // Replace the contract list with the new/edited one
      newState.admin.contracts.contracts = action.contracts;

      return newState;
    }

    default:
      return state;
  }
};

export default adminReducers;
