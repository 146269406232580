import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import { AuthBtn } from '../../styledComponents/GlobalStyle';

const SubscribingUser = ({
  orderFormAccepted,
  validationSchema,
  setValidationError,
  loggingIn,
  paymentMethod,
  processing,
  createStripeCheckoutSession,
  bacsCheckoutSession,
  renewOrgSub
}) => {
  const stripe = useStripe();

  useEffect(() => {
    async function redirectCheckout() {
      //redirect user to Checkout Page as soon as we get the sessionId from the response
      if (stripe && bacsCheckoutSession) await stripe.redirectToCheckout({ sessionId: bacsCheckoutSession.id });
    }
    redirectCheckout();
  }, [bacsCheckoutSession]);

  const handleSubmit = async () => {
    await validationSchema
      .validate({ orderFormAccepted: orderFormAccepted }, { abortEarly: false })
      .then(async () => {
        try {
          loggingIn(true); // show the spinner
          await createStripeCheckoutSession(paymentMethod.value); // Creates a Checkout Session
          loggingIn(false);
        } catch (error) {
          console.log('Payment Error', error);
        }
      })
      .catch((error) => {
        // Still need to validate
        loggingIn(false);
        setValidationError(error.inner);
      });
  };

  return (
    <div className={renewOrgSub && 'login-wrap'}>
      <AuthBtn
        className='btn btn-lg btn-login btn-block'
        id='submit-billingDetails'
        style={{ color: 'white' }}
        disabled={processing ? true : false}
        onClick={() => handleSubmit()}>
        <i className={processing ? 'fa fa-spin fa-spinner' : 'fa fa-shopping-cart'} style={{ marginRight: 5 }} />
        Go to checkout page
      </AuthBtn>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bacsCheckoutSession: state.payment?.stripe?.intent?.subscription || state.payment?.stripe?.intent,
    processing: state.authentication.processing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createStripeCheckoutSession: (paymentMethod) => dispatch(PAYMENTACTIONS.createStripeCheckoutSession(paymentMethod)),
  loggingIn: (saving) => dispatch(AUTHENTICATION_ACTIONS.loggingIn(saving)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribingUser);
