import { Auth } from '@aws-amplify/auth';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Actions as LOGINACTIONS } from '../../store/actions/authenticationActions';
import { AuthBtn } from '../../styledComponents/GlobalStyle';
import LogoSide from '../Register/LogoSide';
import ValidationMessage from '../utils/ValidationMessage';

const validationSchema = yup.object().shape({
  verificationCode: yup.string().required('Verification Code is required'),
  email: yup.string().email().required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])\S{8,}/,
      'Password must contain 8 characters, one uppercase, one lowercase,one number and one special case character'
    ),
});

const SetPassword = ({ setReset, setShowNewPasswordComponent, loggingIn, branding, processing }) => {
  const { bgColor, buttonHover, primaryHeaderText } = branding.colors || {};
  const { headers } = branding.fonts?.[0].font || {};

  const [editedState, setEditedState] = useState({});
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [showPassword, setShowPassword] = useState({ password: false, newPassword: false });

  const setNewPassword = async () => {
    await validationSchema
      .validate(
        {
          verificationCode: editedState?.['verificationCode'],
          email: editedState?.['email'],
          password: editedState?.['password'],
        },
        { abortEarly: false }
      )
      .then(async () => {
        try {
          loggingIn(true);
          await Auth.forgotPasswordSubmit(
            editedState?.['email'],
            editedState?.['verificationCode'],
            editedState?.['password']
          );
          setPasswordChanged(true);
          loggingIn(false);
        } catch (error) {
          loggingIn(false);
          console.log('AWS Error: ', error);
          setError(error);
        }
      })
      .catch((error) => {
        loggingIn(false);
        console.log('Validation Error: ', error);
        setValidationError(error.inner);
      });
  };

  const updateField = (e) => {
    const newEditedState = { ...editedState };
    newEditedState[e.target.name] = e.target.value;
    setEditedState(newEditedState);
  };

  return (
    <div className='login-body' style={{ height: '100vh', width: '100%', display: 'flex' }}>
      <div className='logoSide' style={{ background: bgColor }}>
        <LogoSide />
      </div>
      <div className='signInSide' style={{ background: buttonHover }}>
        <div className='signInFields' style={{ width: '35vw' }}>
          <h1 style={{ marginBottom: '1.5vh', color: primaryHeaderText }}>
            {passwordChanged ? 'Password' : 'Set a New Password'}
          </h1>
          <div
            className='col-xs-12 alert-danger alert'
            style={{ marginBottom: 10, zIndex: 10, display: error ? 'block' : 'none' }}>
            <h5 style={{ textAlign: 'left' }}>Error: {error?.log || error?.message}</h5>
          </div>

          <div style={{ display: passwordChanged ? 'flex' : 'block', width: '100%' }}>
            <div>
              <div>
                <div style={{ display: passwordChanged ? 'flex' : 'none', justifyContent: 'center' }}>
                  <p
                    id='password-successfully-changed'
                    style={{ textAlign: 'center', fontSize: 22, fontWeight: 700, color: primaryHeaderText }}>
                    Your password has been changed!
                  </p>
                </div>

                {!passwordChanged && (
                  <>
                    <div style={{ width: '100%' }}>
                      <input
                        type='text'
                        name='verificationCode'
                        className='form-control'
                        placeholder='Verification Code'
                        required
                        onChange={updateField}
                        style={{ marginBottom: '1.5vh', height: '4.5vh' }}
                      />

                      <ValidationMessage message={_.find(validationError, { path: 'verificationCode' })?.message} />

                      <input
                        type='text'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={updateField}
                        style={{ marginBottom: '1.5vh', height: '4.5vh' }}
                      />

                      <ValidationMessage message={_.find(validationError, { path: 'email' })?.message} />

                      <div style={{ display: 'flex', placeItems: 'center', marginBottom: '1.5vh', height: '4.5vh' }}>
                        <i
                          className='fa fa-eye'
                          style={{ cursor: 'pointer', position: 'absolute', left: '86vw', zIndex: 10 }}
                          onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                        />
                        <input
                          type={showPassword.password ? 'text' : 'password'}
                          name='password'
                          className='form-control'
                          placeholder='New Password'
                          onChange={updateField}
                          style={{ height: '4.5vh' }}
                        />
                      </div>

                      <ValidationMessage message={_.find(validationError, { path: 'password' })?.message} />
                    </div>

                    <div style={{ borderRadius: '2vh' }}>
                      <button
                        className='btn btn-default'
                        onClick={() => {
                          setReset(false);
                          setShowNewPasswordComponent(false);
                        }}>
                        Cancel
                      </button>

                      <AuthBtn
                        className='btn btn-success onceonly pull-right'
                        style={{ fontFamily: headers, color: 'white' }}
                        onClick={setNewPassword}>
                        {processing && <i className='fa fa-spin fa-spinner icon-spinner' />}
                        Submit
                      </AuthBtn>
                    </div>
                  </>
                )}
              </div>

              {passwordChanged && (
                <div style={{ height: '2em', marginBottomm: '0.5em', borderRadius: '2vh' }}>
                  <AuthBtn
                    className='btn btn-success onceonly pull-right'
                    style={{ fontFamily: headers, color: 'white' }}
                    onClick={() => {
                      setReset(false);
                      setShowNewPasswordComponent(false);
                    }}>
                    Continue
                  </AuthBtn>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    branding: state.branding,
    processing: state.authentication.processing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loggingIn: (saving) => dispatch(LOGINACTIONS.loggingIn(saving)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
