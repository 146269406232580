import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AuthBtn } from '../../styledComponents/GlobalStyle';

const DropdownContent = styled.div`
  background-color: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const AuthDropdown = ({ handleSubmit, headers }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className='' style={{ position: 'relative', display: 'block' }}>
      <AuthBtn
        id='sign-in-partner-btn'
        className='btn btn-lg btn-login '
        bgcolor
        onClick={toggleDropdown}
        style={{ fontFamily: headers, marginTop: '3vh', color: 'white', backgroundColor: '#36384A' }}>
        Sign In with Partner
      </AuthBtn>
      {showDropdown && (
        <DropdownContent>
          <a
            className='btn btn-lg btn-login btn-block xero-login partner-btn'
            style={{ fontFamily: headers, width: '100%' }}
            href='#'
            onClick={() => {
              Auth.federatedSignIn({ customProvider: 'Xero' });
            }}>
            Sign in with{' '}
            <img
              src={process.env.PUBLIC_URL + '/images/xero_circle.png'}
              alt='Xero logo'
              style={{ width: '40px', height: '40px' }}
            />
          </a>
          {/* <a
            className='btn btn-lg btn-login btn-block google-login partner-btn'
            style={{ fontFamily: headers, width: '100%' }}
            href='#'
            onClick={() => {
              Auth.federatedSignIn({ customProvider: 'Google' });
            }}>
            Sign in with{' '}
            <img
              src={process.env.PUBLIC_URL + '/images/google_circle.png'}
              alt='Google logo'
              style={{ width: '27px', height: '27px', marginLeft: '7px' }}
            />
          </a> */}
        </DropdownContent>
      )}
    </div>
  );
};

export default AuthDropdown;
