import axios from 'axios';
import { parseDateToTz } from '../../components/utils/GlobalFunctions';
import { getStepFunctionStatus, processingStepFunctionStatus, showLoader } from '../action';
import createSignedRequest from './createSignedRequest';
const { apiURL } = require('./apiURL');
const { format } = require('date-fns');

const Types = {
  BENCHMARK: 'CONSUMPTION_BENCHMARK',
  GET: 'CONSUMPTION_GET',
  TOGGLE_FILTER: 'CONSUMPTION_TOGGLE_FILTER',
  MONTHLY_FILTER: 'CONSUMPTION_MONTHLY_FILTER',
  GET_CONSUMPTION_STATUS: 'CONSUMPTION_GET_STATUS',
};

export const updateConsumption = (response) => ({
  type: Types.GET,
  payload: response,
});

const toggleConsumptionFilter = (filter) => ({
  type: Types.TOGGLE_FILTER,
  filter,
});

const updateConsumptionStatus = (status, processingConsumption) => ({
  type: Types.GET_CONSUMPTION_STATUS,
  status,
  processingConsumption,
});

export const getConsumption = (groupByMonth, stepFunctionSucceeded) => {
  return async (dispatch, getState) => {
    const state = getState();
    const reportDetails = state.report?.details;
    dispatch(showLoader(true));

    const from =
      reportDetails && reportDetails.startDate
        ? format(parseDateToTz(reportDetails.startDate, 'yyyy-MM-dd'), 'yyyy-MM-dd')
        : '2019-01-01';
    const to =
      reportDetails && reportDetails.endDate
        ? format(parseDateToTz(reportDetails.endDate, 'yyyy-MM-dd'), 'yyyy-MM-dd')
        : '2019-12-31';

    try {
      const response = await axios(
        await createSignedRequest(
          'GET',
          apiURL + `/my/consumption/${from}/${to}?groupByMonth=${groupByMonth}&reportId=${reportDetails?.id}`,
          null,
          { Organisation: String(state.currentOrganisation) }
        )
      );

      console.log('getConsumption', response);

      if (stepFunctionSucceeded) {
        dispatch(updateConsumption(response.data?.consumption));
      } else {
        dispatch(getStepFunctionStatus(response.data.stepFunction.executionArn));
      }
    } catch (e) {
      console.log('getConsumption Error', e);
      dispatch(updateConsumptionStatus(false));
      dispatch(showLoader(false));
    }
  };
};

const getConsumptionStepFunctionStatus = (importArn) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/my/stepFunctionStatus/' + importArn, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      dispatch(processingStepFunctionStatus(response.data));

      //Step Function Status is Succeeded
      if (response.data?.data?.status == 'SUCCEEDED') {
        // Flag so when we call again getConsumption we won't invoke another step function execution
        const stepFunctionSucceeded = true;
        // const consumptionData = JSON.parse(response.data?.data?.output);
        // dispatch(updateConsumption(consumptionData));
        dispatch(updateConsumptionStatus(false));
        dispatch(getConsumption(null, stepFunctionSucceeded));
      }

      //Step Function Status Failed
      if (response.data?.data?.status == 'FAILED') {
        dispatch(updateConsumptionStatus(false));
      }
    } catch (e) {
      console.log('getConsumptionStepFunctionStatus Error:', e);
    }
  };
};

const updateBenchmark = (response) => ({
  type: Types.BENCHMARK,
  payload: response,
});

const getBenchmarkData = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/benchmark`, null, { Organisation: String(state.currentOrganisation) })
      );

      console.log('getBenchmarkData', response);
      dispatch(updateBenchmark(response.data));
    } catch (e) {
      console.log('getBenchmarkData Error', e);
    }
  };
};

const monthlyFilters = () => ({
  type: Types.MONTHLY_FILTER,
});

const Actions = {
  getBenchmarkData,
  getConsumption,
  toggleConsumptionFilter,
  monthlyFilters,
  getConsumptionStepFunctionStatus,
};

export { Actions, Types };
