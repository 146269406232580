import LinearProgress from '@mui/material/LinearProgress';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { adminPortalSelect } from '../utils/ReactSelectStyles';
import EntityData from './EntityData';

const UsageTable = ({ usageTotals, branding, facilityOptions, currentOrganisation }) => {
  const [usageCategory, setUsageCategory] = React.useState();
  const [facility, setFacility] = React.useState();
  const [reportingYear, setReportingYear] = React.useState();

  const [filter, setFilter] = React.useState(false);
  const { headers } = branding.fonts?.[0].font;
  const [newTotals, setNewTotals] = React.useState([]);

  const updateFilteredData = () => {
    let filteredData = [...usageTotals];

    if (usageCategory) {
      filteredData = filteredData.filter((item) => item.entityName === usageCategory);
    }

    if (facility) {
      filteredData = filteredData.filter((item) => item.facility === facility);
    }

    if (reportingYear) {
      const formattedYear = moment(reportingYear).format('YYYY-MM-DD');
      filteredData = filteredData.filter((item) => item.endDate === formattedYear);
    }

    setNewTotals(filteredData);
    setFilter(true);
  };

  //Reset filters when organisation changes
  useEffect(() => {
    setUsageCategory(null);
    setFacility(null);
    setReportingYear(null);
    setFilter(false);
    setNewTotals([]);
    newGroupedTotals = [];
  }, [currentOrganisation]);

  //Update filtered data when filters change
  useEffect(() => {
    if (!usageCategory && !facility && !reportingYear) {
      setFilter(false);
    } else {
      updateFilteredData();
    }
  }, [usageCategory, facility, reportingYear]);

  //Update filtered data when new usage data is added
  useEffect(() => {
    if (usageTotals) {
      updateFilteredData();
    }
  }, [usageTotals]);

  const groupedTotals = _.groupBy(usageTotals, (item) => item.entityName); //group usageTotals by entityName

  let newGroupedTotals = _.groupBy(newTotals, (item) => item.entityName);

  const entities = Object.keys(groupedTotals).sort();

  const newEntities = Object.keys(newGroupedTotals).sort();

  if (!usageTotals?.length > 0 && !usageTotals) {
    return <LinearProgress sx={{ marginTop: '3vh' }} color='inherit' />; //Loader
  }

  const getStartDate = (endDate) => {
    return moment(endDate).add(-1, 'year').add(1, 'day').format('DD/MM/YYYY');
  };

  const usageOptions = _.uniqBy(usageTotals, 'entityName').map((item) => {
    return {
      value: item.entityName,
      label: item.entityName,
    };
  });

  const reportingYearOptions = _.uniqBy(usageTotals, 'endDate').map((item) => {
    return {
      value: new Date(item.endDate),
      label: getStartDate(item.endDate) + ' - ' + moment(item.endDate).format('DD/MM/YYYY'),
    };
  });

  if (usageTotals && !usageTotals.length > 0) {
    return (
      <div className='alert-message'>
        <i className='fa-solid fa-triangle-exclamation' />
        <span>No Usage Data Found</span>
      </div>
    );
  }

  return (
    <div className='row' style={{ marginTop: '40px', fontFamily: headers }}>
      <div className='col-lg-12'>
        <section className='panel'>
          <header className='panel-heading text-center'>
            <h5>Filter By:</h5>
            <hr></hr>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: 'inherit',
              }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30%' }}>
                <span
                  style={{
                    padding: '10px',
                  }}>
                  <b>Usage Category: </b>
                </span>
                <div
                  style={{
                    width: '60%',
                  }}>
                  <Select
                    name={'usageCategory'}
                    options={usageOptions}
                    style={adminPortalSelect}
                    onChange={(selected) => {
                      setUsageCategory(selected?.value);
                    }}
                    isClearable={true}
                    value={usageOptions.find((obj) => obj.value === usageCategory) || ''}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40%' }}>
                <span style={{ padding: '10px' }}>
                  <b>Data Starts Between: </b>
                </span>
                <div style={{ width: '52%' }}>
                  <Select
                    name={'reportingYear'}
                    options={reportingYearOptions.sort(function (a, b) {
                      return new Date(a.value) - new Date(b.value);
                    })}
                    style={adminPortalSelect}
                    onChange={(selected) => {
                      setReportingYear(selected?.value);
                    }}
                    isClearable={true}
                    value={filter ? reportingYearOptions.find((obj) => obj.value === reportingYear) : ''}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30%' }}>
                <span style={{ padding: '10px' }}>
                  <b>Facility: </b>
                </span>
                <div style={{ width: '60%' }}>
                  <Select
                    name={'facility'}
                    options={facilityOptions}
                    style={adminPortalSelect}
                    onChange={(selected) => {
                      setFacility(selected?.value);
                    }}
                    isClearable={true}
                    value={facilityOptions?.find((obj) => obj.value === facility) || ''}
                  />
                </div>
              </div>
            </div>
          </header>
          <div className='panel-body'>
            <table className='table  table-hover general-table usage-table'>
              {filter === false
                ? entities?.map((entity, index) => (
                    <EntityData key={index} usageTotals={groupedTotals[entity]} index={entity} entity={entity} />
                  ))
                : newEntities?.map((entity, index) => (
                    <EntityData key={index} usageTotals={newGroupedTotals[entity]} index={entity} entity={entity} />
                  ))}
            </table>
            {newTotals?.length === 0 && filter === true && (
              <p style={{ display: 'flex', justifyContent: 'center' }}>There are no search results!</p>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    usageTotals: state.usageTotals,
    currentOrganisation: state.currentOrganisation,
    branding: state.branding,
    facilityOptions: state.facility.list?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUsageTotals: () => dispatch(USAGEACTIONS.getUsageTotals()),
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(UsageTable));
