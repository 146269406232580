import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { Actions as USAGE_ACTIONS } from '../../../store/actions/usageActions';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import _, { isArray } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import Select from 'react-select';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { AddButton, AdminPortalBulkChange } from '../../../styledComponents/GlobalStyle';
import { adminPortalSelect } from '../../utils/ReactSelectStyles';

const UsageDataListTable = ({
  branding,
  tableName,
  page,
  filterSize,
  tableSize,
  setTotalItems,
  setShowHide,
  setFilter,
  filter,
  usageData,
  parentUsage,
  options,
  setShowPagination,
  toggleModal,
  showLoader,
  handleTableSizeChange,
  getItemsByTermsFromIndexExport,
  canEditAdminPortal,
  showUsageDataModal,
}) => {
  const get_OS_terms = () => ({ filters: filter, page, tableSize: tableSize.value });
  const { headers } = branding?.fonts[0]?.font || {};

  const [localUsageData, setLocalUsageData] = useState(parentUsage);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [exporting, setExporting] = useState(false);
  const checkedList = isArray(localUsageData) && localUsageData?.filter((item) => item.checked);

  const selectAll = () => {
    setSelectAllChecked(!selectAllChecked);
    setLocalUsageData((prev) => prev.map((item) => ({ ...item, checked: !selectAllChecked })));
  };

  const handleFilterUsage = (e) => {
    const checked = e.target.checked;
    // When checked, user wants to see parent and child usage data
    if (checked) {
      return setLocalUsageData(usageData);
    }
    setLocalUsageData(parentUsage);
  };

  useEffect(() => {
    if (checkedList.length == 0) setSelectAllChecked(false);
  }, [checkedList.length]);

  useEffect(() => {
    // At first render only show parent usage data
    setLocalUsageData(parentUsage);
  }, [usageData.length > 0]);

  if (usageData && !isArray(usageData)) {
    //Error Message
    return <div>{usageData}</div>;
  }

  if (showLoader) {
    return <LinearProgress color='inherit' />;
  }

  const formatCellValue = ({ cellData, dataKey }) => {
    if (dataKey) {
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{cellData}</p>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {cellData}
            </div>
          </Tooltip>
        </div>
      );
    }
  };

  const _headerRenderer = () => {
    return <div>{usageData.length > 1 && <Checkbox onChange={selectAll} checked={selectAllChecked} />}</div>;
  };

  const bulkRenderer = ({ cellData, rowData }) => {
    return (
      <Checkbox
        checked={rowData?.checked}
        onChange={() => {
          const newUsageData = [...localUsageData];
          const findIndex = localUsageData.findIndex((item) => item.id === rowData.id);
          newUsageData[findIndex].checked = !newUsageData[findIndex].checked;
          setLocalUsageData(newUsageData);
        }}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    );
  };

  const exportData = async () => {
    setExporting(true);
    const newFilter = { ...filter };
    const filterName = 'generateExport';
    newFilter[filterName] = true;
    await getItemsByTermsFromIndexExport(tableName, newFilter, page, tableSize?.value);
    setExporting(false);
  };

  const formatUsageTypesValues = ({ cellData, rowData, dataKey }) => {
    if (
      [
        'subentity',
        'vehicle',
        'production',
        'metric',
        'waste',
        'seat',
        'load',
        'fuel',
        'entity',
        'facility',
        'organisation',
      ].includes(dataKey)
    )
      return (
        <div>
          <Tooltip
            sx={{ fontSize: 20 }}
            title={
              <p style={{ fontSize: 12, marginBottom: 0 }}>
                {cellData && _.find(options?.[dataKey], { value: cellData })?.label}
              </p>
            }>
            <div
              style={{ cursor: 'pointer', whiteSpace: 'pre-wrap', overflow: 'hidden', height: 'inherit' }}
              className='admin_portal_cellData_description'>
              {cellData ? _.find(options?.[dataKey], { value: cellData })?.label : ''}
            </div>
          </Tooltip>
        </div>
      );

    if (['creationDate', 'modifiedDate', 'dateStart', 'dateEnd'].includes(dataKey))
      return (
        <div>
          <Tooltip
            sx={{ fontSize: 20 }}
            title={<p style={{ fontSize: 12, marginBottom: 0 }}>{cellData && moment(cellData).format('DD/MM/YYYY')}</p>}>
            <div
              style={{ cursor: 'pointer', whiteSpace: 'pre-wrap', overflow: 'hidden', height: 'inherit' }}
              className='admin_portal_cellData_description'>
              {cellData && moment(cellData).format('DD/MM/YYYY')}
            </div>
          </Tooltip>
        </div>
      );

    if (dataKey)
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{cellData}</p>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {cellData}
            </div>
          </Tooltip>
        </div>
      );
  };

  const userActions = ({ rowData }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>Edit Usage Data</p>}>
          <EditIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              showUsageDataModal(true, rowData, true);
            }}
          />
        </Tooltip>
        <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>Delete Usage Data</p>}>
          <DeleteIcon
            sx={{ marginLeft: '1vh', cursor: 'pointer' }}
            onClick={() => toggleModal(true, 'usageData-delete', rowData)}
          />
        </Tooltip>
      </div>
    );
  };

  if (usageData && !usageData.length > 0) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className='alert-message'>
          <i className='fa-solid fa-triangle-exclamation' />
          <span>No Usage Data Found</span>
        </div>
        <AddButton
          id='reset-btn'
          className='btn btn-success'
          onClick={() => {
            toggleModal(true, 'usageData-bulk_search', {
              tableName,
              filter,
              page,
              tableSize: tableSize?.value,
              type: 'usageData-bulk_search',
            });
          }}
          bordercolor='true'
          style={{ fontSize: 16, marginLeft: '3vh' }}>
          Filter
        </AddButton>
        <AddButton
          id='reset-btn'
          className='btn btn-success'
          onClick={() => {
            setFilter({});
            setTotalItems(0);
            setShowPagination(false);
            setShowHide(false);
          }}
          bordercolor='true'
          style={{ width: '10vh', height: 'fit-content', fontSize: 16, marginLeft: '2vh' }}>
          Reset
        </AddButton>
      </div>
    );
  }

  return (
    <section className='panel' style={{ fontFamily: headers }}>
      <header
        className='panel-heading'
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        Usage Data Results
        <div style={{ marginRight: 10, display: 'flex', alignItems: 'baseline' }}>
          <Select
            options={filterSize}
            name={'tableSize'}
            defaultValue={filterSize[0]}
            onChange={handleTableSizeChange}
            styles={{
              ...adminPortalSelect,
              container: (x) => ({ ...x, margin: '0px 40px' }),
              control: (x) => ({ ...x, ...adminPortalSelect.control, width: 100 }),
            }}
            placeholder={'Table Size'}
            value={tableSize}
          />
          <FormGroup>
            <FormControlLabel control={<Switch />} label='Children Usage' onChange={handleFilterUsage} />
          </FormGroup>
          <AddButton
            id='reset-btn'
            className='btn btn-success'
            onClick={() => {
              toggleModal(true, 'usageData-bulk_search', {
                tableName,
                filter,
                page,
                tableSize: tableSize?.value,
                type: 'usageData-bulk_search',
              });
            }}
            style={{ fontSize: 16, marginLeft: '3vh' }}>
            Filter
          </AddButton>
          <AddButton
            id='reset-btn'
            className='btn btn-success'
            onClick={() => {
              setFilter({});
              setTotalItems(0);
              setShowPagination(false);
              setShowHide(false);
            }}
            style={{ fontSize: 16, marginLeft: '3vh' }}>
            Reset
          </AddButton>
          <AddButton
            id='export-btn'
            className='btn'
            onClick={() => {
              exportData();
            }}
            disabled={_.isEmpty(filter) || exporting}
            style={{ fontSize: 16, marginLeft: '3vh' }}>
            {exporting && <i className={`fa fa-spin fa-spinner`} />} Export
          </AddButton>
          {checkedList?.length > 1 && (
            <>
              <div style={{ marginRight: '1.5vh' }}>
                <AdminPortalBulkChange
                  style={{ width: '12vh', fontSize: 16, marginLeft: '3vh', textTransform: 'none' }}
                  to={'/admin/usageData'}
                  className='btn'
                  onClick={() => {
                    toggleModal(true, 'usageData-bulk_edit', checkedList, null, get_OS_terms());
                  }}>
                  Bulk Edit
                </AdminPortalBulkChange>
              </div>
              <div>
                <AdminPortalBulkChange
                  style={{ fontSize: 16, textTransform: 'none' }}
                  to={'/admin/usageData'}
                  className='btn'
                  onClick={() => {
                    toggleModal(true, 'usageData-bulk_delete', checkedList, null, get_OS_terms());
                  }}>
                  Bulk Delete
                </AdminPortalBulkChange>
              </div>
            </>
          )}
        </div>
      </header>
      <div className='panel-body' style={{ height: '100vh', overflowX: 'auto' }}>
        <div style={{ width: '100%', height: '100%', display: !localUsageData?.length > 0 ? 'none' : 'block' }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width + 1000}
                height={height}
                rowHeight={100}
                headerHeight={50}
                headerStyle={{ verticalAlign: 'bottom', width: '100%', display: 'flex', justifyContent: 'center' }}
                // rowStyle={{ textAlign: 'center', textOverflow: 'auto-flow', fontSize: 13 }}
                rowStyle={({ index }) => {
                  const rowData = localUsageData[index];
                  const shouldHighlight = rowData && rowData.checked;
                  return {
                    backgroundColor: shouldHighlight ? 'lightblue' : 'white',
                    textAlign: 'center',
                    textOverflow: 'auto-flow',
                    fontSize: 13,
                  };
                }}
                headerClassName='admin_portal_column_headers'
                rowCount={(localUsageData && localUsageData.length) || null}
                rowGetter={({ index }) => localUsageData[index]}>
                {canEditAdminPortal && (
                  <Column dataKey='id' width={60} headerRenderer={_headerRenderer} cellRenderer={bulkRenderer} />
                )}
                {canEditAdminPortal && <Column label='Actions' dataKey='id' width={75} cellRenderer={userActions} />}
                <Column label='ID' dataKey='id' width={75} cellRenderer={formatCellValue} />
                <Column label='Entity' dataKey='entity' width={150} cellRenderer={formatUsageTypesValues} />
                <Column label='Subentity' dataKey='subentity' width={150} cellRenderer={formatUsageTypesValues} />
                <Column label='Facility' dataKey='facility' width={150} cellRenderer={formatUsageTypesValues} />
                <Column label='Metric' dataKey='metric' width={85} cellRenderer={formatUsageTypesValues} />
                <Column label='Fuel' dataKey='fuel' width={85} cellRenderer={formatUsageTypesValues} />
                <Column label='Load' dataKey='load' width={85} cellRenderer={formatUsageTypesValues} />
                <Column label='Production' dataKey='production' width={85} cellRenderer={formatUsageTypesValues} />
                <Column label='Seat' dataKey='seat' width={85} cellRenderer={formatUsageTypesValues} />
                <Column label='Vehicle' dataKey='vehicle' width={85} cellRenderer={formatUsageTypesValues} />
                <Column label='Waste' dataKey='waste' width={85} cellRenderer={formatUsageTypesValues} />
                <Column label='Organisation' dataKey='organisation' width={150} cellRenderer={formatUsageTypesValues} />
                <Column label='Usage' dataKey='usage' width={150} cellRenderer={formatCellValue} />
                <Column label='Start Date' dataKey='dateStart' width={150} cellRenderer={formatUsageTypesValues} />
                <Column label='End Date' dataKey='dateEnd' width={150} cellRenderer={formatUsageTypesValues} />
                <Column label='Modified Date' dataKey='modifiedDate' width={150} cellRenderer={formatUsageTypesValues} />
                <Column label='Source' dataKey='source' width={150} cellRenderer={formatUsageTypesValues} />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { page, setTableSize, tableSize, handleTableSizeChange } = ownProps;
  const tableName = 'usageData';
  const usageData = state?.admin?.termsItems?.items || [];
  const profile = state.profile?.details;
  // If usage doesn't have parentID, it's a parent usage
  const parentUsage = usageData.length > 0 && usageData?.filter((item) => !item.parentID);

  return {
    totalItems: state?.admin?.termsItems?.totalCount,
    branding: state.branding,
    adminPortalModal: state.admin?.modal?.adminPortalModal,
    tableName,
    filterSize: state.admin?.filterSize,
    page,
    tableSize,
    handleTableSizeChange,
    setTableSize,
    usageData: usageData,
    parentUsage: parentUsage,
    showLoader: state?.showLoader,
    canEditAdminPortal: profile?.canEditAdminPortal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
  filterFromOpeanSearch: (index, query) => dispatch(ADMINACTIONS.filterFromOpeanSearch(index, query)),
  showUsageDataModal: (status, item, adminPortal) => dispatch(USAGE_ACTIONS.showUsageDataModal(status, item, adminPortal)),
  getItemsByTermsFromIndexExport: (index, filters, page, size) =>
    dispatch(ADMINACTIONS.getItemsByTermsFromIndexExport(index, filters, page, size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageDataListTable);
