import { Types } from '../actions/sidebarActions';
import _ from 'lodash';

const defaultState = {};

const sidebarReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SIDEBAR_TOGGLE: {
      let newState = _.cloneDeep(state);

      newState.sidebar.show = !newState.sidebar.show;

      return newState;
    }

    case Types.SHOW_BILLING_DETAILS: {
      let newState = _.cloneDeep(state);

      newState.sidebar.showBillingDetails = action.status;

      return newState;
    }

    default:
      return state;
  }
};

export default sidebarReducers;
