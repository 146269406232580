import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Column } from 'react-virtualized';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { Actions as PAYMENTACTIONS } from '../../../store/actions/paymentActions';
import { Modal, LinearProgress, TextField, InputAdornment, Tooltip, Checkbox, FormControlLabel } from '@mui/material';
import { AccountCircle, ControlPoint, RemoveCircleOutline, DeleteOutline } from '@mui/icons-material';
import _ from 'lodash';
import { NumericTextField } from '../../utils/GlobalFunctions';

const PricingBandModal = ({
  showModal,
  setShowModal,
  getAllItemsFromDB,
  pricingBands,
  editedState,
  setEditedState,
  rowData,
  headerStyle,
  isNew,
  setIsNew,
  savePricingBand,
}) => {
  const openModal = rowData || showModal || isNew ? true : false;
  const [bands, setBands] = useState([]); // For Displaying Pricing Bands
  const [editBand, setEditBand] = useState({ bands: [], name: '' }); // Add or Edit Pricing Bands
  const [rowCount, setRowCount] = useState(2);

  useEffect(() => {
    if (!pricingBands) {
      // Fetch pricing bands in case they're not on the state,
      const table = 'pricingBands';
      getAllItemsFromDB(table);
    }
  }, [showModal]);

  useEffect(() => {
    if (pricingBands) {
      // Display pricing bands in the table
      setBands(_.orderBy(pricingBands, (pb) => pb.bands.length, 'desc'));
    }
  }, [pricingBands]);

  useEffect(() => {
    // Setting Default State
    if (rowData) {
      // When editing a band
      setEditBand(rowData);
      setRowCount(rowData.bands.length);
    } else if (isNew) {
      // Adding new pricing bands
      let newBands = [...editBand.bands];
      if (newBands.length !== rowCount) {
        for (let i = 1; i <= rowCount; i++) newBands.push({ index: i });
        setEditBand({ ...editBand, bands: newBands });
      }
    }
  }, []);

  useEffect(() => editedState && setBands(_.orderBy(pricingBands, (pb) => pb.bands.length, 'desc')), [pricingBands]);

  const handleClose = () => {
    if (isNew) {
      // Reset the State
      setIsNew(false);
      setEditBand({ bands: [], name: '' });
    } else setShowModal(false);
  };

  const handleSave = async () => await savePricingBand(editBand);

  const cellValue = ({ cellData, dataKey, rowData: rowVal, rowIndex }) => {
    if (dataKey == 'action') {
      return (
        // When adding new pricing bands, this is to delete a row already filled
        // It removes the  band from the bands array, updates the states, and reduces the rowCount
        <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>Delete Row</p>}>
          <DeleteOutline
            sx={{ cursor: 'pointer', textAlign: 'center' }}
            onClick={() => {
              const newData = { ...editBand };
              if (newData.bands.length == 1) return;
              const removeArr = newData.bands
                .filter((b) => b.index != rowIndex + 1) // Remove the band from the array
                .map((b) => {
                  // Decrease the index of the bands that come after the deleted one
                  if (b.index > rowIndex + 1) {
                    b.index = b.index - 1;
                    return b;
                  } else return b;
                });
              setRowCount(rowCount - 1); // Decrease the rowCount
              setEditBand({ ...newData, bands: removeArr });
            }}
          />
        </Tooltip>
      );
    }
    if (rowData || isNew) {
      const cellValue = rowVal[dataKey];
      // Input to Edit and Add New Pricing Bands
      return (
        <NumericTextField
          id='standard-basic'
          variant='standard'
          disabled={['index'].includes(dataKey)}
          value={cellValue || ''}
          type='text'
          InputProps={{ pattern: '[-]?[0-9]*[.,]?[0-9]*', inputMode: 'decimal' }}
          onChange={(e) => {
            const { value } = e.target;
            const newData = { ...editBand };
            if (!_.find(editBand.bands, { index: rowIndex + 1 })) {
              // Create the index for the new band and add the property key and value
              newData.bands.push({ index: rowIndex + 1, [dataKey]: value });
            } else {
              // Index already exists, search for the band and update the property key and value
              const bands = newData.bands;
              const updatedBand = bands.map((b) => (b.index === rowIndex + 1 ? { ...b, [dataKey]: value } : b));
              newData.bands = updatedBand;
            }
            setEditBand(newData);
          }}
        />
      );
    }
    return cellData;
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleClose}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button onClick={handleClose} className='close'>
                x
              </button>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4 className='modal-title'>Pricing Band</h4>
                {(isNew || rowData) && (
                  <i
                    className='far fa-save fa-lg'
                    style={{ marginLeft: 10, fontSize: 20 }}
                    onClick={async () => {
                      await handleSave();
                      handleClose();
                    }}
                  />
                )}
              </div>
            </div>
            <div className='modal-body' style={{ overflow: 'auto' }}>
              <div style={{ maxHeight: '75vh' }}>
                {(isNew || rowData) && ( // When editing or adding a new band
                  <div style={{ marginTop: 25 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                      <TextField
                        sx={{ marginBottom: 5 }}
                        id='input-with-icon-textfield'
                        label='Pricing Band Name'
                        name='name'
                        value={editBand.name || ''}
                        onChange={(e) => setEditBand({ ...editBand, name: e.target.value })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div>
                        <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>Add Row</p>}>
                          <ControlPoint
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              // Add an index with respective index prop to bands array and increment rowCount
                              setEditBand({ ...editBand, bands: [...editBand.bands, { index: rowCount + 1 }] });
                              setRowCount(rowCount + 1);
                            }}
                          />
                        </Tooltip>
                        {rowCount >= 1 && (
                          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>Remove Row</p>}>
                            <RemoveCircleOutline
                              sx={{ margin: '0px 10px', cursor: 'pointer' }}
                              onClick={() => {
                                // Remove last index from bands array and decrement rowCount
                                setEditBand({ ...editBand, bands: editBand.bands.slice(0, -1) });
                                setRowCount(rowCount - 1);
                              }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <Table
                      width={540}
                      height={400 + rowCount * 35}
                      rowHeight={100}
                      headerHeight={30}
                      headerStyle={headerStyle}
                      rowStyle={{ textAlign: 'center' }}
                      headerClassName='admin_portal_column_headers'
                      rowGetter={({ index }) => editBand.bands[index]}
                      rowCount={rowCount}>
                      <Column label='Start' dataKey='start' width={400} cellRenderer={cellValue} />
                      <Column label='End' dataKey='end' width={400} cellRenderer={cellValue} />
                      <Column label='Per Unit' dataKey='perUnit' width={400} cellRenderer={cellValue} />
                      <Column label='Action' dataKey='action' width={400} cellRenderer={cellValue} />
                    </Table>
                  </div>
                )}
                {/* While Fetching the Pricing Bands, show Loader */}
                {!(isNew || rowData) && !bands.length > 0 && <LinearProgress />}
                {/* Once Pricing Bands are fetched, show the table */}
                {!(isNew || rowData) && bands?.length > 0 && (
                  <div>
                    {bands.map((pb, i) => {
                      const bandsLength = pb.bands.length;
                      return (
                        <div key={i} style={{ marginTop: i && 25 }}>
                          <div className='price-band-modal_table_header'>
                            <FormControlLabel
                              style={{ display: 'flex', alignItems: 'flex-end' }}
                              control={<Checkbox />}
                              onChange={() => {
                                setEditedState((prevState) => ({ ...prevState, perReportFeeVal: pb.id }));
                                setTimeout(() => setShowModal(false), 1500);
                              }}
                              value={(editedState && editedState['perReportFeeVal']) || ''}
                              checked={editedState && editedState['perReportFeeVal'] === pb.id}
                              label={<p>{pb.name}</p>}
                            />
                          </div>
                          <Table
                            width={540}
                            height={100 + bandsLength * 35}
                            rowHeight={100}
                            headerHeight={30}
                            headerStyle={headerStyle}
                            rowStyle={{ textAlign: 'center' }}
                            headerClassName='admin_portal_column_headers'
                            rowCount={bandsLength}
                            style={{ border: editedState?.['perReportFeeVal'] == pb.id ? '3px inset #130834' : 'none' }}
                            rowGetter={({ index }) => pb.bands[index]}>
                            <Column label='Index' dataKey='index' width={400} cellRenderer={cellValue} />
                            <Column label='Start' dataKey='start' width={400} cellRenderer={cellValue} />
                            <Column label='End' dataKey='end' width={400} cellRenderer={cellValue} />
                            <Column label='Per Unit' dataKey='perUnit' width={400} cellRenderer={cellValue} />
                          </Table>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { pricingBands } = state.admin?.adminPortal || {};
  const headerStyle = { verticalAlign: 'bottom', width: '100%', display: 'flex', justifyContent: 'center' };
  return {
    showLoader: state.showLoader,
    branding: state?.branding,
    pricingBands,
    headerStyle,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  savePricingBand: (band) => dispatch(PAYMENTACTIONS.savePricingBand(band)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingBandModal);
