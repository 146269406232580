import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMIN_ACTIONS } from '../../store/actions/adminActions';
import { Actions as CONSUMPTION_ACTIONS } from '../../store/actions/consumptionActions';
import { Actions as FACILITY_ACTIONS } from '../../store/actions/facilityActions';
import { Actions as REPORT_ACTIONS } from '../../store/actions/reportActions';
import BenchmarkReport from './BenchmarkReport';
import EmissionsByMainGreenhouseGases from './EmissionsByMainGreenhouseGases';
import EmissionsDisaggregatedByCustomCategory from './EmissionsDisaggregatedByCustomCategory';
import EmissionsDisaggregatedByFacility from './EmissionsDisaggregatedByFacility';
import GreenhouseEmissionsBySource from './GreenhouseEmissionsBySource';
import ReportOverview from './ReportOverview';
import TotalEmissionsRatios from './TotalEmissionsRatios';

import LinearProgress from '@mui/material/LinearProgress';

const HTMLReport = ({
  report,
  consumptionFilter,
  getFacilityMetrics,
  getReport,
  getBenchmarkData,
  fetchingConsumpData,
  branding,
  facilitiesExcluded,
  usageTypeDetails,
  getAllItemsFromDB,
}) => {
  const { headers } = branding.fonts?.[0].font || {};
  const filterClasses = consumptionFilter.map((item) => `hide-${item}`);

  useEffect(() => {
    const reportId = window.location.pathname.split('/')[3];
    getReport(reportId);
  }, []);

  useEffect(() => {
    (async () => {
      if (report && report.id) {
        await getBenchmarkData();
        await getFacilityMetrics();
      }

      if (!usageTypeDetails) {
        await getAllItemsFromDB('usageTypeDetails');
      }
    })();
  }, [report && report.id]);

  if (!report || !report.id) {
    return <LinearProgress color='inherit' />;
  }

  return (
    <div className={filterClasses.join(' ')} style={{ fontFamily: headers }}>
      <ReportOverview />
      {fetchingConsumpData ? (
        <div className='alert-message'>
          <span style={{ marginBottom: 10 }}>Loading Report...</span>
          <LinearProgress color='inherit' />
        </div>
      ) : (
        <section className='panel'>
          <div className='panel-body'>
            <TotalEmissionsRatios />
            <GreenhouseEmissionsBySource />
            <EmissionsByMainGreenhouseGases />
            <EmissionsDisaggregatedByFacility />
            <EmissionsDisaggregatedByCustomCategory />
            <BenchmarkReport />
            {facilitiesExcluded?.length > 0 && (
              <div>
                Please note that the following facilities are excluded from the report:{' '}
                {facilitiesExcluded?.map((facility) => facility?.name)?.join(', ')}
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { entities: consumptionEntities, subentities: consumptionSubentities, years, filter } = state.consumption || {};
  const usageTypeDetails = state?.admin?.adminPortal?.usageTypeDetails;

  const { subentities, entities, report } = state;

  const neededEntities =
    entities &&
    consumptionEntities &&
    _.orderBy(
      entities.filter(({ id }) => consumptionEntities.includes(id)),
      ['name']
    );

  const neededSubentities =
    subentities &&
    consumptionSubentities &&
    _.orderBy(
      subentities.filter(({ id }) => consumptionSubentities.includes(id)),
      ['name']
    );

  return {
    report: report?.details,
    fetchingConsumpData: state.showLoader || state.showLoader == undefined,
    consumptionFilter: filter || [],
    subentities: neededSubentities,
    entities: neededEntities,
    years: years || [],
    branding: state?.branding,
    showLoader: state?.showLoader,
    facilitiesExcluded: state?.facility?.list?.filter((facility) => facility.excludeFromReports),
    usageTypeDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReport: (id) => dispatch(REPORT_ACTIONS.getReport(id)),
  getFacilityMetrics: () => dispatch(FACILITY_ACTIONS.getFacilityMetrics()),
  getBenchmarkData: () => dispatch(CONSUMPTION_ACTIONS.getBenchmarkData()),
  toggleConsumptionFilter: (filter) => dispatch(CONSUMPTION_ACTIONS.toggleConsumptionFilter(filter)),
  getAllItemsFromDB: (table) => dispatch(ADMIN_ACTIONS.getAllItemsFromDB(table)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HTMLReport);
